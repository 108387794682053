import * as actions from './binhluanConstants';

const binhluan = {
    binhluanList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'desc'},
        search: '',
        filter: {
            status:0
        },
    }
};
const binhluanReducer = (state = binhluan, action) => {
    switch (action.type) {
    case actions.BINHLUAN_SET_LIST_DATA:
        return Object.assign({}, state, { binhluanList: action.binhluanList });
    case actions.BINHLUAN_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {binhluanReducer};