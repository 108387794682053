import React from 'react';
import { Spinner, Modal } from 'react-bootstrap';
class LoaderComponent extends React.Component {
    render() {
        const { loading } = this.props
        return (
            <React.Fragment>
                {
                    loading &&
                    <Modal className="loader-modal"
                        size="sm"
                        show={loading}
                        backdrop="static">
                        <Modal.Body>
                            <div className="loading-warp">
                                <Spinner animation="border" className="text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            </div>
                        </Modal.Body>
                    </Modal>
                }
            </React.Fragment>
        );
    }
}
export { LoaderComponent };
