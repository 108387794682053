import React from 'react';
import logoHeader from 'assets/images/logo-header.png'
import { NavLink } from "react-router-dom";
import { DanhGiaHeThongFormComponent } from "modules/Public/tienich/DanhGiaHeThongFormComponent";
import { tienichService } from "modules/Public/tienich/tienichService";
import { truycapService } from "modules/Public/demluottruycap/TruyCapService";
import { store } from 'redux/store';
import { withRouter } from 'react-router';
class FooterPublicComponent extends React.Component {
    constructor(props) {
        super(props);
        this.oauth = store.getState().oauth;
        this.state = {
            luottruycap: 0,
            thangnhieutruycap: '',
            thangittruycap: '',
            truycapthangnay: 0,
            luottruycapthang: 0,
            luottruycapmax: 0,
            luottruycapmin: 0,
            flat1: false,
        };
    }
    componentDidMount() {

        var today = new Date();
        var sethours = today.setHours(6);
        if (sessionStorage.getItem('setLuotTruyCap') == 1) {
            var checktime = new Date();
            checktime = checktime.setHours(0);
            if (checktime == localStorage.getItem('ThoiGianTruyCap')) {
                truycapService.TangLuotTruyCap().subscribe(res => {
                });
                localStorage.setItem('ThoiGianTruyCap', sethours)
            }
        }
        else {
            sessionStorage.setItem('setLuotTruyCap', '1');
            localStorage.setItem('ThoiGianTruyCap', sethours)
            truycapService.TangLuotTruyCap().subscribe(res => {
            });

        }
        truycapService.LuotTruyCap().subscribe(res => {
            var d = new Date();
            var month = d.getMonth();
            var thang = d.getMonth() + 1;
            var nam = d.getFullYear();
            var Luottruycap = res.data.reduce(function (prev, cur) {
                return prev + cur.luottruycap;
            }, 0);
            var Luottruycapmax = Math.max.apply(Math, res.data.map(function (o) { return o.luottruycap; }));
            var Thangnhieutruycaps = res.data.filter(x => x.luottruycap == Luottruycapmax);
            if (Thangnhieutruycaps.length > 0) {
                var Thangnhieutruycap = Thangnhieutruycaps[0].thang < 10 ? '0' + Thangnhieutruycaps[0].thang + '/' + Thangnhieutruycaps[0].nam : Thangnhieutruycaps[0].thang + '/' + Thangnhieutruycaps[0].nam;
            }
            else {
                var Thangnhieutruycap = thang < 10 ? '0' + thang + '/' + nam : thang + '/' + nam;
            }
            var Luottruycapmin = 0;
            if (month < 10) {
                var Thangittruycap = '0' + month + '/' + nam;
            }
            else {
                var Thangittruycap = month + '/' + nam;
            }

            if (res.data.filter(x => x.thang == month).length > 0) {
                var Luottruycapmin = Math.min.apply(Math, res.data.map(function (o) { return o.luottruycap; }))
                var Thangittruycaps = res.data.filter(x => x.luottruycap == Luottruycapmin);
                if (Thangittruycaps.length > 0) {
                    var Thangittruycap = Thangittruycaps[0].thang < 10 ? '0' + Thangittruycaps[0].thang + '/' + Thangittruycaps[0].nam : Thangittruycaps[0].thang + '/' + Thangittruycaps[0].nam;
                }
                else {
                    Thangittruycap = thang < 10 ? '0' + thang + '/' + nam : thang + '/' + nam;
                }
            }

            var Truycapthangnay = thang;
            var Luottruycapthangs = res.data.filter(x => x.thang == thang);
            var Luottruycapthang = Luottruycapthangs[0].luottruycap;
            this.setState({
                luottruycap: Luottruycap,
                thangnhieutruycap: Thangnhieutruycap,
                thangittruycap: Thangittruycap,
                truycapthangnay: Truycapthangnay,
                luottruycapthang: Luottruycapthang,
                luottruycapmax: Luottruycapmax,
                luottruycapmin: Luottruycapmin,
            })
        });
    }
    overItem = (e) => {
        switch (e) {
            case 1:
                this.setState({ flat1: true });
                break;
            default:
                break;
        }
    }
    leaveItem = (e) => {
        switch (e) {
            case 1:
                this.setState({ flat1: false });
                break;
            default:
                break;
        }
    }
    adddanhgia() {
        if (this.oauth.access_token != null) {
            tienichService.sendToForm.next({
                id: null,
                action: 'new',
                isShow: true
            });
        } else {
            this.props.history.push({ pathname: '/dang-nhap' });
        }

    }

    render() {
        return (
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex box-main_footer">
                                <div className="itemBoxFooter box-medium box-sm-top">
                                    <div className="box-top d-flex align-items-center justify-content-md-start justify-content-center">
                                        <NavLink to="/">
                                            <img src={logoHeader} alt="logo_header" />
                                        </NavLink>
                                        <p className="m-0 font-12 font-Bold text-white">TỔNG CỤC <br />QUẢN LÝ THỊ TRƯỜNG</p>
                                    </div>
                                    <div style={{ borderBottom: '1px solid #fbfbfb4d' }} className="d-md-block d-none">
                                        <p className="font-16 font-Bold text-white text-uppercase">Hệ thống quản lý về <br />
                                        hàng giả, hàng nhái, buôn lậu,<br />
                                        gian lận thương mại và <br />
                                        vi phạm sở hữu trí tuệ</p>
                                    </div>
                                    <div style={{ borderBottom: '1px solid #fbfbfb4d' }} className="d-md-none d-block">
                                        <p className="font-16 font-Bold text-white text-uppercase text-center">Hệ thống quản lý về
                                        hàng giả, hàng nhái, buôn lậu,
                                        gian lận thương mại và
                                        vi phạm sở hữu trí tuệ</p>
                                    </div>
                                    <div className="boxThongKeTruyCap">
                                        <div className="mainBoxSlideThongKe">
                                            <div className="boxSlideThongKeTruyCap"> 
                                            <div className="item itemBoxSlideThongKe" onMouseOver={() => this.overItem(1)} onMouseOut={() => this.leaveItem(1)} >
                                                <p className="font-Bold font-12 pt-2 mb-2 m-0 text-white">Lượt truy cập: <span className="font-Light">{this.state.luottruycap}</span></p>

                                                {(this.state.flat1 &&
                                                    <div className="boxHoverItemSlideThongKe p-2" >
                                                        <div className="d-flex flex-column">
                                                            <p className="text-white font-12 font-Regular m-0 text-uppercase">Tổng số lượt truy cập: {this.state.luottruycap} </p>
                                                        </div>
                                                        <hr className="bc-bc-82 mt-1 mb-1" />
                                                        <div className="d-flex flex-column">
                                                            <p className="text-white font-12 font-Regular m-0 text-uppercase">Số lượt truy cập trong tháng {this.state.truycapthangnay}: {this.state.luottruycapthang}   </p>
                                                        </div>
                                                        <hr className="bc-bc-82 mt-1 mb-1" />
                                                        <div className="d-flex flex-column">
                                                            <p className="text-white font-12 font-Regular m-0 text-uppercase">Thời gian truy cập nhiều nhất tháng {this.state.thangnhieutruycap}: {this.state.luottruycapmax}  </p>
                                                        </div>
                                                        <hr className="bc-bc-82 mt-1 mb-1" />
                                                        <div className="d-flex flex-column">
                                                            <p className="text-white font-12 font-Regular m-0 text-uppercase">Thời gian Truy cập ít nhất tháng {this.state.thangittruycap}: {this.state.luottruycapmin}   </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="itemBoxFooter box-medium box-sm-double">
                                    <ul className="box-items-full" style={{ padding: '0 0px 0 80px' }}>
                                        <li>
                                            <p className="font-Bold font-12 text-white m-0">TIỆN ÍCH</p>
                                        </li>
                                        <li><NavLink exact to="/gioi-thieu" className="font-Regular font-12 text-link text-white">.: Giới thiệu hệ thống</NavLink></li>
                                        <li><NavLink exact to="/huong-dan-su-dung" className="font-Regular font-12 text-link text-white">.: Hướng dẫn sử dụng</NavLink></li>
                                        <li><NavLink exact to="/cau-hoi-thuong-gap" className="font-Regular font-12 text-link text-white">.: Câu hỏi thường gặp</NavLink></li>
                                        <li><NavLink exact to="/lien-he-ho-tro" className="font-Regular font-12 text-link text-white">.: Liên hệ và hỗ trợ</NavLink></li>
                                        {
                                            this.oauth.access_token ?
                                                <li><NavLink exact to="/san-pham-quan-tam" className="font-Regular font-12 text-link text-white">.: Sản phẩm quan tâm</NavLink></li>
                                                : ""
                                        }
                                    </ul>
                                </div>
                                <div className="itemBoxFooter box-medium box-sm-double">
                                    <ul className="box-items-full">
                                        <li>
                                            <p className="font-Bold font-12 text-white" />
                                        </li>
                                        <li><NavLink exact to="/doanh-nghiep" className="font-Regular font-12 text-link text-white">.: Doanh nghiệp/ Hộ kinh doanh</NavLink>
                                        </li>
                                        {
                                            this.oauth.access_token ?
                                                <li><NavLink exact to={"#"} className="font-Regular font-12 text-link text-white" onClick={this.adddanhgia.bind(this)}>.: Đánh giá hệ thống</NavLink></li>
                                                : ""
                                        }
                                        <li><NavLink exact to="/phieu-khao-sat" className="font-Regular font-12 text-link text-white">.: Khảo sát</NavLink></li>
                                        <li><NavLink exact to="/dieu-khoan-chinh-sach" className="font-Regular font-12 text-link text-white">.: Điều khoản &amp; Chính sách</NavLink></li>
                                        {
                                            (this.oauth.access_token && this.oauth.unitId.includes('5')) ?
                                                <li><NavLink exact to="/bao-cao" className="font-Regular font-12 text-link text-white">.: Báo cáo</NavLink></li>
                                                : ""
                                        }
                                        {
                                            this.oauth.access_token ?
                                                <li><NavLink exact to="/tu-lieu-dong-gop" className="font-Regular font-12 text-link text-white">.: Tư liệu đóng góp</NavLink></li>
                                                : ""
                                        }
                                    </ul>
                                </div>
                                <div className="itemBoxFooter box-lg box-sm-bottom">
                                    <ul className="box-items-full">
                                        <li>
                                            <p className="font-Bold font-12 text-white m-0 text-uppercase">Liên hệ</p>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className="fa fa-map-marker mr-1 text-white pr-1" aria-hidden="true" />
                                                <p className="font-Regular font-12 text-link text-white m-0">91 Đinh Tiên Hoàng, Q. Hoàn Kiếm, Hà Nội
                                                </p>
                                            </div>
                                        </li>
                                       
                                        <li>
                                            <div className="d-flex">
                                                <i className="fa fa-phone mr-1 text-white" aria-hidden="true" />
                                                <p className="font-Regular font-12 text-link text-white m-0">84.24.3825 5868</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <i className="fa fa-envelope mr-1 text-white" aria-hidden="true" />
                                                <p className="font-Regular font-12 text-link text-white m-0">pqtt@moit.gov.vn</p>
                                            </div>
                                        </li>
                                        <li className="pt-2" style={{ borderTop: '1px solid #b3b3b34d' }}><a href="#" className="font-Regular font-12 text-link text-white">Copyright © 2020 - Tổng cục Quản lý thị trường</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DanhGiaHeThongFormComponent />
            </footer>

        );


    }
}
const FooterPublic = withRouter(FooterPublicComponent)
export default FooterPublic;