import * as Constant from './dmchitieuConstant';

const dmchitieu = {
    dmchitieuList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: ''        
    }
};
const dmchitieuReducer = (state = dmchitieu, action) => {
    switch (action.type) {
    case Constant.DMCHITIEU_SET_LIST_DATA:
        return Object.assign({}, state, { dmchitieuList: action.dmchitieuList });
    case Constant.DMCHITIEU_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { dmchitieuReducer};