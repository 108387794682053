import * as Constant from './dmduongphoConstant';

const dmduongpho = {
    dmduongphoList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '',
        filter: {
            idPhuongXa: 0,            
        },
    }
};
const dmduongphoReducer = (state = dmduongpho, action) => {
    switch (action.type) {
    case Constant.DMDUONGPHO_SET_LIST_DATA:
        return Object.assign({}, state, { dmduongphoList: action.dmduongphoList });
    case Constant.DMDUONGPHO_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {dmduongphoReducer};