import * as actions from './tktaikhoanConstant';

const tktaikhoan = {
    tktaikhoanList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { tendangnhap: 'asc' },
        search: '',
        filter: {
            nam: 0,
            thang: 0,
            nmanhom: "",
            _: new Date().getTime()

        },
    },
    data: {}
};
const tktaikhoanReducer = (state = tktaikhoan, action) => {
    switch (action.type) {
        case actions.TKTAIKHOAN_SET_LIST_DATA:
            return Object.assign({}, state, { tktaikhoanList: action.tktaikhoanList });
        case actions.TKTAIKHOAN_SET_LIST_META:
            return Object.assign(
                {},
                state,
                {
                    meta: Object.assign(
                        {},
                        state.meta,
                        action.meta
                    )
                });
        case actions.TKTAIKHOAN_SET_INFO:
            return Object.assign({}, state, { data: action.data });
        default:
            return state;
    }
};
export { tktaikhoanReducer };