import React from 'react';
import { Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from 'shared/components';
import { routesPublic } from './PublicRoutes';
import { NavBarPublic } from './NavBarPublic';
import  SideBarPublic  from './SideBarPublic';
import FooterPublic from './FooterPublic';
import NotifiFooter from './NotifiFooter';
import "jquery/src/jquery";
import { BehaviorsContext } from 'shared/services';
import { tienichService, Context } from 'modules/Public/tienich/tienichService';
//export default function AuthLayout() {
class PublicLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
        };
        this.handlerClickToggle = this.handlerClickToggle.bind(this);
    }
    handlerClickToggle() {
        this.setState({
            toggle: !this.state.toggle
        });
    }
    render() {
        return (
            <BehaviorsContext.Consumer>
                {
                    ({ beh }) => (
                        <Context.Provider value={{
                            tienichService: tienichService,
                            beh: beh
                        }} >

                            <div className="wrap-main">
                                <SideBarPublic />
                                <NavBarPublic />
                                <Switch>
                                    {routesPublic.map((route, i) => (
                                        <RouteWithSubRoutes key={i} {...route} />
                                    ))}

                                </Switch>
                                <FooterPublic></FooterPublic>
                                <NotifiFooter></NotifiFooter>
                            </div>

                        </Context.Provider>
                    )
                }
            </BehaviorsContext.Consumer>

        )
    }
}
export { PublicLayout };



