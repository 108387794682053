import * as Constant from './menuFrontEndConstant';

const menuFrontEnd = {
    menuFrontEndList: [],
    meta: {
        page: 1,
        page_size: 20,
        sort: {id:'asc'},
        search: '',
        filter: {           
        },
    }
};
const menuFrontEndReducer = (state = menuFrontEnd, action) => {
    switch (action.type) {
        case Constant.MENUFRONTEND_SET_LIST_DATA:
            return Object.assign({}, state, { menuFrontEndList: action.menuFrontEndList });
        case Constant.MENUFRONTEND_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export {menuFrontEndReducer};