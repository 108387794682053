import { http } from '../utils';

class BaseService {
    constructor(props = {}) {
        this.url = props ? props.url : '';
        this.http = http;
    }
    getMany(props) {
        const { page, page_size, sort, search, filter } = props;
        let sortBy = '';
        let sortDesc = false;
        let title = search; // do api chua co tim kiem theo tu khoa nen de tam search se tim kiem theo username
        if (sort) {
            sortBy = Object.keys(sort)[0];
            sortDesc = sort[sortBy] === 'desc';
        }
        const params = Object.assign({}, {
            page: page,
            itemsPerPage: page_size,
            sortBy: sortBy,
            sortDesc: sortDesc,
            FilterText: title
        }, filter);
        return http.get(`${this.url}`, { params: params });
    }
    getById(id) {
        return http.get(`${this.url}/${id}`);
    }
    create(obj) {
        return http.post(`${this.url}`, obj);
    }
    update(obj, id) {
        return http.put(`${this.url}/${id}`, obj);
    }
    createFormData(obj, files, objcreate) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        }
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i])
        }
        formData.append(objcreate, JSON.stringify(obj));
        return http.post(`${this.url}`, formData, {
            headers: headers
        })
    }
    updateFormData(obj, files, id, objupdate) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        }
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i])
        }
        formData.append(objupdate, JSON.stringify(obj));
        return http.put(`${this.url}/${id}`, formData, {
            headers: headers
        })
    }
    del(id) {
        return http.delete(`${this.url}/${id}`);
    }
    formatDateTime(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
            var output = day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            if (output == '1/01/1')
                return '';
            return output;
        }
    }
    formatDateTimeMDY(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
            var output = (('' + month).length < 2 ? '0' : '') + month + '/' +
                day + '/' +
                d.getFullYear();
            if (output == '1/01/1')
                return '';
            return output;
        }
    }
    formatFullDateTimeMDY(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            let hours = d.getHours();
            let minute = d.getMinutes();
            var month = d.getMonth() + 1;
            var day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
            var output = (('' + month).length < 2 ? '0' : '') + month + '/' +
                day + '/' +
                d.getFullYear() +' '+hours+':'+minute;
            if (output == '1/01/1')
                return '';
            return output;
        }
    }
    formatTime(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var hour = d.getHours();
            var minutes = d.getMinutes();
            var output = (('' + hour).length < 2 ? '0' : '') + hour + 'h' + (('' + minutes).length < 2 ? '0' : '') + minutes;
            return output;
        }
    }
    //ChageFormat datetime yyyy-mm-dd phục vụ tìm kiếm mở rộng
    changeFormatDateTime(strValue) {
        if (!strValue) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();

            var output = d.getFullYear() + '-' +
                (('' + month).length < 2 ? '0' : '') + month + '-' +
                (('' + day).length < 2 ? '0' : '') + day;
            return output;
        }
    }
    //ChageFormat datetime yyyy-mm phục vụ tìm kiếm mở rộng
    formatDateTimeYearMonth(strValue) {
        if (!strValue) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();

            var output = d.getFullYear() + '-' +
                (('' + month).length < 2 ? '0' : '') + month;
            return output;
        }
    }
    slugURL(str) {
        if (str) {
            // Chuyển hết sang chữ thường
            str = str.toLowerCase();

            // xóa dấu
            str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
            str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
            str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
            str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
            str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
            str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
            str = str.replace(/(đ)/g, 'd');

            // Xóa ký tự đặc biệt
            str = str.replace(/([^0-9a-z-\s])/g, '');

            // Xóa khoảng trắng thay bằng ký tự -
            str = str.replace(/(\s+)/g, '-');

            // xóa phần dự - ở đầu
            str = str.replace(/^-+/g, '');

            // xóa phần dư - ở cuối
            str = str.replace(/-+$/g, '');

            // return
            return str;
        }
        else
            return '';

    }
    /**
     * Kiểm tra có quyền không
     * @param {any} lstRoles danh sách quyền
     * @param {any} strObjectPer mã đối tượng
     * @param {any} strPrivilege mã quyền
     * @param {any} strPermisson Value quyền 
     */
    checkPermisson(lstRoles, strObjectPer, strPrivilege, strPermisson) {
        if (lstRoles.length > 0) {
            for (var i = 0; i < lstRoles.length; i++) {
                if (lstRoles[i] == (strObjectPer + '-' + strPrivilege + '-' + strPermisson))
                    return true;
            }
        }
        return false;
    }
    formatFullDateTime(strValue) {
        if (strValue == undefined || strValue === '' || strValue == null) {
            return '';
        }
        else {
            let output = '';
            let d = new Date(strValue);
            let month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1);
            let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
            let hour = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
            let minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
            output = day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            if (hour > 0) {
                output = output + ' ' + hour + ':' + minute;
            }
            if (output == '1/01/1')
                return '';
            return output;
        }
    }
    formatFullDateTimeBL(strValue) {
        if (strValue == undefined || strValue === '' || strValue == null) {
            return '';
        }
        else {
            let output = '';
            let d = new Date(strValue);
            let month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1);
            let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
            let hour = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
            let minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
            output = day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            if (hour > 0) {
                output = output + ' ' + (('' + hour).length < 2 ? '0' : '') + hour + 'h' + (('' + minute).length < 2 ? '0' : '') + minute;
            }
            if (output == '1/01/1')
                return '';
            return output;
        }
    }
    // dels(listId = []): Observable<any> {
    //     //return this.http.post<any>(this.url + '/deletes', listId);
    // }

    formartPriceVND(strNumber) {
        return strNumber ? strNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') : strNumber;
    }

    formartPhoneNuber(strNumber) {
        if (strNumber != undefined && strNumber != null && strNumber != '') {
            if (strNumber.length > 4) {

                return strNumber.replace(strNumber.slice(strNumber.length - 4, strNumber.length), 'XXXX');
            }
            else
                return 'XXXX';
        }
        return '';
    }
    randomArray(arr) {
        if (arr)
            arr.sort(function () {
                return 0.5 - Math.random();
            });
        return arr;
    }
}
const baseService = new BaseService();
export { BaseService, baseService };
