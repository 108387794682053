import * as Constant from './dmchucvuConstant';

const dmchucvu = {
    dmchucvuList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: ''        
    }
};
const dmchucvuReducer = (state = dmchucvu, action) => {
    switch (action.type) {
        case Constant.DMCHUCVU_SET_LIST_DATA:
            return Object.assign({}, state, { dmchucvuList: action.dmchucvuList });
        case Constant.DMCHUCVU_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { dmchucvuReducer};