import * as actions from './binhluanspConstants';

const binhluansp = {
    binhluanspList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'desc'},
        search: '',
        filter: {
            status:0
        },
    }
};
const binhluanspReducer = (state = binhluansp, action) => {
    switch (action.type) {
    case actions.BINHLUANSP_SET_LIST_DATA:
        return Object.assign({}, state, { binhluanspList: action.binhluanspList });
    case actions.BINHLUANSP_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {binhluanspReducer};