import * as actions from './dnsukienConstants';

const dnsukien = {
    dnsukienList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'desc'},
        search: '',
        filter: {
            status:0
        },
    }
};
const dnsukienReducer = (state = dnsukien, action) => {
    switch (action.type) {
    case actions.DNSUKIEN_SET_LIST_DATA:
        return Object.assign({}, state, { dnsukienList: action.dnsukienList });
    case actions.DNSUKIEN_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {dnsukienReducer};