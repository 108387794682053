import * as Constant from './tknhomnguoidungConstant';

const tknhomnguoidung = {
    tknhomnguoidungList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'asc'},
        search: '',
        filter: {
            //idDoanhNghiep: 0,            
        },     
    }
};
const tknhomnguoidungReducer = (state = tknhomnguoidung, action) => {
    switch (action.type) {
    case Constant.TKNHOMNGUOIDUNG_SET_LIST_DATA:
        return Object.assign({}, state, { tknhomnguoidungList: action.tknhomnguoidungList });
    case Constant.TKNHOMNGUOIDUNG_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {tknhomnguoidungReducer};