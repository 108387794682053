import * as Constant from './traloiphieukhaosatConstant';

const traloiphieukhaosat = {
    traloiphieukhaosatList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'asc'},
        search: '',
           filter: {
            ID_PHIEUKHAOSAT: 0,
            startdate: "",
            enddate: "",
        },
    }
};
const traloiphieukhaosatReducer = (state = traloiphieukhaosat, action) => {
    switch (action.type) {
        case Constant.TRALOIPHIEUKHAOSAT_SET_LIST_DATA:
            return Object.assign({}, state, { traloiphieukhaosatList: action.traloiphieukhaosatList });
        case Constant.TRALOIPHIEUKHAOSAT_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { traloiphieukhaosatReducer};