import React from 'react';
import PropTypes from 'prop-types';
import logo_home from 'assets/images/logo_home.svg';
import { NavLink } from "react-router-dom";
import { withRouter, matchPath } from 'react-router';
import { API_URL } from 'app-setting';

import Sidebar from "react-sidebar";

const mql = window.matchMedia(`(min-width: 800px)`);

class NavBarPublicComponent extends React.Component {
    //static propTypes = {
    //    history: PropTypes.object,
    //    handleChange: PropTypes.func

    //}
    constructor(props) {
        super(props);
        this.state = {
            dataTreeMenus: [],
            dataTreeMenuExpand: [],
            valueSearch: '',
            onToogleNavbar: false,
            sidebarDocked: mql.matches,
            sidebarOpen: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }
    componentWillMount() {
        mql.addListener(this.mediaQueryChanged);
    }

    componentDidMount() {
        this.getDataTreeMenus();
    }

    componentWillUnmount() {
        mql.removeListener(this.mediaQueryChanged);
    }

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    mediaQueryChanged() {
        this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
    }

    getDataTreeMenus() {
        fetch(`${API_URL}api/menu/treemenu`)
            .then(res => res.json())
            .then((data) => {
                let datamenu = data.filter(x => x.ischeck != 1);
                let datamenuexpan = data.filter(x => x.ischeck == 1);
                this.setState({ dataTreeMenus: datamenu, dataTreeMenuExpand: datamenuexpan });
            })
            .catch(console.log);
    }
    
    renderTreeMenus = () => {
        return this.state.dataTreeMenus.map(({ title, url, children }, i) => {
            return (
                <li className="nav-item pr-2 box-item-nav" key={i}>
                    <NavLink onClick={() => { this.setState({ sidebarOpen: !this.state.sidebarOpen }) }} className="nav-link text-uppercase font-Bold bg-transparent" exact to={url}>{title}</NavLink>
                    {(children && children.length && children.length > 0) ? (
                        <div className="menu-child active">
                            <ul className="list_menu-child">
                                {children.map(({ title, url, children }, j) => {
                                    return (
                                        <React.Fragment key={j}>
                                            {(children && children.length && children.length > 0) ? (
                                                <li>
                                                    <p className="font-12">{title}</p>
                                                    <ul className="menu-lever-child">
                                                        {children.map(({ title, url }, l) => {
                                                            return (
                                                                <li key={l} className="text-uppercase"><NavLink exact to={url} onClick={() => { this.setState({ sidebarOpen: !this.state.sidebarOpen }) }}>{title}</NavLink></li>
                                                            );
                                                        })}
                                                    </ul>
                                                </li>
                                            )
                                                :
                                                <li><NavLink onClick={() => { this.setState({ sidebarOpen: !this.state.sidebarOpen }) }} className="font-12" exact to={url}>{title}</NavLink></li>
                                            }
                                        </React.Fragment>
                                    );
                                })}
                            </ul>
                        </div>
                    ) : null}
                </li>
            );
        });
    }
    renderTreeMenuExpand = () => {
        if (this.state.dataTreeMenuExpand && this.state.dataTreeMenuExpand.length)
            return this.state.dataTreeMenuExpand.map((item, i) => {
                return (
                    // eslint-disable-next-line react/jsx-key
                    <li key={i}><NavLink className="font-12" exact to={item.url}>{item.title}</NavLink></li>
                );
            });
        else
            return '';
    }
    handleChange(event) {
        this.setState({ valueSearch: event.target.value });
    }
    handleKeyPress(target) {
        if (target.charCode == 13) {
            this.props.history.push('/tim-kiem?keysearch=' + this.state.valueSearch);
        }
    }

    renderNavbarMenuMobile = () => {
        return (
            <ul style={{ }}>
                <li>
                    <div className="form-inline position-relative form-search">
                        <input className="form-control" type="text" value={this.state.valueSearch} placeholder="Nhập từ khóa tìm kiếm" onChange={this.handleChange} onKeyPress={this.handleKeyPress} style={{ borderColor: '#b3b3b3', paddingRight: "32px", height: "35px" }} aria-label="Search"  />
                        <button className="btn btn-link icon-search_box position-absolute" onClick={() => this.props.history.push('/tim-kiem?keysearch=' + this.state.valueSearch)} >
                            <i className="fa fa-search" aria-hidden="true" />
                        </button>
                    </div>
                </li>
                <li style={{}}>
                    <a className="nav-link text-uppercase font-Bold bg-transparent" href="/">Trang chủ</a>
                </li>
                {this.state.dataTreeMenus && this.state.dataTreeMenus.length &&
                    this.renderTreeMenus()
                }
            </ul>
        )
    }


    render() {
        return (
            <div className="nav-menu">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <nav className="navbar navbar-expand-lg pr-0 pl-0 nav-menu-boder">
                                <NavLink className="navbar-brand p-0 mb-2 mr-1" exact to="/" style={{ zIndex:9999 }}>
                                    <img className="logo-home" src={logo_home} width="20px" alt="" />
                                </NavLink>
                                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => this.onSetSidebarOpen(true)}>
                                    <span className="fa fa-bars text-white" />
                                </button> */}
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mr-auto align-items-end">
                                        {this.state.dataTreeMenus && this.state.dataTreeMenus.length &&
                                            this.renderTreeMenus()
                                        }

                                        <li className="nav-item pr-2 box-item-nav" style={{ paddingLeft: '4px', paddingBottom: '5px' }}>
                                            <NavLink className="nav-link text-uppercase font-Bold bg-transparent" exact to='#' style={{ paddingBottom: '0px' }}><i className="far fa-plus-square" aria-hidden="true" style={{ color: '#B3B3B3', fontSize: '22px' }} /></NavLink>
                                            <div className="menu-child ">
                                                <ul className="list_menu-child">
                                                    <React.Fragment>
                                                        {
                                                            this.renderTreeMenuExpand()
                                                        }
                                                       
                                                    </React.Fragment>
                                                </ul>
                                            </div>
                                        </li>

                                        {/* <li className="nav-item pr-2" style={{ paddingLeft: '4px', paddingBottom: '5px' }}>
                                            <a className="nav-link text-uppercase font-Bold" href="# " style={{ paddingBottom: '0px' }}>
                                                <i className="far fa-plus-square" aria-hidden="true" style={{ color: '#B3B3B3', fontSize: '22px' }} />
                                            </a>
                                        </li> */}
                                    </ul>
                                    <div className="form-inline position-relative d-none">
                                        <input className="form-control" type="text" value={this.state.valueSearch} onChange={this.handleChange} onKeyPress={this.handleKeyPress} style={{ borderColor: '#b3b3b3', paddingRight: "32px", height:"35px" }} aria-label="Search" />
                                        <button className="btn btn-link icon-search_box position-absolute" onClick={() => this.props.history.push('/tim-kiem?keysearch=' + this.state.valueSearch)} >
                                            <i className="fa fa-search" aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
                {/* navbar menu mobile */}
                <Sidebar
                    sidebar={
                        <ul style={{}}>
                            <li>
                                <div className="form-inline position-relative form-search">
                                    <input className="form-control" type="text" value={this.state.valueSearch} placeholder="Nhập từ khóa tìm kiếm" onChange={this.handleChange} onKeyPress={this.handleKeyPress} style={{ borderColor: '#b3b3b3', paddingRight: "32px", height: "35px" }} aria-label="Search" />
                                    <button className="btn btn-link icon-search_box position-absolute" onClick={() => this.props.history.push('/tim-kiem?keysearch=' + this.state.valueSearch)} >
                                        <i className="fa fa-search" aria-hidden="true" />
                                    </button>
                                </div>
                            </li>
                            <li style={{}}>
                                <a className="nav-link text-uppercase font-Bold bg-transparent" href="/">Trang chủ</a>
                            </li>
                            {this.state.dataTreeMenus && this.state.dataTreeMenus.length &&
                                this.renderTreeMenus()
                            }
                        </ul>
                    }
                    open={this.state.sidebarOpen}
                    onSetOpen={this.onSetSidebarOpen}
                    sidebarClassName="box-navbar-menu d-md-none d-block"
                    overlayClassName="box-navbar-menu-child d-md-none d-block"
                    rootClassName="d-md-none d-block"
                >
                    <button className="d-md-none d-flex align-items-center justify-content-center btn h-100 ml-auto" onClick={() => this.onSetSidebarOpen(true)}>
                    <i className="fas fa-bars text-white"></i>
                     </button>
                </Sidebar>
                {/* <div className={(this.state.onToogleNavbar) ? "navbar-menu-mobile  d-md-none d-block" : "navbar-menu-mobile  d-md-none d-none"}>
                    <ul style={{ marginTop: 30 }}>
                        <li>
                            <div className="form-inline position-relative form-search">
                                <input className="form-control" type="text" value={this.state.valueSearch} placeholder="Nhập từ khóa tìm kiếm" onChange={this.handleChange} onKeyPress={this.handleKeyPress} style={{ borderColor: '#b3b3b3', paddingRight: "32px", height: "35px" }} aria-label="Search" />
                                <button className="btn btn-link icon-search_box position-absolute" onClick={() => this.props.history.push('/tim-kiem?keysearch=' + this.state.valueSearch)} >
                                    <i className="fa fa-search" aria-hidden="true" />
                                </button>
                            </div>
                        </li>
                        <li style={{}}>
                            <a className="nav-link text-uppercase font-Bold bg-transparent" href="/">Trang chủ</a>
                        </li>
                        {this.state.dataTreeMenus && this.state.dataTreeMenus.length &&
                            this.renderTreeMenus()
                        }
                    </ul>
                </div> */}
                {/* end navbar menu mobile */}
            </div>
        );
    }
}
//const mapStateToProps = (state) => {
//    return {
//        //user: state.oauth
//    };
//};
//const NavBarPublicComponent = connect(mapStateToProps, {})(NavBarPublic);
const NavBarPublic = withRouter(NavBarPublicComponent)
export { NavBarPublic };