import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { API_URL } from 'app-setting';
import { NavLink } from 'react-router-dom';
import NoImg from 'assets/images/unnamed.png';

class SPBaoCaoViPham extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataProductReports: [],
            dataTCCNVP: []
        }
    }

    componentDidMount() {
        this.getDataProductReports();
    }
    slugURL(str) {
        if (str) {
            // Chuyển hết sang chữ thường
            str = str.toLowerCase();

            // xóa dấu
            str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
            str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
            str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
            str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
            str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
            str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
            str = str.replace(/(đ)/g, 'd');

            // Xóa ký tự đặc biệt
            str = str.replace(/([^0-9a-z-\s])/g, '');

            // Xóa khoảng trắng thay bằng ký tự -
            str = str.replace(/(\s+)/g, '-');

            // xóa phần dự - ở đầu
            str = str.replace(/^-+/g, '');

            // xóa phần dư - ở cuối
            str = str.replace(/-+$/g, '');

            // return
            return str;
        }
        else
            return '';

    }
    getDataProductReports() {
        fetch(`${API_URL}api/bcviphamsanpham/lists?trangthai=1&page=1&itemsPerPage=3`)
            .then(res => res.json())
            .then((data) => {
                if (data.data != null) {
                    this.setState({ dataProductReports: data.data })
                }
            })
            .catch(console.log)
    }



    render() {
        return (
            <div>
                <div className="box-title_main_gray d-flex justify-content-between align-items-center">
                    <NavLink to={`/san-pham-vi-pham`} className="m-0 color-blue font-14 text-uppercase font-Bold">
                        Sản phẩm hay bị làm giả
                </NavLink>
                    <i className="fa fa-exclamation font-20 color-blue" aria-hidden="true"></i>
                </div>


                {this.state.dataProductReports ? (this.state.dataProductReports.map((item, i) => {
                    return (
                        <div key={i} className="item-SanPhamBaoCao container">
                            <div className="main-col-item">
                                <NavLink to={`/san-pham/${item.iD_SANPHAM}-${this.slugURL(item.tensanpham)}.html`} className="font-12 font-Regular color-blue text-link row">

                                    <div className="col-sm-6 col-md-6 col-lg-4 col-12">
                                            {item.anhsanpham != null ? 
                                            <img className="w-100 mb-2 img-sanpham-bcvp" src={`${API_URL}${item.anhsanpham}`} alt="" /> 
                                            : 
                                            <img className="w-100" style={{ borderRadius: '5px' }} src={NoImg} alt="" />}
                                    </div>

                                    <div className="col-sm-6 col-md-6 col-lg-4 col-12">
                                        <p className="font-12 font-Bold m-0 mb-1 color-black limited-line-2"> {item.loaisanpham}</p>

                                        <p className="font-12 font-Light color-black limited-line-2">{item.tensanpham}</p>
                                    </div>
                                    <div className="col float-right">
                                        <span className="font-12 font-Bold color-danger"> <i className="fa fa-ban" aria-hidden="true" />{item.soluotbcvp} báo cáo </span>
                                    </div>
                                </NavLink>
                            </div>


                        </div>

                    )
                })):""}
                <hr></hr>
                <NavLink to={`/san-pham-vi-pham`} className="font-12 font-Regular color-blue text-link d-flex justify-content-center align-items-center">
                    Xem thêm &nbsp;<i className="fa fa-angle-down" aria-hidden="true"></i>
                </NavLink>
                <div className="clear-fix"></div>
            </div>
        );
    }
}

export default SPBaoCaoViPham;