import { combineReducers } from 'redux';
import { oauthReducer } from './oauth/oauthReducer';
import { userReducer } from './user/userReducer';
import { dmchuyenmuctinReducer } from './dmchuyenmuctin/dmchuyenmuctinReducer';
import { dmdoituongchiaseReducer } from './dmdoituongchiase/dmdoituongchiaseReducer';
import { dmdonvilienketReducer } from './dmdonvilienket/dmdonvilienketReducer';
import { dmchudecauhoiReducer } from './dmchudecauhoi/dmchudecauhoiReducer';
import { dmlinhvuckinhdoanhReducer } from './dmlinhvuckinhdoanh/dmlinhvuckinhdoanhReducer';
import { dnthuonghieuReducer } from './dnthuonghieu/dnthuonghieuReducer';
import { dmchitieuReducer } from './dmchitieu/dmchitieuReducer';
import { DMLoaiThongBaoReducer } from './dmloaithongbao/DMLoaiThongBaoReducer';
import { dmdiabanReducer } from './dmdiaban/dmdiabanReducer';
import { dmquanhuyenReducer } from './dmquanhuyen/dmquanhuyenReducer';
import { tktaikhoanReducer } from './tktaikhoan/tktaikhoanReducer';
import { dmtinhthanhReducer } from './dmtinhthanh/dmtinhthanhReducer';
import { dmphuongxaReducer } from './dmphuongxa/dmphuongxaReducer';
import { dmduongphoReducer } from './dmduongpho/dmduongphoReducer';
import { dmchitieusanphamquantamReducer } from './dmchitieusanphamquantam/dmchitieusanphamquantamReducer';
import { dmchitieusanphamReducer } from './dmchitieusanpham/dmchitieusanphamReducer';
import { DMChiTieuNguoiDungTichCucReducer } from './dmchitieunguoidungtichcuc/DMChiTieuNguoiDungTichCucReducer';
import { DMChiTieuDoanhNghiepTichCucReducer } from './dmchitieudoanhnghieptichcuc/DMChiTieuDoanhNghiepTichCucReducer';
import { dmloaisanphamReducer } from './dmloaisanpham/dmloaisanphamReducer';
import { tkpermissionReducer } from './tkpermission/tkpermissionReducer';
import { dmloaihinhkinhdoanhReducer } from './dmloaihinhkinhdoanh/dmloaihinhkinhdoanhReducer';
import { DMKhoangGiaReducer } from './dmkhoanggia/DMKhoangGiaReducer';
import { DMDonViReducer } from './dmdonvi/DMDonViReducer';
import { tknhomnguoidungReducer } from './tknhomnguoidung/tknhomnguoidungReducer';
import { dmdoituongquanlyReducer } from './dmdoituongquanly/dmdoituongquanlyReducer';
import { dmchucvuReducer } from './dmchucvu/dmchucvuReducer';
import { dmhanhviviphamReducer } from './dmhanhvivipham/dmhanhviviphamReducer';
import { dmchitieudanhgiaReducer } from './dmchitieudanhgia/dmchitieudanhgiaReducer';
import { tknhomnguoidungcnReducer } from './tknhomnguoidungcn/tknhomnguoidungcnReducer';
import { dmlinhvucsukienReducer } from './dmlinhvucsukien/dmlinhvucsukienReducer';
import { logSysReducer } from './logSys/logSysReducer';
import { menuhethongReducer } from './menuhethong/menuhethongReducer'; 
import { hosovuviecReducer } from './quanlynoidung/hosovuviec/hosovuviecReducer';
import { khotulieuReducer } from './quanlynoidung/khotulieu/khotulieuReducer';
import { tindangReducer } from './quanlynoidung/tindang/tindangReducer';
import { bclydobaocaoReducer } from './quanlynoidung/bclydobaocao/bclydobaocaoReducer';
import { bcviphamsanphamReducer } from './quanlynoidung/bcviphamsanpham/bcviphamsanphamReducer';
import { tochuccanhanviphamReducer } from './quanlynoidung/tochuccanhanvipham/tochuccanhanviphamReducer';
import { danhgiavehethongReducer } from './danhgiavehethong/danhgiavehethongReducer';
import { phieukhaosatReducer } from './phieukhaosat/phieukhaosatReducer';
import { cauhoikhaosatReducer } from './cauhoikhaosat/cauhoikhaosatReducer';
import { ketquaphieukhaosatReducer } from './ketquaphieukhaosat/ketquaphieukhaosatReducer';
import { baidangReducer } from './quanlynoidung/baidang/baidangReducer';
import { binhluanReducer } from './quanlynoidung/baidang/binhluan/binhluanReducer';
import { bvbaocaoviphamReducer } from './quanlynoidung/baidang/bvbaocaovipham/bvbaocaoviphamReducer';
import { traloiphieukhaosatReducer } from './traloiphieukhaosat/traloiphieukhaosatReducer';
import { cauhinhhethongReducer } from './cauhinhhethong/cauhinhhethongReducer';
import { cauhinhdulieulienketReducer } from './cauhinhdulieulienket/cauhinhdulieulienketReducer';
import { dmapiwebserviceReducer } from './dmapiwebservice/dmapiwebserviceReducer'; 
import { tkdoanhnghiepReducer } from './tktaikhoan/tkdoanhnghiep/tkdoanhnghiepReducer';
import { sanphamdnReducer } from './quanlynoidung/sanphamdn/sanphamdnReducer';
import { binhluanspReducer } from './quanlynoidung/sanphamdn/binhluansp/binhluanspReducer';
import { dnsukienReducer } from './quanlynoidung/dnsukien/dnsukienReducer';
import { tinbaotogiacReducer } from './quanlynoidung/tinbaotogiac/tinbaotogiacReducer';
import { taikhoanReducer } from './quanlynoidung/tinbaotogiac/taikhoan/taikhoanReducer';
import { menuFrontEndReducer } from './menuFrontEnd/menuFrontEndReducer';
import { bvtienichReducer } from './bvtienich/bvtienichReducer';
import { dieukhoanchinhsachReducer } from './dieukhoanchinhsach/dieukhoanchinhsachReducer';
import { chcauhoiReducer } from './chcauhoi/chcauhoiReducer';
import { bannerquangcaoReducer } from './bannerquangcao/bannerquangcaoReducer';
import { chtraloiReducer } from './chtraloi/chtraloiReducer';
const reducer = combineReducers({
    oauth: oauthReducer,
    user: userReducer,
    dmchuyenmuctin: dmchuyenmuctinReducer,
    dmdoituongchiase: dmdoituongchiaseReducer,
    dmdonvilienket: dmdonvilienketReducer,
    dmchudecauhoi: dmchudecauhoiReducer,
    dmchitieu: dmchitieuReducer,
    dmlinhvuckinhdoanh: dmlinhvuckinhdoanhReducer,
    dnthuonghieu: dnthuonghieuReducer,
    DMLoaiThongBao: DMLoaiThongBaoReducer,
    tktaikhoan:tktaikhoanReducer,
    dmchitieusanphamquantam: dmchitieusanphamquantamReducer,
    dmdiaban: dmdiabanReducer,
    dmquanhuyen: dmquanhuyenReducer,
    dmtinhthanh: dmtinhthanhReducer,
    dmphuongxa: dmphuongxaReducer,
    dmduongpho: dmduongphoReducer,
    dmloaisanpham: dmloaisanphamReducer,
    tkpermission: tkpermissionReducer,    
    dmchitieusanpham: dmchitieusanphamReducer,
    dmloaihinhkinhdoanh: dmloaihinhkinhdoanhReducer,
    DMChiTieuNguoiDungTichCuc: DMChiTieuNguoiDungTichCucReducer,
    DMChiTieuDoanhNghiepTichCuc: DMChiTieuDoanhNghiepTichCucReducer,
    DMKhoangGia: DMKhoangGiaReducer,
    DMDonVi: DMDonViReducer,
    tknhomnguoidung: tknhomnguoidungReducer,
    dmdoituongquanly: dmdoituongquanlyReducer,
    dmchucvu: dmchucvuReducer,
    dmhanhvivipham:dmhanhviviphamReducer,
    dmchitieudanhgia: dmchitieudanhgiaReducer,
    tknhomnguoidungcn:tknhomnguoidungcnReducer,
    dmlinhvucsukien: dmlinhvucsukienReducer,
    logSys: logSysReducer,
    menuhethong: menuhethongReducer,
    hosovuviec: hosovuviecReducer,
    khotulieu: khotulieuReducer,
    tindang: tindangReducer,
    bclydobaocao: bclydobaocaoReducer,
    bcviphamsanpham: bcviphamsanphamReducer,
    tochuccanhanvipham: tochuccanhanviphamReducer,
    danhgiavehethong: danhgiavehethongReducer,
    phieukhaosat: phieukhaosatReducer,
    cauhoikhaosat: cauhoikhaosatReducer,
    ketquaphieukhaosat: ketquaphieukhaosatReducer,
    baidang: baidangReducer,
    binhluan:binhluanReducer,
    bvbaocaovipham:bvbaocaoviphamReducer,
    traloiphieukhaosat: traloiphieukhaosatReducer,
    cauhinhhethong: cauhinhhethongReducer,
    cauhinhdulieulienket:cauhinhdulieulienketReducer,
    dmapiwebservice: dmapiwebserviceReducer,
    tkdoanhnghiep:tkdoanhnghiepReducer,
    sanphamdn: sanphamdnReducer,
    binhluansp:binhluanspReducer,
    dnsukien: dnsukienReducer,
    tinbaotogiac: tinbaotogiacReducer,
    taikhoan: taikhoanReducer,
    menuFrontEnd: menuFrontEndReducer,
    bvtienich: bvtienichReducer,
    dieukhoanchinhsach:dieukhoanchinhsachReducer,
    chcauhoi: chcauhoiReducer,
    bannerquangcao: bannerquangcaoReducer,
    chtraloi: chtraloiReducer
});
export default reducer;