import * as Constant from './bcviphamsanphamConstant';

const bcviphamsanpham = {
    bcviphamsanphamList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'asc'},
        search: '',
        filter: {
            iD_SANPHAM: 0,    
            iD_DOANHNGHIEP: 0,     
            iD_NGUOIBAOCAO: 0,     
            iD_LYDOBAOCAO: 0,     
            iD_NGUOIPHANHOI: 0,     
        },
    }
};
const bcviphamsanphamReducer = (state = bcviphamsanpham, action) => {
    switch (action.type) {
        case Constant.BCVIPHAMSANPHAM_SET_LIST_DATA:
            return Object.assign({}, state, { bcviphamsanphamList: action.bcviphamsanphamList });
        case Constant.BCVIPHAMSANPHAM_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export {bcviphamsanphamReducer};