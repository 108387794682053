import { loadable } from 'shared/utils';
const routesPublic = [
    {
        path: '/dang-ky-tai-khoan',
        component: loadable(() => import('../modules/Public/taikhoan'))
    },
    {
        path: '/active-account',
        component: loadable(() => import('../modules/Public/taikhoan'))
    },
    {
        path: '/to-chuc-ca-nhan-vi-pham',
        component: loadable(() => import('../modules/Public/tochuccanhanvipham'))
    },
    //{
    //    path: '/tin-bao-to-cao',
    //    component: loadable(() => import('../modules/Public/tinbaotocao/guitinbaotocao'))
    //},
    {
        path: '/tin-tuc',
        component: loadable(() => import('../modules/Public/tintuc'))
    },
    {
        path: '/dang-nhap',
        component: loadable(() => import('../modules/Public/login'))
    },
    {
        path: '/su-kien',
        component: loadable(() => import('../modules/Public/doanhnghiep'))
    },
    //{
    //    path: '/profile-su-kien',
    //    component: loadable(() => import('../modules/Public/sukien'))
    //},
    {
        path: '/doanh-nghiep',
        component: loadable(() => import('../modules/Public/doanhnghiep/doanhnghiephokinhdoanh'))
    },
    {
        path: '/doanh-nghiep-tich-cuc',
        component: loadable(() => import('../modules/Public/doanhnghiep/doanhnghieptichcuc'))
    },
    {
        path: '/lien-he-ho-tro',
        component: loadable(() => import('../modules/Public/tienich/lienhehotroComponent'))
    },
    {
        path: '/cau-hoi-thuong-gap',
        component: loadable(() => import('../modules/Public/tienich'))
    },
    //chi tiết câu hỏi
    {
        path: '/cau-hoi',
        component: loadable(() => import('../modules/Public/tienich'))
    },
    {
        path: '/gioi-thieu',
        component: loadable(() => import('../modules/Public/tienich/gioithieuComponent'))
    },
    {
        path: '/huong-dan-su-dung',
        component: loadable(() => import('../modules/Public/tienich/hdsdComponent'))
    },
    {
        path: '/hoi-dap',
        component: loadable(() => import('../modules/Public/hoidap/dshoidap'))
    },
    {
        path: '/nhan-dien-thuong-hieu',
        component: loadable(() => import('../modules/Public/nhandienthuonghieu'))
    },
    {
        path: '/tim-kiem',
        component: loadable(() => import('../modules/Public/timkiem'))
    },
    {
        path: '/san-pham-vi-pham',
        component: loadable(() => import('../modules/Public/nhandienthuonghieu/dssanphambaocaovipham'))
    },
    {
        path: '/san-pham',
        component: loadable(() => import('../modules/Public/sanpham'))
    },
    {
        path: '/dia-ban-xay-ra-vi-pham',
        component: loadable(() => import('../modules/Public/diabanxayravipham/dsdiabanxayravipham'))
    },
    {
        path: '/bao-cao',
        component: loadable(() => import('../modules/Public/baocao'))
    },
    {
        path: '/dieu-khoan-chinh-sach',
        component: loadable(() => import('../modules/Public/dieukhoanchinhsach'))
    },
    {
        path: '/phieu-khao-sat',
        component: loadable(() => import('../modules/Public/khaosat'))
    },
    {
        path: '/bai-dang',
        component: loadable(() => import('../modules/Public/baiviet'))
    },
    {
        path: '/tu-lieu-dong-gop',
        component: loadable(() => import('../modules/Public/tulieudonggop'))
    }, {
        path: '/ket-noi',
        component: loadable(() => import('../modules/Public/ketnoitaikhoan'))
    }, {
        path: '/theo-doi',
        component: loadable(() => import('../modules/Public/theodoitaikhoan'))
    },
    {
        path: '/san-pham-quan-tam',
        component: loadable(() => import('../modules/Public/nhandienthuonghieu/SanPhamQuanTamComponent'))
    },
    {
        path: '/san-pham-doanh-nghiep',
        component: loadable(() => import('../modules/Public/trangdoanhnghiep/sanpham'))
    },
    {
        path: '/thong-tin-doanh-nghiep',
        component: loadable(() => import('../modules/Public/trangdoanhnghiep/doanhnghiephokinhdoanh'))
    },
    {
        path: '/su-kien-doanh-nghiep',
        component: loadable(() => import('../modules/Public/trangdoanhnghiep/sukien'))
    },
    {
        path: '/thuong-hieu',
        component: loadable(() => import('../modules/Public/thuonghieu'))
    },
    {
        path: '/thuong-hieu-noi-bat',
        component: loadable(() => import('../modules/Public/thuonghieu/thuonghieunoibat'))
    },
    {
        path: '/phan-anh',
        component: loadable(() => import('../modules/Public/phananh'))
    },
    //lịch sử quét mã
    {
        path: '/lich-su-quet-ma-qr',
        component: loadable(() => import('../modules/Public/lichsuquetma'))
    },
    {
        path: '/lich-su-quet-ma-vach',
        component: loadable(() => import('../modules/Public/lichsuquetma'))
    },
    {
        path: '/lich-su-quet-tem-chong-gia',
        component: loadable(() => import('../modules/Public/lichsuquetma'))
    },
    //end lịch sử quét mã
    // {
    //     path: '/active-account',
    //     component: loadable(() => import('../modules/Public/taikhoan/activetaikhoan'))
    // },
    {
        path: '/van-ban-phap-luat',
        component: loadable(() => import('../modules/Public/vanbanphapluat'))
    },
    
    //mặc định để cuối cùng
    {
        path: '/',
        component: loadable(() => import('../modules/Public/home'))
    },


];
export { routesPublic };