import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/Public/custom-style.scss';
import '../../../styles/Public/responsive.scss';
import 'jquery/src/jquery';
const LayoutpaContext = React.createContext();

class Left extends Component {
    static propTypes = {
        className: PropTypes.string
    };
    render() {
        const { className, ...props } = this.props;
        return (
            <LayoutpaContext.Consumer>
                {() => (
                    <div className={['col-md-8', className].join(' ')} {...props}>{props.children}</div>
                )}
            </LayoutpaContext.Consumer>

        );
    }

}

class Right extends Component {
    static propTypes = {
        className: PropTypes.string
    };
    render() {
        const { className, ...props } = this.props;
        return (
            <LayoutpaContext.Consumer>
                {() => (
                    <div className={['col-md-4', className].join(' ')} {...props}>{props.children}
                        <div>
                            {/* end box tin moi */}
                            {/* san pham moi */}
                            
                        </div>

                    </div>
                )}
            </LayoutpaContext.Consumer>
        );
    }
}
class LayoutpaComponent extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ])
    };


    render() {
        return (
            <LayoutpaContext.Provider {...this.props}>
                <div className="wrap-main-danh-sach-bai-viet pt-4">
                    <div className="container">
                        <div className="row">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </LayoutpaContext.Provider>
        );
    }
}
LayoutpaComponent.Left = Left;
LayoutpaComponent.Right = Right;
export { LayoutpaComponent };