import React from 'react';
import logoHeader from 'assets/images/logo-header.png'
import { NavLink } from "react-router-dom";
import { tienichService } from "modules/Public/tienich/tienichService";
import { withRouter } from 'react-router';
class NotifiFooterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noiDungTB: '',
        };
    }
    componentDidMount() {
        tienichService.getTienIchByType(7).subscribe(res => {
            this.setState({ noiDungTB: res.noidung.replace(/(<([^>]+)>)/ig, '') });
        })
    }
    render() {
        return (
            <div className="footer-notification"><marquee dangerouslySetInnerHTML={{ __html: this.state.noiDungTB }}></marquee></div>
        );


    }
}
const NotifiFooter = withRouter(NotifiFooterComponent)
export default NotifiFooter;