import * as Constant from './dmdiabanConstant';

const dmdiaban = {
    dmdiabanList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '',
        filter: {
            idTinhThanh: 0,            
        },
    }
};
const dmdiabanReducer = (state = dmdiaban, action) => {
    switch (action.type) {
        case Constant.DMDIABAN_SET_LIST_DATA:
        return Object.assign({}, state, { dmdiabanList: action.dmdiabanList });
        case Constant.DMDIABAN_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {dmdiabanReducer};