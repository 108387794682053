import * as actions from './tindangConstants';

const tindang = {
    tindangList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'desc'},
        search: '',
        filter: {
            status:0
            // donvi: 0,
           // _: new Date().getTime()
            
        },
    }
};
const tindangReducer = (state = tindang, action) => {
    switch (action.type) {
    case actions.TINDANG_SET_LIST_DATA:
        return Object.assign({}, state, { tindangList: action.tindangList });
    case actions.TINDANG_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {tindangReducer};