//--- Component
export { RouteGuardComponent } from './RouteGuard';
export { RouteWithSubRoutes } from './RouteWithSubRoutes';
export { LoadingComponent } from './LoadingComponent';
export { LoaderComponent  as Loader } from './LoaderComponent';
export { PageComponent as Page } from './page/PageComponent';
export { Layout2cComponent as Layout2c } from './layout-home/Layout2cComponent';
export { LayoutbvComponent as Layoutbv } from './layout-home/LayoutbvComponent';
export { LayoutpaComponent as Layoutpa } from './layout-home/LayoutpaComponent';
export { GridViewComponent as GridView } from './grid-view/GridViewComponent';
export { AlertComponent } from './behaviors/AlertComponent';
export { ConfirmComponent } from './behaviors/ConfirmComponent';
export { FormSelectComponent as FormSelect } from './form-control/FormSelectComponent';
export { FileAttachComponent as FileAttach } from './form-control/FileAttachComponent';
export { FileAttachImageComponent as FileAttachIamge } from './form-control/FileAttachIamgeComponent';
export { DatePickerComponent as Datepicker } from './form-control/DatePickerComponent';