import * as Constant from './dmquanhuyenConstant';

const dmquanhuyen = {
    dmquanhuyenList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '',
        filter: {
            idTinhThanh: 0,            
        },
    }
};
const dmquanhuyenReducer = (state = dmquanhuyen, action) => {
    switch (action.type) {
    case Constant.DMQUANHUYEN_SET_LIST_DATA:
        return Object.assign({}, state, { dmquanhuyenList: action.dmquanhuyenList });
    case Constant.DMQUANHUYEN_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {dmquanhuyenReducer};