import * as actions from './tkdoanhnghiepConstant';

const tkdoanhnghiep = {
    tkdoanhnghiepList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {tendangnhap:'asc'},
        search: '',
        filter: {
            id_linhvuc:0,
            id_loaihinh:0,
            id_mathangkinhdoanhchinh:0,
            id_tinhthanh:0,
            id_quanhuyen:0,
            id_phuongxa:0,
            _: new Date().getTime()
            
        },
    }
};
const tkdoanhnghiepReducer = (state = tkdoanhnghiep, action) => {
    switch (action.type) {
    case actions.TKDOANHNGHIEP_SET_LIST_DATA:
        return Object.assign({}, state, { tkdoanhnghiepList: action.tkdoanhnghiepList });
    case actions.TKDOANHNGHIEP_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {tkdoanhnghiepReducer};