import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { baseService } from '../../../../shared/services'
import { Row, Form, Col, Button, Breadcrumb } from 'react-bootstrap';
import * as DatabaseChat from '../../../../database/chat';
import { API_URL } from 'app-setting';
import { wsClient, subcriptionTinNhan, queryHistory } from '../../../../database/chat';
import { Formik } from 'formik';
import { string, object } from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { store } from 'redux/store';
import { connect } from 'react-redux';
import imgDefault from 'assets/images/img-user-default.png';
import { http } from 'shared/utils';
import InfiniteScroll from 'react-infinite-scroller'
import ReactDOM from 'react-dom';
import { Dropdown } from "react-bootstrap";
import { tinNhanSchema } from '../../../../database/chat/tinNhanSchema';
const schema = object({
    //NOIDUNG: string().trim().required(''),
});
class ChatBoxComponent extends Component {
    static propTypes = {
        ID_HOITHOAI: PropTypes.string,
        onScrollBottom: PropTypes.bool
    };
    static defaultProps = {
        ID_HOITHOAI: '',
        onScrollBottom:true
    }
    constructor(props) {
        super(props);
        DatabaseChat.get().then(res => {
            this.db = res;
        })
        this.state = {
            message: {},
            tieude_ht: '',
            anhdaidien_ht: '',
            list_message: [],
            prevProps: props,
            list_limmit_mess_box: 10,
            isLoading: false,
            prevY: 0,
            onScrollBottom: this.props.onScrollBottom,
            ngay_tao_cu: '',
            hasMore: true,
            isSearch: false,
            valueSearch:''
        }
        this.oauth = store.getState().oauth;
        this.subscriptions = {};
        this.nameCollection = '';
        this.hoithoaiCollection = "hoithoai" + props.ID_TAIKHOAN + "ht";
    }
    handleObserver(entities, observer) {
        const y = entities[0].boundingClientRect.y;
        this.scrollToBottom();
        this.setState({ prevY: y });
    }

    async onSubmit(data) {
        if (!data.NOIDUNG.trim())
            return;
        const db = await DatabaseChat.get();
        data.ID = uuidv4();
        data.ID_HOITHOAI = this.props.ID_HOITHOAI;
        await db[this.nameCollection].insert(data);
        this.setState({ prevY: 0 });
        this.scrollToBottom();
    }

    async componentDidMount() {
        this.RunReplication();
        if (this.props.ID_HOITHOAI != null && this.props.ID_HOITHOAI != '') {
            this.scrollToBottom();
            this.observer = new IntersectionObserver(
                this.handleObserver.bind(this)
            );
            this.observer.observe(this.scrollParentRef);
        }
    }
    scrollToBottom = () => {
        if (this.scrollParentRef.scrollTop + this.scrollParentRef.clientHeight + 100 >= this.scrollParentRef.scrollTop) {
            this.scrollParentRef.scrollTop = this.scrollParentRef.scrollHeight;
        }
    }
    async RunReplication() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
        if (this.replicationState) {
            await this.replicationState.cancel();
        }
        const db = await DatabaseChat.get();
        this.nameCollection = 'db' + this.props.ID_HOITHOAI.replace(/-/g, "");
        if (this.nameCollection !== 'db') {
            if (!db[this.nameCollection]) {
                const collection = await db.collection({
                    name: this.nameCollection,
                    schema: tinNhanSchema
                });
            }
            db[this.hoithoaiCollection].findOne(this.props.ID_HOITHOAI).exec().then(async doc => {
                if (doc) {
                    this.setState({
                        tieude_ht: doc.DS_THANHVIEN.find(x => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN) != null ? doc.DS_THANHVIEN.find(x => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN).TENDAYDU : doc.TIEUDE,
                        anhdaidien_ht: (doc.DS_THANHVIEN.find(x => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN) != null && doc.DS_THANHVIEN.find(x => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN).ANHDAIDIEN != null) ? API_URL + doc.DS_THANHVIEN.find(x => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN).ANHDAIDIEN : imgDefault
                    });
                }
            });
            let batchSize = 20;
            this.replicationState = db[this.nameCollection].syncGraphQL({
                url: API_URL, // url to the GraphQL endpoint
                headers: {
                    "Authorization": "Bearer " + this.props.access_token
                },
                pull: {
                    queryBuilder: DatabaseChat.pullQueryBuilder({ ID_HOITHOAI: this.props.ID_HOITHOAI, Paging: false }), // the queryBuilder from above
                },
                push: {
                    batchSize,
                    queryBuilder: DatabaseChat.pushQueryBuilderCustom,
                },
                deletedFlag: 'DELETED', // the flag which indicates if a pulled document is deleted
                live: true, // if this is true, rxdb will watch for ongoing changes and sync them, when false, a one-time-replication will be done
                liveInterval: 600000,
            });
            this.subscriptions["replicationStateError"] = this.replicationState.error$.subscribe(err => {
                http.get('/api/lookup/roles').subscribe();
            });

            const ret = wsClient.request({
                query: subcriptionTinNhan,
                variables: {
                    "id_hoithoai": this.props.ID_HOITHOAI
                }
            });
            this.subscriptions["wsClient"] = ret.subscribe({
                next: async (data) => {
                    await this.replicationState.run();
                },
                error(error) {
                    console.log(error)
                }
            });

            this.subscriptions["list_message"] = db[this.nameCollection].find(
                {
                    selector: {
                        ID_HOITHOAI: { $eq: this.props.ID_HOITHOAI },
                    }
                }
            )
                .sort({ 'NGAYTAO_UTC': 1 }).$.subscribe(response => {
                    if (!response) {
                        return;
                    }
                    this.setState({
                        list_message: response,
                        loading: false
                    });
                    //nếu số lượng tin nhắn ban đầu nhỏ hơn 20 sẽ tắt chức năng loading
                    if (this.state.list_message.length < 20)
                        this.setState({
                            hasMore:false
                        });
                });
        }
    }
    //Load dữ liệu cũ
    async GetData() {
        const db = await DatabaseChat.get();
        await db[this.nameCollection].findOne().sort('NGAYTAO_UTC')
            .exec().then(response => {
                if (!response) {
                    return;
                }
                this.setState({
                    ngay_tao_cu: response.NGAYTAO_UTC
                });
            });
        await fetch(API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.props.access_token
            },
            body: JSON.stringify({
                query: DatabaseChat.queryHistory({ lastID: '', NgayTao: this.state.ngay_tao_cu, ID_HOITHOAI: this.props.ID_HOITHOAI, Paging: true }),
                variables: {},
            })
        }).then(r => r.json()).then(data => {
            //Đẩy data vào rxdb
            if (data.data.feedForRxDBReplicationMessage != null && data.data.feedForRxDBReplicationMessage.length > 0) {
                db[this.nameCollection].bulkInsert(data.data.feedForRxDBReplicationMessage);
                db[this.nameCollection].find(
                    {
                        selector: {
                            ID_HOITHOAI: { $eq: this.props.ID_HOITHOAI }
                        }
                    }
                )
                    .sort({ 'NGAYTAO_UTC': 1 }).$.subscribe(response => {
                        if (!response) {
                            return;
                        }
                        this.setState({
                            list_message: response,
                            loading: false
                        });
                    });
            }
            else {
                this.setState({
                    hasMore: false
                });
            }
            return data;
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.ID_HOITHOAI != this.props.ID_HOITHOAI || prevProps.access_token != this.props.access_token) {
            this.RunReplication()
        }
        // const y = entities[0].boundingClientRect.y; 
        // if (this.refs.scrollParentRef.scrollHeight != null) {

        // }
        else if (this.state.onScrollBottom) {
            this.scrollToBottom();
        }

    }

    async onDeleteMessage(messageID) {
        const db = await DatabaseChat.get();
        const query = db[this.nameCollection].find().where('ID').eq(messageID);
        await query.remove();
        db[this.nameCollection].find(
            {
                selector: {
                    ID_HOITHOAI: { $eq: this.props.ID_HOITHOAI },
                }
            }
        )
            .sort({ 'NGAYTAO_UTC': 1 }).$.subscribe(response => {
                if (!response) {
                    return;
                }
                this.setState({
                    list_message: response,
                    loading: false
                });
                //nếu số lượng tin nhắn ban đầu nhỏ hơn 20 sẽ tắt chức năng loading
                if (this.state.list_message.length < 20)
                    this.setState({
                        hasMore: false
                    });
                else {
                    this.setState({
                        hasMore: true
                    });
                }
            });
    }
    async componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
        if (this.replicationState) {
            await this.replicationState.cancel();
        }
    }
    onLoadMore = () => {
        this.setState({
            onScrollBottom: false,
            list_limmit_mess_box: this.state.list_limmit_mess_box + 10,
        });
        if (this.state.list_limmit_mess_box >= this.state.list_message.length) {
            this.GetData();
        }
    }
    handleChangeSearch(value) {
        this.setState({ valueSearch: value });
    }
    async changeSearchMessage(change){
        await this.setState({
            isSearch: change ? false : true,
            valueSearch:''
        });
        if (!change) {
            this.searchMessage('');
        }
    }
    async searchMessage(keyword) {
        const db = await DatabaseChat.get();
        this.setState({ loading: true });
        var regexp = new RegExp(keyword,'i');
        db[this.nameCollection].find(
            {
                selector: {
                    ID_HOITHOAI: { $eq: this.props.ID_HOITHOAI },
                    NOIDUNG: { $regex: regexp}
                }
            }
        )
            .sort({ 'NGAYTAO_UTC': 1 }).$.subscribe(response => {
                if (!response) {
                    return;
                }
                this.setState({
                    list_message: response,
                    loading: false
                });
            });
    }
    renderListMess = () => {
        var reverseArrayDataListMessage = this.state.list_message;
        return (
            reverseArrayDataListMessage.slice(0, this.state.list_limmit_mess_box).map((item, index) => {
                return (
                    <div key={index} className={`d-flex align-items-center ${item.ID_NGUOIGUI == this.props.ID_TAIKHOAN ? 'box-chart-guest justify-content-end' : 'box-chart-user'}`}>
                        {/*<img alt="dep-ic" className="dep-ic" src={item.ID_NGUOIGUI != this.props.ID_TAIKHOAN ? this.state.anhdaidien_ht : (this.oauth.anhdaidien != "" ? API_URL + this.oauth.anhdaidien : imgDefault)} width={45} height={45} />*/}
                        <div className="boxMessage">
                            <span dangerouslySetInnerHTML={{ __html: item.NOIDUNG }} title={ baseService.formatFullDateTime(item.NGAYTAO_UTC) }>
                            </span>
                        </div>
                        {(item.ID_NGUOIGUI == this.props.ID_TAIKHOAN) ?
                                <div className="dropdown">
                            <Dropdown>
                                <Dropdown.Toggle variant="" className="dropdown-basic" style={{}}>
                                    ...
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => this.onDeleteMessage(item.ID)}>Xóa</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            </div> : <React.Fragment></React.Fragment>
                            }
                    </div>
                )
            })
        )
    }
    render() {
        if (this.props.ID_HOITHOAI != null && this.props.ID_HOITHOAI != '') {
            return (
                <div className="col-sm-9 col-10 p-0">
                    <div className="MainRight">
                        <div className="TableProject">
                            <div className="MainRight__action d-sm-flex d-flex justify-content-between align-items-center">
                                <div className="MainRight__action--colLeft d-flex align-items-center">
                                    <div className="dotActive" style={{ width: '45px', height: '45px' }}>
                                        <img src={this.state.anhdaidien_ht} alt="" width={45} height={45} style={{ borderRadius: '50px' }} /></div>
                                    <p className="font-weight-bold m-0 ml-2 font-Reular" style={{ fontSize: '18px' }}>{this.state.tieude_ht}</p>
                                </div>
                                <div className="d-sm-flex align-items-center d-none">
                                    <ul className="nav">
                                        <li className="nav-item" style={{ cursor: 'pointer' }}><a onClick={() => this.changeSearchMessage(this.state.isSearch)} className="d-block h-100 d-flex flex-column align-items-center justify-content-center">
                                            <div><svg viewBox="0 0 24 24" role="presentation" style={{ width: '1.5rem', height: '1.5rem' }}>
                                                <path d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z">
                                                </path>
                                            </svg></div>
                                        </a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="search-mess row" style={this.state.isSearch ? {} : { display:'none' }}>
                                <input type="text" className="form-control col-sm-10" placeholder="Nhập từ khóa" value={this.state.valueSearch} onChange={(event) => { this.handleChangeSearch(event.currentTarget.value) }} />
                                <div className="col-sm-2"><a style={{ cursor: 'pointer' }} onClick={() => this.searchMessage(this.state.valueSearch)}>Tìm kiếm </a></div>
                            </div>
                            <div className="chat-box-col-right" style={{ padding: '15px' }} ref={(ref) => this.scrollParentRef = ref}>
                                <InfiniteScroll
                                    pageStart={0}
                                    initialLoad={false}
                                    useWindow={false}
                                    isReverse={true}
                                    loadMore={() => this.onLoadMore()}
                                    hasMore={this.state.hasMore}
                                    getScrollParent={() => this.scrollParentRef}
                                    loader={<div key={0} className="loader">Loading ...</div>}>
                                    {
                                        this.renderListMess()
                                    }
                                </InfiniteScroll>

                            </div>
                            <div className="box-input-chat d-flex p-2 justify-content-center align-items-center">
                                <Formik
                                    validationSchema={schema}
                                    onSubmit={(values, { resetForm }) => {
                                        this.onSubmit(values);
                                        resetForm({})
                                    }}
                                    enableReinitialize={true}
                                    initialValues={this.state.message}

                                >
                                    {({
                                        handleSubmit,
                                        handleChange,
                                        handleBlur,
                                        resetForm,
                                        values,
                                        touched,
                                        errors,
                                    }) => (
                                            <>
                                                <Form noValidate onSubmit={handleSubmit}>
                                                    <Form.Control
                                                        id="NOIDUNG"
                                                        className="h-100 form-control textareaAutosize"
                                                        placeholder="Nhập tin nhắn cho" type="text"
                                                        style={{ border: "0px", height: "31px !important" }}
                                                        value={values.NOIDUNG || ''}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={touched.NOIDUNG && !!errors.NOIDUNG}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.NOIDUNG}
                                                    </Form.Control.Feedback>
                                                    <Button type="submit" className="btn btn-outline text-bold">Gửi</Button>
                                                </Form>
                                            </>
                                        )}

                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="col-sm-9 col-10 p-0">
                    <div className="MainRight">
                        <p>Wellcome</p>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        access_token: state.oauth.access_token,
        ID_TAIKHOAN: state.oauth.idTaiKhoan
    };
};
const Boxmessages = connect(mapStateToProps)(ChatBoxComponent);
export { Boxmessages }