
export const hoiThoaiSchema = {
    version: 0,
    type: 'object',
    properties: {
       
        "ID": {
            "type": 'string',
            "primary": true
        },
        "TIEUDE": {
            "type": 'string'
        },
        "ID_NGUOITAO": {
            "type": 'number'
        },
        "LA_NHOM": {
            "type": 'boolean'
        },
        "NGAYTAO_UTC": {
            "type": 'string'
        },
        "NGAYCHINHSUA_UTC":{
            "type":'string'
        },
        "DELETED":{
            "type": 'boolean'
        },
        "ANHDAIDIEN": {
            "type": ["string", 'null']
        },
        "SL_TINNHAN_CHUADOC": {
            "type": ["number", 'null']
        },
        "DS_THANHVIEN":{
            "type": "array",
            "uniqueItems": true,
            "items": {
                "type": "object",
                "properties": {
                    "ID_TAIKHOAN": {
                        "type": "number"
                    },
                    "TENDANGNHAP":{
                        "type": "string"
                    },
                    "ANHDAIDIEN": {
                        "type": ["string", 'null']
                    },
                    "LANDOC_CUOICUNG_UTC":{
                        "type":'string'
                    },
                    "SL_TINNHAN_CHUADOC":{
                        "type": ["number", 'null']
                    }
                }
            }
        },
        "TINNHAN_CUOICUNG": {
            "type": ["object",'null'],
            "properties": {
                
                "NOIDUNG":{
                    type:'string',
                },
                "NGAYTAO_UTC":{
                    type:'string',
                },
                "NGAYCHINHSUA_UTC":{
                    type:'string',
                },
                //"nGAYXOA":{
                //    type:["string",'null'],
                //},
                "DELETED":{
                    type:'boolean',
                },
                "ID_NGUOIGUI":{
                    type:'number',
                },
                
            }
        }
    },
    indexes: ['NGAYCHINHSUA_UTC', 'NGAYTAO_UTC'],
    required: ['ID']
};

export const graphQLGenerationHoiThoaiInput = {
    message: {
        schema: hoiThoaiSchema,
        feedKeys: [
            'ID',
            'NGAYCHINHSUA_UTC'
        ],
        deletedFlag: 'DELETED'
    }
};
