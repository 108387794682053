import * as Constant from './menuhethongConstant';

const menuhethong = {
    menuhethongList: [],
    meta: {
        page: 1,
        page_size: 20,
        sort: {id:'asc'},
        search: '',
        filter: {           
        },
    }
};
const menuhethongReducer = (state = menuhethong, action) => {
    switch (action.type) {
        case Constant.MENUHETHONG_SET_LIST_DATA:
            return Object.assign({}, state, { menuhethongList: action.menuhethongList });
        case Constant.MENUHETHONG_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export {menuhethongReducer};