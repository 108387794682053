
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './redux/store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LoadingComponent, RouteGuardComponent as RouteGuard,AlertComponent,ConfirmComponent} from 'shared/components';
import '@iconify/iconify';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../src/styles/styles.scss';
import { PublicLayout } from './layouts/PublicLayout';
import OauthCallback from './modules/oauth-callback/OauthCallback';
import { LogoutComponent } from './modules/logout';
import { LoginComponent } from './modules/Public/login';
import { chatModule } from './modules/Public/chathome/chatModule';
import { QuenMatKhauComponent } from './modules/Public/taikhoan/quenmatkhau';
import { QuenMatKhauService, Context } from 'modules/Public/taikhoan/quenmatkhau/QuenMatKhauService';
import { BehaviorsContext } from 'shared/services';
import { NhapMatKhauComponent } from 'modules/Public/taikhoan/quenmatkhau/nhapmatkhau/NhapMatKhauComponent';
class App extends React.Component {
    render() {
        return (
            <BehaviorsContext.Consumer>
                {
                    ({ beh }) => (
                        <Context.Provider value={{
                            QuenMatKhauService: QuenMatKhauService,
                            beh: beh
                        }} >
                            <Provider store={store}>
                                <PersistGate loading={<LoadingComponent loading={true} />} persistor={persistor}>
                                    <Router>
                                        <Switch>
                                            <Route path="/oauth-callback" component={OauthCallback} />
                                            <Route path="/dang-xuat" component={LogoutComponent} />
                                            <Route path="/dang-nhap" component={LoginComponent} />
                                            <Route path="/quen-mat-khau" component={QuenMatKhauComponent} />
                                            <Route path="/nhap-mat-khau" component={NhapMatKhauComponent} />
                                            <RouteGuard path="/bai-dang" component={PublicLayout} />
                                            <RouteGuard path="/phieu-khao-sat" component={PublicLayout} />
                                            <RouteGuard path="/tu-lieu-dong-gop" component={PublicLayout} />
                                            <RouteGuard path="/chat" component={chatModule} />
                                            <RouteGuard path="/bao-cao" per="05" component={PublicLayout} />
                                            <Route path="/" component={PublicLayout} />
                                        </Switch>
                                    </Router>
                                    <AlertComponent />
                                    <ConfirmComponent />
                                </PersistGate>
                            </Provider>

                        </Context.Provider>
                        )
                }
            </BehaviorsContext.Consumer>

   
        );
    }
}
export default App;