import { DM_CHI_TIEU_NGUOI_DUNG_TICH_CUC_SET_LIST_DATA, DM_CHI_TIEU_NGUOI_DUNG_TICH_CUC_SET_LIST_META } from './DMChiTieuNguoiDungTichCucConstant';

const dmchitieunguoidungtichcuc = {
    DMChiTieuNguoiDungTichCucList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '',
        filter: {
            unitId: '',
            unitTraversalOption: '0',
            _: new Date().getTime()
            
        },
    }
};
const DMChiTieuNguoiDungTichCucReducer = (state = dmchitieunguoidungtichcuc, action) => {
    switch (action.type) {
    case DM_CHI_TIEU_NGUOI_DUNG_TICH_CUC_SET_LIST_DATA:
            return Object.assign({}, state, { DMChiTieuNguoiDungTichCucList: action.DMChiTieuNguoiDungTichCucList });
    case DM_CHI_TIEU_NGUOI_DUNG_TICH_CUC_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export {DMChiTieuNguoiDungTichCucReducer};