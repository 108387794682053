import * as Constant from './dmdoituongchiaseConstant';

const dmdoituongchiase = {
    dmdoituongchiaseList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: ''        
    }
};
const dmdoituongchiaseReducer = (state = dmdoituongchiase, action) => {
    switch (action.type) {
        case Constant.DMDOITUONGCHIASE_SET_LIST_DATA:
            return Object.assign({}, state, { dmdoituongchiaseList: action.dmdoituongchiaseList });
        case Constant.DMDOITUONGCHIASE_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export {dmdoituongchiaseReducer};