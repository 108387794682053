
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { _BaiVietService, Context } from '../BaiVietService';
import { API_URL } from 'app-setting';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import NoImg from 'assets/images/NoImageBinhLuan.png';
import { Spinner } from 'react-bootstrap';
import { store } from 'redux/store';
class KetNoiCuaToiComponent extends Component {
    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
    }
    static contextType = Context;
    constructor(props) {
        super(props);
        this.oauth = store.getState().oauth;
        this.state = {
            loading: true,
            isShow: true,
            meta: {
                page: 1,
                sortBy: 'ID',
                page_size: 6,
                sortDesc: false,
                total: 0,
            },
            data: [],
        };
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        let { meta } = this.state;
        _BaiVietService.getKetNoiCuaToi(meta).subscribe(res => {
            meta.total = res.totalRows;
            this.setState({ data: res.data, isShow: false },
            );
        }, err => {
            console.log(err);
            this.setState({ isShow: false });
        });
    }

    render() {
        let { data, isShow, meta } = this.state;
        if (!isShow) {
            return (
                !(data && data.length) ? '' :
                    <div className="box-right-baiviet box-ket-noi">
                        <div className="box-title_main d-flex justify-content-between align-items-center">
                            <p className="m-0 color-blue font-16 text-uppercase font-Bold">Kết nối của tôi</p>
                            <i className="fa fa-users font-20 color-blue" aria-hidden="true" />
                        </div>
                        <div className="d-flex flex-wrap boxMainUser">
                            {
                                data.map((item, index) => {
                                    return (
                                        <div className="userBox d-flex mb-2 p-1" key={index}>
                                            <div className="mb-1 pr-1">
                                                <img className="rounded-circle" style={{ width: '50px', height: '50px' }} src={
                                                    this.oauth.idTaiKhoan === item.iD_NGUOINHAN ? (item.nguoigui && item.nguoigui.anhDaiDien) ? `${API_URL}${item.nguoigui.anhDaiDien}` : `${NoImg}`
                                                        : (item.nguoinhan && item.nguoinhan.anhDaiDien) ? `${API_URL}${item.nguoinhan.anhDaiDien}` : `${NoImg}`

                                                } alt="" />
                                            </div>
                                            <div className="flex-column" style={{ maxHeight: 50 }}>
                                                {
                                                    this.oauth.idTaiKhoan === item.iD_NGUOINHAN ?
                                                        <React.Fragment>
                                                            <Link to={`/profile/${item.nguoigui.tendangnhap}`} className="mb-0 color-blue font-Bold font-12 limited-line-1">{item.nguoigui.title}</Link>
                                                            <Link to={`/chat/${item.nguoigui.tendangnhap}`} className="btn btn-sm btn-info btn-custom-sm font-10"><i className="fa fa-comments mr-1"></i>Nhắn tin</Link>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <Link to={`/profile/${item.nguoinhan.tendangnhap}`} className="mb-0 color-blue font-Bold font-12 limited-line-1">{item.nguoinhan.title}</Link>
                                                            <Link to={`/chat/${item.nguoinhan.tendangnhap}`} className="btn btn-sm btn-info btn-custom-sm font-10"><i className="fa fa-comments mr-1"></i>Nhắn tin</Link>
                                                        </React.Fragment>
                                                }
                                                {/* <p className="font-10 m-0 font-Light">Đang trực tuyển</p> */}

                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {
                            meta.total > meta.page_size ? <div className="btn-xem-them text-center p-1">
                                <Link to={'/ket-noi'} className="font-12 font-Regular color-blue text-link m-auto">Xem thêm <i className="fa fa-angle-down" aria-hidden="true" /></Link>
                            </div> : ''
                        }
                    </div>
            );
        }
        else {
            return (<Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>);
        }

    }
}

export default KetNoiCuaToiComponent;