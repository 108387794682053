
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { _BaiVietService, Context } from '../BaiVietService';
import { API_URL } from 'app-setting';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import NoImg from 'assets/images/NoImageBinhLuan.png';
import { Spinner } from 'react-bootstrap';
import { findIndex } from 'lodash';
import { store } from 'redux/store';
class LoiMoiKetNoiComponent extends Component {
    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
    }
    static contextType = Context;
    constructor(props) {
        super(props);
        this.oauth = store.getState().oauth;
        this.state = {
            loading: true,
            isShow: true,
            meta: {
                page: 1,
                sortBy: 'ID',
                page_size: 6,
                sortDesc: false,
                total: 0,
            },
            data: [],
        };
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        let { meta } = this.state;
        _BaiVietService.getLoiMoiKetNoi(meta).subscribe(res => {
            meta.total = res.length;
            this.setState({ data: res, isShow: false },
            );
        }, err => {
            console.log(err);
            this.setState({ isShow: false });
        });
    }
    actXacNhan(id) {
        if (this.oauth.idTaiKhoan != null && this.oauth.idTaiKhoan > 0) {
            let { data } = this.state;
            let index = findIndex(data, function (o) { return o.id === id; });
            if (index !== -1) {
                data[index].ischeck = false;
                this.setState({ data: data, loading: false });
                _BaiVietService.dongYLoiMoiKetNoi(id).subscribe(() => {
                    // this.context.beh.alert('Yêu cầu kết nối của bạn đã được gửi đến ' + item.title); // xử lý gửi kết nối                
                },
                    (err) => {
                        console.log(err.error);
                    });
            }

        } else {
            this.props.history.push({ pathname: '/dang-nhap' });
        }
    }

    actXoa(id) {
        if (this.oauth.idTaiKhoan != null && this.oauth.idTaiKhoan > 0) {
            let { data } = this.state;
            let index = findIndex(data, function (o) { return o.id === id; });
            if (index !== -1) {
                data[index].ischeck = false;
                this.setState({ data: data, loading: false });
                _BaiVietService.huyLoiMoiKetNoi(id).subscribe(() => {
                    // this.context.beh.alert('Yêu cầu kết nối của bạn đã được gửi đến ' + item.title); // xử lý gửi kết nối                
                },
                    (err) => {
                        console.log(err.error);
                    });
            }

        } else {
            this.props.history.push({ pathname: '/dang-nhap' });
        }
    }

    render() {
        let { data, isShow, meta } = this.state;
        if (!isShow) {
            return (
                !(data && data.length) ? '' :
                    <div className="box-right-baiviet box-ket-noi">
                        <div className="box-title_main d-flex justify-content-between align-items-center">
                            <p className="m-0 color-blue font-16 text-uppercase font-Bold">Lời mời kết nối</p>
                            <i className="fa fa-users font-20 color-blue" aria-hidden="true" />
                        </div>
                        <div className="d-flex flex-wrap boxMainUser">
                            {
                                data.map((item, index) => {
                                    return (
                                        <div className="userBox d-flex mb-2 p-1" key={index}>
                                            <div className="mb-1 pr-1">
                                                <img className="rounded-circle" style={{ width: '50px', height: '50px' }} src={
                                                    (item.anhDaiDien) ? `${API_URL}${item.anhDaiDien}` : `${NoImg}`
                                                } alt="" />
                                            </div>
                                            <div className="d-flex flex-column" style={{ maxHeight: 50 }}>
                                                {
                                                    this.oauth.idTaiKhoan === item.iD_NGUOINHAN ?
                                                        <React.Fragment>
                                                            <Link to={`/profile/${item.nguoigui.tendangnhap}`} className="mb-0 color-blue font-Bold font-12 limited-line-1">{item.nguoigui.title}</Link>
                                                            {
                                                                (item.ischeck === true) ?
                                                                    <div className="d-flex mt-auto">
                                                                        <button onClick={() => this.actXacNhan(item.id)} className="btn btn-sm btn-primary btn-custom-sm font-10 mr-2">Xác nhận</button>
                                                                        <button onClick={() => this.actXoa(item.id)} className="btn btn-sm btn-outline-secondary btn-custom-sm font-10">Xóa</button>
                                                                    </div>
                                                                    : ''
                                                            }

                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <Link to={`/profile/${item.nguoinhan.tendangnhap}`} className="mb-0 color-blue font-Bold font-12 limited-line-1">{item.nguoinhan.title}</Link>
                                                            {
                                                                (item.ischeck === true) ?
                                                                    <div className="d-flex mt-auto">
                                                                        <button onClick={() => this.actXacNhan(item.id)} className="btn btn-sm btn-primary btn-custom-sm font-10 mr-2">Xác nhận</button>
                                                                        <button onClick={() => this.actXoa(item.id)} className="btn btn-sm btn-outline-secondary btn-custom-sm font-10">Xóa</button>
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        {
                            meta.total > meta.page_size ? <div className="btn-xem-them text-center p-1">
                                <Link to={'/ket-noi'} className="font-12 font-Regular color-blue text-link m-auto">Xem thêm <i className="fa fa-angle-down" aria-hidden="true" /></Link>
                            </div> : ''
                        }
                    </div>
            );
        }
        else {
            return (<Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
            </Spinner>);
        }

    }
}

export default LoiMoiKetNoiComponent;