import * as Constant from './dmchuyenmuctinConstant';

const dmchuyenmuctin = {
    dmchuyenmuctinList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: ''        
    }
};
const dmchuyenmuctinReducer = (state = dmchuyenmuctin, action) => {
    switch (action.type) {
    case Constant.DMCHUYENMUCTIN_SET_LIST_DATA:
        return Object.assign({}, state, { dmchuyenmuctinList: action.dmchuyenmuctinList });
    case Constant.DMCHUYENMUCTIN_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {dmchuyenmuctinReducer};