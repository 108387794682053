import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { API_URL } from 'app-setting';
import { NavLink } from 'react-router-dom';

class SuKienSapDienRa extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataAreaViolations: [],
            dataTCCNVP: []
        }
    }

    componentDidMount() {
        this.getDataAreaViolations();
    }
    formatDateTime(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var output = day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            return output;
        }
    }
    slugURL(str) {
        if (str) {
            // Chuyển hết sang chữ thường
            str = str.toLowerCase();

            // xóa dấu
            str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
            str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
            str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
            str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
            str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
            str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
            str = str.replace(/(đ)/g, 'd');

            // Xóa ký tự đặc biệt
            str = str.replace(/([^0-9a-z-\s])/g, '');

            // Xóa khoảng trắng thay bằng ký tự -
            str = str.replace(/(\s+)/g, '-');

            // xóa phần dự - ở đầu
            str = str.replace(/^-+/g, '');

            // xóa phần dư - ở cuối
            str = str.replace(/-+$/g, '');

            // return
            return str;
        }
        else
            return '';

    }
    formatDate(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var output = day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            return output;
        }
    }
    formatTime(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var hours = d.getHours();
            var minutes = d.getMinutes();
            var output = hours + 'h' + (minutes < 1 ? '00' : minutes);
            return output;
        }
    }
    getDataAreaViolations() {
        fetch(`${API_URL}api/dnsukien/getlistsknearly?ItemsPerPage=3`)
            .then(res => res.json())
            .then((data) => {
                this.setState({ dataTCCNVP: data.data.slice(0,1) });
            })
            .catch(console.log)
    }
    render() {
        return (
            <div>
               
                {this.state.dataTCCNVP.map((item, i) => {
                    return (
                        <div className="box-suKienSapDienRa" key={i}>
                            <div className="title-top">
                                <p className="m-0 font-Bold">Sự kiện sắp diễn ra</p>
                            </div>
                            <div className="item-suKienSapDienRa d-flex">
                                <div className="col-6">
                                    <p to={`/san-pham/${item.id}`}>
                                        {(item.lstattachment != undefined && item.lstattachment.length > 0) ?
                                            <NavLink to={`/su-kien/${item.id}-${this.slugURL(item.title)}.html`}>
                                                <img className="max-image" src={`${API_URL}` + item.lstattachment[0].url} alt="" />
                                            </NavLink> : <NavLink to={`/su-kien/${item.id}-${this.slugURL(item.title)}.html`}>
                                                <img className="max-image" src="https://images.fpt.shop/unsafe/fit-in/192x192/filters:quality(90):fill(white)/cdn.fptshop.com.vn/Uploads/Originals/2019/9/11/637037651956109900_11-chung.png" alt="" />
                                            </NavLink>
                                        }
                                      </p>
                                 </div>
                                <div className="col-6 d-flex flex-column justify-content-between">
                                    <p className="m-0 font-14 font-Bold color-blue text-link">{this.formatDate(item.ngaybatdau)} | {this.formatTime(item.ngaybatdau)} 
                                        </p>
                                    <NavLink to={`/su-kien/${item.id}-${this.slugURL(item.title)}.html`} className="m-0 font-14 font-Bold color-blue text-link limit-line-4 text-uppercase">{item.title}</NavLink>
                                    <NavLink to={`/su-kien/${item.id}-${this.slugURL(item.title)}.html`} className="text-danger text-link font-12 font-Light mt-auto">Xem thêm ></NavLink>

                                    </div>
                                </div>
                            </div>
                       
                    )
                })}


            </div>
        );
    }
}

export default SuKienSapDienRa;