import * as Constant from './dmchitieudanhgiaConstant';

const dmchitieudanhgia = {
    dmchitieudanhgiaList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: ''        
    }
};
const dmchitieudanhgiaReducer = (state = dmchitieudanhgia, action) => {
    switch (action.type) {
        case Constant.DMCHITIEUDANHGIA_SET_LIST_DATA:
            return Object.assign({}, state, { dmchitieudanhgiaList: action.dmchitieudanhgiaList });
        case Constant.DMCHITIEUDANHGIA_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { dmchitieudanhgiaReducer};