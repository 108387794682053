import * as Constant from './dmhanhviviphamConstant';

const dmhanhvivipham = {
    dmhanhviviphamList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: ''        
    }
};
const dmhanhviviphamReducer = (state = dmhanhvivipham, action) => {
    switch (action.type) {
        case Constant.DMHANHVIVIPHAM_SET_LIST_DATA:
            return Object.assign({}, state, { dmhanhviviphamList: action.dmhanhviviphamList });
        case Constant.DMHANHVIVIPHAM_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { dmhanhviviphamReducer};