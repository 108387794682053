import * as Constant from './bannerquangcaoConstant';

const bannerquangcao = {
    bannerquangcaoList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'asc'},
        search: ''        
    }
};
const bannerquangcaoReducer = (state = bannerquangcao, action) => {
    switch (action.type) {
        case Constant.BANNERQUANGCAO_SET_LIST_DATA:
            return Object.assign({}, state, { bannerquangcaoList: action.bannerquangcaoList });
        case Constant.BANNERQUANGCAO_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { bannerquangcaoReducer};