import * as Constant from './bvtienichConstant';

const bvtienich = {
    bvtienichList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id: 'desc' },
        search: ''        
    }
};
const bvtienichReducer = (state = bvtienich, action) => {
    switch (action.type) {
        case Constant.BVTIENICH_SET_LIST_DATA:
            return Object.assign({}, state, { bvtienichList: action.bvtienichList });
        case Constant.BVTIENICH_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { bvtienichReducer};