import { DM_LOAI_THONG_BAO_SET_LIST_DATA, DM_LOAI_THONG_BAO_SET_LIST_META } from './DMLoaiThongBaoConstant';

const dmloaithongbao = {
    DMLoaiThongBaoList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '',
        filter: {
            unitId: '',
            unitTraversalOption: '0',
            _: new Date().getTime()
            
        },
    }
};
const DMLoaiThongBaoReducer = (state = dmloaithongbao, action) => {
    switch (action.type) {
    case DM_LOAI_THONG_BAO_SET_LIST_DATA:
        return Object.assign({}, state, { DMLoaiThongBaoList: action.DMLoaiThongBaoList });
    case DM_LOAI_THONG_BAO_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {DMLoaiThongBaoReducer};