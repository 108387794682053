import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Image } from 'react-bootstrap';
import { string, object } from 'yup';
import { Card, Form, InputGroup, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { LoadingComponent, Page } from 'shared/components';
import { authService } from 'shared/services';
import { OauthAction, TKTaiKhoanAction } from 'redux/actions';
import logoNavBar from 'assets/images/logo-new.png'
import '@iconify/iconify';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/styles.scss';
import { NavLink } from 'react-router-dom';
import { store } from 'redux/store';
const schema = object({
    username: string().trim().required('Cần phải nhập tên tài khoản'),
    password: string().trim().required('Cần phải nhập mật khẩu'),
});

class Login extends React.Component {
   
    constructor(props) {
        super(props);
        this.oauth = store.getState().oauth;
        this.state = {
            data: {
                username: '',
                password: '',
            },
            loading: false,
            errorMessage: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
    }

    handleSubmit(data) {
        this.setState({ loading: true });
        authService.login(data).subscribe(() => {
            //đăng nhập thành công
            this.setState({ loading: false, errorMessage: '' });
            const { from } = this.props.location.state || { from: { pathname: '/' } };
            this.props.history.push(from);
        }, (err) => {
                debugger;
            if (err.status === 400) {
                this.setState({ loading: false, errorMessage: 'Tài khoản hoặc mật khẩu không chính xác, vui lòng kiểm tra lại' });
            } else {
                this.setState({ loading: false, errorMessage: 'Có lỗi xảy ra trong quá trình login' });
            }
        });
    }

    render() {
        return (

            <Page>

                <Page.Content>
                    <div className="d-flex justify-content-center align-items-center h-100 login-page-public" >
                        <div className="login-box" style={{ width: 400 }}>

                            {/* /.login-logo */}
                            <Card style={{ marginTop: '50px', marginBottom: '50px' }} >
                                <Card.Body>
                                    <a href='/'> <Image className="d-flex m-auto" style={{ fontSize: 48, borderColor: "#90a4ae", color: "#90a4ae", borderRadius: 100, borderWidth: 2, borderStyle: "solid", padding: 20, width: 130, height: 130, marginBottom: 10 }} src={logoNavBar} /></a>
                                    <h5 className="text-center text-dark mt-3" style={{ fontSize: 18 }}>Hệ thống Quản lý hàng thật hàng giả</h5>
                                    <p className="login-box-msg text-center">
                                        Đăng nhập để bắt đầu phiên của bạn
                                <br />
                                        <i className="text-danger">{this.state.errorMessage}</i>
                                    </p>

                                    <Formik
                                        validationSchema={schema}
                                        onSubmit={this.handleSubmit}
                                        // enableReinitialize={true}
                                        initialValues={this.state.data}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            handleBlur,
                                            values,
                                            touched,
                                            errors,
                                        }) => (
                                                <Form noValidate onSubmit={handleSubmit}>
                                                    <Form.Group>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                name="username"
                                                                placeholder="Tài khoản"
                                                                aria-label="Username"
                                                                autoComplete="on"
                                                                autoFocus
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.username}
                                                                isInvalid={touched.username && !!errors.username}
                                                            />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text>
                                                                    <span className="iconify" data-icon="fa-solid:user" data-inline="false" />
                                                                </InputGroup.Text>
                                                            </InputGroup.Append>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.username}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                name="password"
                                                                type="password"
                                                                placeholder="Mật khẩu"
                                                                aria-label="Password"
                                                                autoComplete="on"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.password}
                                                                isInvalid={touched.password && !!errors.password}
                                                            />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text>
                                                                    <span className="iconify" data-icon="fa:lock" data-inline="false" />
                                                                </InputGroup.Text>
                                                            </InputGroup.Append>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.password}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Row>
                                                        <Col className="col-12">
                                                            <button type="submit" className="btn btn-primary btn-block">Đăng nhập</button>
                                                        </Col>
                                                    </Row>

                                                    <Row className="mt-2">
                                                        <Col className="login-box-msg col-6" style={{ textAlign: 'center' }}>
                                                            <NavLink to={`/dang-ky-tai-khoan`} className=" text-link m-0 font-26 color-black font-Regular mb-3 title-top" style={{ lineHeight: '35px' }}>Đăng ký</NavLink>

                                                        </Col>
                                                        <Col className="login-box-msg col-6" style={{ textAlign: 'center' }}>
                                                            <NavLink to={`/quen-mat-khau`} className=" text-link m-0 font-26 color-black font-Regular mb-3 title-top" style={{ lineHeight: '35px' }}>Quên mật khẩu</NavLink>

                                                        </Col>
                                                    </Row>
                                                </Form>
                                            )}
                                    </Formik>
                                    {/* /.login-card-body */}
                                </Card.Body>
                            </Card>
                        </div>
                        <LoadingComponent loading={this.state.loading} />

                    </div>
                </Page.Content>
            </Page>



        );
    }
}
Login.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

const LoginComponent = connect(null, { setToken: OauthAction.setToken })(Login);
export { LoginComponent };
