import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { API_URL } from 'app-setting';
import { NavLink } from 'react-router-dom';

class DiaBanViPham extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataAreaViolations: [],
            dataTCCNVP: []
        }
    }

    componentDidMount() {
        this.getDataAreaViolations();
    }
    formatDateTime(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var output = day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            return output;
        }
    }
    getDataAreaViolations() {
        fetch(`${API_URL}api/hosovuviec/listdbvpnew?ItemsPerPage=3`)
            .then(res => res.json())
            .then((data) => {
                this.setState({ dataTCCNVP: data.data })
            })
            .catch(console.log)
    }
    render() {
        return (
            <div>
                <div className="box-title_main_gray d-flex justify-content-between align-items-center">
                    <NavLink to={`/dia-ban-xay-ra-vi-pham`} className="m-0 color-blue font-14 text-uppercase font-Bold">
                        Tình hình xử lý vi phạm
                </NavLink>
                   
                    <i className="fa fa-exclamation font-20 color-blue" aria-hidden="true"></i>
                </div>
                {this.state.dataTCCNVP.map((item, i) => {
                    return (
                        <div className="itemDiaDiemViPham container" key={i}>
                            <div>
                                <NavLink to={`/dia-ban-xay-ra-vi-pham/${item.id}`} className="font-12 font-Bold m-0 mb-0 color-black row">
                                <div className="col-md-9">
                                    <div className="d-flex">
                                            <i className="fa fa-map-marker color-danger" aria-hidden="true" /> 	&nbsp;
                                            <p className="font-12 font-Bold m-0 mb-0 color-black">{item.titlE_TINHTHANH}</p>
                                        
                                    </div>
                                        <p className="font-12 font-Regular color-black mb-1">{item.diachi}</p>

                                </div>
                                <div className="col-md-3">
                                        <p className="font-12 font-Regular color-black m-0">{this.formatDateTime(item.ngayvipham)}</p>
                                    </div> 
                                </NavLink>
                            </div>
                           <br></br>
                        </div>
                       
                    )
                })}
                <hr></hr>
                <NavLink to={`/dia-ban-xay-ra-vi-pham`} className="font-12 font-Regular color-blue text-link d-flex justify-content-center align-items-center">
                    Xem thêm &nbsp;<i className="fa fa-angle-down" aria-hidden="true"></i>

                </NavLink>


            </div>
        );
    }
}

export default DiaBanViPham;