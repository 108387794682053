
export const tinNhanSchema = {
    version: 0,
    type: 'object',
    properties: {
        "ID": {
            type: 'string',
            primary: true
        },
        "ID_HOITHOAI": {
            type: 'string'
        },
        "NOIDUNG": {
            type: 'string'
        },
        "NGAYTAO_UTC": {
            type: 'string'
        },
        "NGAYCHINHSUA_UTC":{
            type:'string'
        },
        "ID_NGUOIGUI": {
            type: 'number'
        },
        "DELETED":{
            type: 'boolean'
        },
        "LUOTTHICH": {
            type: 'number',
            default: 0
        },
        "LUOTSHARE": {
            type: 'number',
            default: 0
        },
        "TRANGTHAI": {
            type: 'number',
            default: 0
        }
    },
    indexes: ['NOIDUNG','NGAYCHINHSUA_UTC','NGAYTAO_UTC'],
    required: ['ID', 'NOIDUNG']
};

export const graphQLGenerationTinNhanInput = {
    message: {
        schema: tinNhanSchema,
        feedKeys: [
            'ID',
            'NGAYCHINHSUA_UTC'
        ],
        deletedFlag: 'DELETED'
    }
};
