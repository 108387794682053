import * as actions from './sanphamdnConstants';

const sanphamdn = {
    sanphamdnList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'desc'},
        search: '',
        filter: {
            status:0
        },
    }
};
const sanphamdnReducer = (state = sanphamdn, action) => {
    switch (action.type) {
    case actions.SANPHAMDN_SET_LIST_DATA:
        return Object.assign({}, state, { sanphamdnList: action.sanphamdnList });
    case actions.SANPHAMDN_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {sanphamdnReducer};