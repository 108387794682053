import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/Public/custom-style.scss';
import '../../../styles/Public/responsive.scss';
import "jquery/src/jquery";
import HoiDap from '../../../modules/layoutright/HoiDap';
import DiaBanViPham from '../../../modules/layoutright/DiaBanViPham';
import SPBaoCaoViPham from '../../../modules/layoutright/SPBaoCaoViPham';
import SuKienSapDienRa from '../../../modules/layoutright/SuKienSapDienRa';
import { OauthAction } from 'redux/actions';
import { store } from 'redux/store';
import { connect } from 'react-redux';
const Layout2cContext = React.createContext();

class Left extends Component {
    static propTypes = {
        className: PropTypes.string
    };
    render() {
        const { className, ...props } = this.props;
        return (
            <Layout2cContext.Consumer>
                {() => (
                    <div className={['col-md-9', className].join(' ')} {...props}>{props.children}</div>
                )}
            </Layout2cContext.Consumer>

        );
    }

}

class Right extends Component {

    constructor(props) {
        super(props);
        this.oauth = store.getState().oauth;
  
    }
    static propTypes = {
        className: PropTypes.string,
    };
    render() {
        const { className, ...props } = this.props;
        return (
            <Layout2cContext.Consumer>

                {() => (

                    <div className={['col-md-3', className].join(' ')} {...props}>{props.children}
                        {/*
                         {this.oauth.access_token != null ? (
                            <SuKienSapDienRa />
                        ) : ""}
                         */} 
                        <SPBaoCaoViPham />
                        <HoiDap />
                        <DiaBanViPham />
                    </div>
                )}
            </Layout2cContext.Consumer>
        );
    }
}
class Layout2c extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object,
            this.oauth = store.getState().oauth,
        ])
    };


    render() {
        return (
            <Layout2cContext.Provider {...this.props}>
                <div className="container">
                    <div className="row">
                        {this.props.children}
                    </div>
                </div>
            </Layout2cContext.Provider>
        );
    }
}
Layout2c.Left = Left;
Layout2c.Right = Right;
const Layout2cComponent = connect(null, { setToken: OauthAction.setToken })(Layout2c);
export { Layout2cComponent };