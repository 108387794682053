import * as Constant from './logSysConstant';

const logSys = {
    logSysList: [],
    meta: {
        page: 1,
        page_size: 20,
        sort: {id:'desc'},
        search: '',
        filter: {
            thaotac: 0,
            table:'',
        },
    }
};
const logSysReducer = (state = logSys, action) => {
    switch (action.type) {
        case Constant.LOGSYS_SET_LIST_DATA:
            return Object.assign({}, state, { logSysList: action.logSysList });
        case Constant.LOGSYS_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export {logSysReducer};