import * as Constant from './tochuccanhanviphamConstant';

const tochuccanhanvipham = {
    tochuccanhanviphamList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id: 'asc' },
        search: '',
        filter: {
            id_linhvuc: 0,
            id_loaisp: 0,
        }    
    }
};
const tochuccanhanviphamReducer = (state = tochuccanhanvipham, action) => {
    switch (action.type) {
        case Constant.TOCHUCCANHANVIPHAM_SET_LIST_DATA:
            return Object.assign({}, state, { tochuccanhanviphamList: action.tochuccanhanviphamList });
        case Constant.TOCHUCCANHANVIPHAM_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { tochuccanhanviphamReducer};