import { BaseService } from 'shared/services';
import { API_URL } from 'app-setting';
import { http } from 'shared/utils';
import { createContext } from 'react';
import { Subject } from 'rxjs';
class TienIchService extends BaseService {
    constructor(props) {
        const _props = Object.assign({}, { url: 'api/chcauhoi/lists' }, props);
        super(_props);
        this.sendToForm = new Subject();
    }
    getTienIchByType(type) {
        return http.get(`${API_URL}api/bvtienich/getitembytype?type=${type}`);
    }
  
    getListCauHoiTheoChuDe(obj) {

        return http.get(`${API_URL}api/chcauhoi/getchcungchude?id_chude=${obj.filter.id_chude}&id_cauhoi=${obj.filter.id_cauhoi}`);
    }
    getListCauHoiKhac(obj) {

        return http.get(`${API_URL}api/chcauhoithuonggap/getchkhac?id_cauhoi=${obj.filter.id_cauhoi}`);
    }
    getCauHoiByID(id) {
        return http.get(`${API_URL}api/chcauhoi/getitembyid/${id}`);
    }
    getCauHoiThuongGapByID(id) {
        return http.get(`${API_URL}api/chcauhoithuonggap/getitembyid/${id}`);
    }
    //chỉ tiêu đánh giá hệ thống
    getchitieudanhgiahethong() {
        return http.get(`${API_URL}api/dmchitieudanhgia?ItemsPerPage=-1&Page=1&type=1`);
    }
    //gửi đánh giá hệ thống
    createdanhgiahethong(obj) {
        return http.post(`${API_URL}api/danhgiavehethong`, obj);
    }
    //get đánh giá hệ thống
    getdanhgiahethong(obj) {
        return http.get(`${API_URL}api/danhgiavehethong/getbycurrentuser`);
    }
 
    //thông báo
    getDataNotify(id,itemsPerPage,strNotify) {
        return http.get(`${API_URL}api/thongbao?page=1&itemsPerPage=${itemsPerPage}&IDNguoiNhan=${id}&FilterText=${strNotify}`);
    }
    updateNotify(obj, id) {
        return http.put(`${API_URL}api/thongbao/changestatus`, obj);
    }
    delNotify(id) {
        return http.delete(`${API_URL}api/thongbao/${id}`);
    }
    getlstCauHoiThuongGap(props) {
        const { page, page_size, sort, search, filter } = props;
        let sortBy = '';
        let sortDesc = false;
        let title = search; // do api chua co tim kiem theo tu khoa nen de tam search se tim kiem theo username
        if (sort) {
            sortBy = Object.keys(sort)[0];
            sortDesc = sort[sortBy] === 'desc';
        }
        const params = Object.assign({}, {
            page: page,
            itemsPerPage: page_size,
            sortBy: sortBy,
            sortDesc: sortDesc,
            FilterText: title
        }, filter);
        return http.get(`api/chcauhoithuonggap/lists`, { params: params });
    }
}
const tienichService = new TienIchService();
const Context = createContext();
export { Context, tienichService, TienIchService };