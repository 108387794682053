import * as Constant from './dmdoituongquanlyConstant';

const dmdoituongquanly = {
    dmdoituongquanlyList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: ''        
    }
};
const dmdoituongquanlyReducer = (state = dmdoituongquanly, action) => {
    switch (action.type) {
        case Constant.DMDOITUONGQUANLY_SET_LIST_DATA:
            return Object.assign({}, state, { dmdoituongquanlyList: action.dmdoituongquanlyList });
        case Constant.DMDOITUONGQUANLY_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export {dmdoituongquanlyReducer};