import React, { Component } from "react";
import { LoadingComponent, Layout2c } from "shared/components";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { API_URL } from "app-setting";
import { http } from "shared/utils";
import * as DatabaseChat from "../../../../database/chat";
import { wsClient } from "../../../../database/chat";
import { Boxmessages } from "../messages/boxmessages";
import {
    pullQueryBuilderHoiThoai,
    pushQueryBuilderHoiThoai,
    subcriptionHoiThoai,
    pushCreatHoiThoai
} from "../../../../database/chat/hoithoaiCollection";
import imgDefault from "assets/images/img-user-default.png";
import { Modal, Dropdown } from "react-bootstrap";
import NoImg from "assets/images/unavatar.jpg";
import logoHeader from 'assets/images/logo-header.png';
import Nav from "react-bootstrap/Nav";
import Icon from "@mdi/react";
import { mdiMenuDownOutline } from '@mdi/js';
import { _hoithoaiService, Context } from '../hoithoaiService';
import { hoiThoaiSchema } from '../../../../database/chat/hoiThoaiSchema';
import { store } from 'redux/store';
class HoithoaiComponents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: {},
            list_hoithoai: [],
            selected_ID_HOITHOAI: "",
            showBoxAddMessModal: false,
            dataKN: [],
            meta: {
                page: 1,
                sortBy: 'id',
                page_size: 10,
                sortDesc: false,
                total: 0,
                keyword: '',
                filter: {
                }
            },
        };
        this.subscriptions = {};
        this.hoithoaiCollection = "hoithoai" + props.ID_TAIKHOAN + "ht";
        this.oauth = store.getState().oauth;
    }
    async RunReplication() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
        if (this.replicationState) {
            await this.replicationState.cancel();
        }

        const db = await DatabaseChat.get();
        if (!db[this.hoithoaiCollection]) {
            const collection = await db.collection({
                name: this.hoithoaiCollection,
                schema: hoiThoaiSchema
            });
        }
        let batchSize = 10;
        //console.log('Hoi thoai Start replication..');
        this.replicationState = db[this.hoithoaiCollection].syncGraphQL({
            url: API_URL, // url to the GraphQL endpoint
            headers: {
                Authorization: "Bearer " + this.props.access_token,
            },
            pull: {
                queryBuilder: pullQueryBuilderHoiThoai, // the queryBuilder from above
            },
            push: {
                batchSize,
                queryBuilder: pushQueryBuilderHoiThoai,
            },
            deletedFlag: "DELETED", // the flag which indicates if a pulled document is deleted
            live: true, // if this is true, rxdb will watch for ongoing changes and sync them, when false, a one-time-replication will be done
            liveInterval: 60000,
        });
        this.subscriptions[
            "replicationStateError"
        ] = this.replicationState.error$.subscribe((err) => {
            // nếu xảy ra lỗi sẽ call 1 api bất kỳ để kiểm tra api có trả về 401 không, nếu có thì thực hiện refresh token và life cycle thực thi lại hàm componentDidUpdate
            // để gán lại grahpql header Authorization token mới
            http.get("/api/lookup/roles").subscribe(() => {
            });
        });

        const ret = wsClient.request({
            query: subcriptionHoiThoai,
            variables: {
                id_taikhoan: this.props.ID_TAIKHOAN,
            },
        });
        this.subscriptions["wsClient"] = ret.subscribe({
            next: async (data) => {
                await this.replicationState.run();
            },
            error(error) {
            },
        });

        this.subscriptions["list_hoithoai"] = db[this.hoithoaiCollection]
            .find().sort({ 'NGAYCHINHSUA_UTC': 0 }).$.subscribe((response) => {
                if (!response) {
                    return;
                }
                //console.log('list_hoithoai ');
                //console.dir(response);
                let listitem = JSON.parse(JSON.stringify(response));
                listitem = listitem.map((item, index) => {
                    item.TIEUDE =
                        item.DS_THANHVIEN.find(
                            (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                        ) != null
                            ? item.DS_THANHVIEN.find(
                                (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                            ).TENDAYDU
                            : item.TIEUDE;
                    item.ANHDAIDIEN =
                        item.DS_THANHVIEN.find(
                            (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                        ) != null &&
                            item.DS_THANHVIEN.find(
                                (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                            ).ANHDAIDIEN != null
                            ? API_URL +
                            item.DS_THANHVIEN.find(
                                (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                            ).ANHDAIDIEN
                            : imgDefault;
                    item.SL_TINNHAN_CHUADOC = item.DS_THANHVIEN.find(x => x.ID_TAIKHOAN == this.props.ID_TAIKHOAN).SL_TINNHAN_CHUADOC
                    return item;
                });
                this.setState({
                    list_hoithoai: listitem,
                    loading: false,
                });
            });
    }
    async clickHoiThoai(ID_HOITHOAI) {
        //1. thực hiện cập nhật lần đọc cuối cùng cho hội thoại đó
        const db = await DatabaseChat.get();
        db[this.hoithoaiCollection]
            .findOne(ID_HOITHOAI)
            .exec()
            .then(async (doc) => {
                if (doc) {
                    const changeFunction = (oldData) => {
                        for (var i = 0; i < oldData.DS_THANHVIEN.length; i++) {
                            if (oldData.DS_THANHVIEN[i].ID_TAIKHOAN === this.props.ID_TAIKHOAN) {
                                oldData.DS_THANHVIEN[i].LANDOC_CUOICUNG_UTC = new Date().toISOString();
                                oldData.DS_THANHVIEN[i].SL_TINNHAN_CHUADOC = 0;
                            }
                        }
                        return oldData;
                    }
                    await doc.atomicUpdate(changeFunction);
                }
            });
        this.setState({
            selected_ID_HOITHOAI: ID_HOITHOAI,
        });
    }
    async DanhDauDaDoc(ID_HOITHOAI) {
        //1. thực hiện cập nhật lần đọc cuối cùng cho hội thoại đó
        const db = await DatabaseChat.get();
        db[this.hoithoaiCollection]
            .findOne(ID_HOITHOAI)
            .exec()
            .then(async (doc) => {
                if (doc) {
                    const changeFunction = (oldData) => {
                        for (var i = 0; i < oldData.DS_THANHVIEN.length; i++) {
                            if (oldData.DS_THANHVIEN[i].ID_TAIKHOAN === this.props.ID_TAIKHOAN) {
                                oldData.DS_THANHVIEN[i].LANDOC_CUOICUNG_UTC = new Date().toISOString();
                                oldData.DS_THANHVIEN[i].SL_TINNHAN_CHUADOC = 0;
                            }
                        }
                        return oldData;
                    }
                    await doc.atomicUpdate(changeFunction);
                }
            });
    }
    async onClickReload() {
        const db = await DatabaseChat.get();
        await db.remove();
        window.location.reload();
    }
    async componentDidMount() {
        await this.RunReplication();
        let username = this.props.match.params.username;
        if (username != null && username != "" && username != this.oauth.username) {
            await this.actSendMessage(username)
        }
        this.featDataKN(this.state.meta);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        //kiểm tra chỉ chạy lại sau khi access token thay đổi
        if (prevProps.access_token != this.props.access_token) {
            this.RunReplication();
        }
    }
    async componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
        if (this.replicationState) {
            await this.replicationState.cancel();
        }
    }

    onShowBoxAddMessModal = (e) => {
        // e.preventDefault();
        this.setState({ showBoxAddMessModal: true });
    };
    onCloseBoxAddMessModal = (e) => {
        // e.preventDefault();
        this.setState({ showBoxAddMessModal: false });
    };
    //Lấy danh sách tài khoản kết nối
    featDataKN(meta) {
        let { dataKN } = this.state;
        _hoithoaiService.getDanhSachKetNoi(meta).subscribe(res => {
            dataKN = meta.page == 1 ? res.data : dataKN.concat(res.data);
            meta.total = res.totalRows;
            this.setState({
                dataKN,
            });
        }, err => {
            console.log(err);
        });
    }
    async actSendMessage(userName) {
        const db = await DatabaseChat.get();
        db[this.hoithoaiCollection].find({
            selector: {
                'DS_THANHVIEN': {
                    '$elemMatch': {
                        'TENDANGNHAP': { '$eq': userName }
                    }
                }
            }
        }).sort({ 'NGAYCHINHSUA_UTC': 0 }).$.subscribe(response => {
            //Nếu đã tồn tại hội thoại selected hội thoại
            if (response.length > 0) {
                this.setState({
                    showBoxAddMessModal: false,
                    selected_ID_HOITHOAI: response[0].ID
                });
            }
            //ngược lại sẽ tạo hội thoại mới và selected
            else {
                fetch(API_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + this.props.access_token
                    },
                    body: JSON.stringify(
                        pushCreatHoiThoai({ userName: userName })
                    )
                }).then(r => r.json()).then(data => {
                    //Đẩy data vào rxdb
                    if (data.data.createHoiThoai != null) {
                        db[this.hoithoaiCollection].insert(data.data.createHoiThoai);
                        db[this.hoithoaiCollection]
                            .find().sort({ 'NGAYCHINHSUA_UTC': 0 }).$.subscribe((response) => {
                                if (!response) {
                                    return;
                                }
                                //console.log('list_hoithoai ');
                                //console.dir(response);
                                let listitem = JSON.parse(JSON.stringify(response));
                                listitem = listitem.map((item, index) => {
                                    item.TIEUDE =
                                        item.DS_THANHVIEN.find(
                                            (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                                        ) != null
                                            ? item.DS_THANHVIEN.find(
                                                (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                                            ).TENDAYDU
                                            : item.TIEUDE;
                                    item.ANHDAIDIEN =
                                        item.DS_THANHVIEN.find(
                                            (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                                        ) != null &&
                                            item.DS_THANHVIEN.find(
                                                (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                                            ).ANHDAIDIEN != null
                                            ? API_URL +
                                            item.DS_THANHVIEN.find(
                                                (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                                            ).ANHDAIDIEN
                                            : imgDefault;
                                    item.SL_TINNHAN_CHUADOC = item.DS_THANHVIEN.find(x => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN).SL_TINNHAN_CHUADOC
                                    return item;
                                });
                                this.setState({
                                    list_hoithoai: listitem,
                                    loading: false,
                                    selected_ID_HOITHOAI: data.data.createHoiThoai.ID,
                                    showBoxAddMessModal: false
                                });
                            });
                    }
                });
            }
        });
    }
    viewMore() {
        let { meta } = this.state;
        meta.page_size = meta.page_size + 1;
        this.setState({ meta });
        this.featDataKN(meta);
    }
    handleChangeKeyword = (e) => {
        let { meta } = this.state;
        var target = e.target;
        var value = target.value;
        meta.keyword = value;
        this.setState({
            meta
        });
        this.featDataKN(meta);
    }
    handleChangeKeywordHoiThoai = (e) => {
        var target = e.target;
        var value = target.value;
        this.searchHoiThoai(value.toLowerCase());
    }
    async searchHoiThoai(value) {
        const db = await DatabaseChat.get();
        db[this.hoithoaiCollection].find().sort({ 'NGAYCHINHSUA_UTC': 0 }).$.subscribe((response) => {
            if (!response) {
                return;
            }
            let listitem = JSON.parse(JSON.stringify(response));
            listitem = listitem.map((item, index) => {
                item.TIEUDE =
                    item.DS_THANHVIEN.find(
                        (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                    ) != null
                        ? item.DS_THANHVIEN.find(
                            (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                        ).TENDAYDU
                        : item.TIEUDE;
                item.ANHDAIDIEN =
                    item.DS_THANHVIEN.find(
                        (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                    ) != null &&
                        item.DS_THANHVIEN.find(
                            (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                        ).ANHDAIDIEN != null
                        ? API_URL +
                        item.DS_THANHVIEN.find(
                            (x) => x.ID_TAIKHOAN != this.props.ID_TAIKHOAN
                        ).ANHDAIDIEN
                        : imgDefault;
                item.SL_TINNHAN_CHUADOC = item.DS_THANHVIEN.find(x => x.ID_TAIKHOAN == this.props.ID_TAIKHOAN).SL_TINNHAN_CHUADOC
                return item;
            });
            listitem = listitem.filter(item => item.TIEUDE.toLowerCase().includes(value));
            this.setState({
                list_hoithoai: listitem,
                loading: false,
            });
        });
    }
    render() {
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                className="color-blue font-Bold"
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
                <Icon path={mdiMenuDownOutline} size={0.5} color={""} />
            </a>
        ));
        return (
            <div id="root">
                <div className="App">
                    <div className="main_container">
                        <div className="LeftCol d-sm-block d-none">
                            <a className="brand d-block text-center" href="/" >
                                <img src={logoHeader} alt="logo_header" />
                            </a>
                            <div className="d-flex flex-column justify-content-between left-icon">
                                <div className="LeftColItem d-block text-center">
                                    <div className="Items active">
                                        <a className="LeftNavLink" href="/chat"><svg viewBox="0 0 24 24" role="presentation" style={{ width: '2.25rem', height: '2.25rem' }}>
                                            <path d="M16,20H20V16H16M16,14H20V10H16M10,8H14V4H10M16,8H20V4H16M10,14H14V10H10M4,14H8V10H4M4,20H8V16H4M10,20H14V16H10M4,8H8V4H4V8Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                                        </svg>
                                            <div className="Item font-12">Tin Nhắn</div>
                                        </a></div>
                                    {/*<div className="Items"><a className="LeftNavLink" href="http://localhost:3000/thanh-vien"><svg viewBox="0 0 24 24" role="presentation" style={{ width: '2.25rem', height: '2.25rem' }}>
                                                <path d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                                            </svg>
                                                <div className="Item font-12">Thành viên</div>
                                            </a></div>*/}
                                </div>
                                <div className="text-center"><svg viewBox="0 0 24 24" role="presentation" style={{ width: '2.25rem', height: '2.25rem' }}>
                                    <path d="M16,12A2,2 0 0,1 18,10A2,2 0 0,1 20,12A2,2 0 0,1 18,14A2,2 0 0,1 16,12M10,12A2,2 0 0,1 12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12M4,12A2,2 0 0,1 6,10A2,2 0 0,1 8,12A2,2 0 0,1 6,14A2,2 0 0,1 4,12Z" style={{ fill: 'rgb(255, 255, 255)' }} />
                                </svg></div>
                            </div>
                        </div>
                        <div className="TopNav">
                            <div className="pull-left TopNav--Items d-flex align-items-center h-100">
                                <div className="search m-auto text-center ml-2">
                                    <a className=" d-block text-cente d-md-none" href="http://localhost:3000/" >
                                        <img className="W-100" style={{ height: 55 }} src={logoHeader} alt="logo_header" />
                                    </a>
                                    <p className="text-uppercase m-0 font-Bold color-blue font-18 d-md-block d-none">HỆ THỐNG QUẢN LÝ VỀ HÀNG GIẢ, HÀNG NHÁI, BUÔN LẬU, GIAN LẬN THƯƠNG MẠI VÀ VI PHẠM SỞ HỮU TRÍ TUỆ</p>
                                </div>
                                <div className="d-flex align-items-center mr-2 box-user-right">
                                    <div className="mr-2" style={{ width: 45, height: 45, borderRadius: 50, border: "2px solid #bcbcbc82", overflow: "hidden" }}>
                                        <img className="w-100" src={this.oauth.anhdaidien != "" ? API_URL + this.oauth.anhdaidien : imgDefault} alt="logo_header" />
                                    </div>
                                    <div>
                                        <Dropdown>
                                            <Dropdown.Toggle as={CustomToggle}>
                                                {this.oauth.fullName}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="/bai-dang">Trang cá nhân</Dropdown.Item>
                                                <Dropdown.Item href={'/profile/' + this.oauth.username}>Thông tin cá nhân</Dropdown.Item>
                                                <Dropdown.Item href="/dang-xuat">Đăng xuất</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="MainContent">
                            <div className="container-fluid m-0 p-0">
                                <div className="row p-0 m-0">
                                    <div className="col-sm-3 col-2 p-0">
                                        <div className="MainLeft">
                                            <div className="ListProjectGroup">
                                                <div className="ListProjectGroup__header">
                                                    <span className="d-md-block d-none">
                                                        Thêm cuộc hội thoại mới
                </span>
                                                    <a
                                                        onClick={() => this.onShowBoxAddMessModal()}
                                                        className="none-decoration pull-right"
                                                    >
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            role="presentation"
                                                            style={{ width: "1.8rem", height: "1.8rem" }}
                                                        >
                                                            <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div className="box-form-search d-md-block d-none">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        onChange={this.handleChangeKeywordHoiThoai}
                                                        placeholder="Tìm kiếm người dùng,..."
                                                    />
                                                </div>
                                                <ul
                                                    className="p-0 m-0 css-lst-hoithoai"
                                                    style={{ padding: "0em 0em 1em", cursor: "inherit" }}
                                                >
                                                    {this.state.list_hoithoai.map((item, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                aria-roledescription="You are currently at a draggable item at position 1. Press space bar to lift."
                                                                className={this.state.selected_ID_HOITHOAI == item.ID ? 'department-icon not-outline-focus d-flex align-items-center item-hoi-thoai-selected' : 'department-icon not-outline-focus d-flex align-items-center'}
                                                                style={{
                                                                    userSelect: "none",
                                                                    touchAction: "none",
                                                                    boxSizing: "border-box",
                                                                    position: "relative",
                                                                    listStyleType: "none",
                                                                    cursor: "inherit",
                                                                    //   backgroundColor: "rgb(255, 255, 255)",
                                                                    padding: "8px 15px",
                                                                }}
                                                            >
                                                                <div
                                                                    className="itemUserBoxMessage w-100"
                                                                    style={{ display: "flex", alignItems: "center" }}
                                                                >
                                                                    <div
                                                                        className="main-content d-flex w-100"
                                                                        onClick={() => this.clickHoiThoai(item.ID)}
                                                                    >
                                                                        <img
                                                                            alt="dep-ic"
                                                                            className="dep-ic"
                                                                            src={item.ANHDAIDIEN}
                                                                            width={45}
                                                                            height={45}
                                                                        />
                                                                        <div className="ml-3 w-100 d-sm-block d-none">
                                                                            <div className="d-flex justify-content-between align-items-center title-name-user font-Bold font-14">
                                                                                {item.TIEUDE}{" "}
                                                                                <small className="d-none">0</small>
                                                                            </div>
                                                                            <div className="content-boxChat">
                                                                                <p
                                                                                    className="m-0 font-14"
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html: item.TINNHAN_CUOICUNG
                                                                                            ? item.TINNHAN_CUOICUNG.NOIDUNG
                                                                                            : "",
                                                                                    }}
                                                                                ></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="btn-DropDown d-flex flex-column justify-content-between align-items-center">
                                                                        <div className={(item.SL_TINNHAN_CHUADOC === 0) ? "d-none" : "box-number-mess-waiting d-flex justify-content-center align-items-center"} style={{ width: 25, height: 25, backgroundColor: "rgb(255, 86, 48)", borderRadius: 50, border: "1px solid #fff" }}>
                                                                            <p className="text-white font-12 font-Bold text-center m-0">{(item.SL_TINNHAN_CHUADOC > 100) ? "99+" : item.SL_TINNHAN_CHUADOC}</p>
                                                                        </div>
                                                                        <Dropdown>
                                                                            <Dropdown.Toggle
                                                                                variant=""
                                                                                className="dropdown-basic"
                                                                                style={{
                                                                                    visibility: "visible",
                                                                                    marginLeft: 0,
                                                                                    marginRight: 5,
                                                                                }}
                                                                            >
                                                                                ...
                                                        </Dropdown.Toggle>

                                                                            <Dropdown.Menu>
                                                                                <Dropdown.Item onClick={() => this.DanhDauDaDoc(item.ID)}>
                                                                                    Đánh dấu đã đọc
                                                           </Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                                <div
                                                    aria-live="assertive"
                                                    role="log"
                                                    aria-atomic="true"
                                                    style={{
                                                        position: "absolute",
                                                        width: "1px",
                                                        height: "1px",
                                                        margin: "-1px",
                                                        border: "0px",
                                                        padding: "0px",
                                                        overflow: "hidden",
                                                        clipPath: "inset(100%)",
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                    <Boxmessages
                                        ID_HOITHOAI={this.state.selected_ID_HOITHOAI}
                                        onScrollBottom={true}
                                    ></Boxmessages>
                                    {/* Modal Add  Department */}
                                    <Modal
                                        show={this.state.showBoxAddMessModal}
                                        onHide={this.onCloseBoxAddMessModal}
                                    >
                                        <Modal.Header closeButton>
                                            <span>Thêm cuộc hội thoại</span>
                                        </Modal.Header>
                                        <Modal.Body className="modal-body-ketnoi">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control input-modal"
                                                    onChange={this.handleChangeKeyword}
                                                    name="keyword" value={this.state.meta.keyword}
                                                    placeholder="Tên người dùng"
                                                />

                                            </div>
                                            {this.state.dataKN.map((val, index) => {
                                                return (
                                                    <div className="ava-user d-flex" key={index}>
                                                        {(this.props.ID_TAIKHOAN != val.iD_NGUOIGUI) ?
                                                            <React.Fragment>
                                                                <img className="rounded-circle mr-3" width="45px" height="45px" src={(val.nguoigui && val.nguoigui.anhDaiDien) ? `${API_URL}${val.nguoigui.anhDaiDien}` : `${imgDefault}`} alt="" />
                                                                <div>
                                                                    <b className="title">{val.nguoigui && val.nguoigui.title}</b>
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => this.actSendMessage(val.nguoigui.tendangnhap)}>Gửi tin nhắn</button>
                                                                </div>
                                                            </React.Fragment> :
                                                            <React.Fragment>
                                                                <img className="rounded-circle mr-3" width="45px" height="45px" src={(val.nguoinhan && val.nguoinhan.anhDaiDien) ? `${API_URL}${val.nguoinhan.anhDaiDien}` : `${imgDefault}`} alt="" />
                                                                <div>
                                                                    <b className="title">{val.nguoinhan && val.nguoinhan.title}</b>
                                                                </div>
                                                                <div className="ml-auto">
                                                                    <button type="button" className="btn btn-sm btn-primary" onClick={() => this.actSendMessage(val.nguoinhan.tendangnhap)}>Gửi tin nhắn</button>
                                                                </div>
                                                            </React.Fragment>}

                                                    </div>
                                                );
                                            })
                                            }
                                            {
                                                (this.state.meta.page * this.state.meta.page_size >= this.state.meta.total) ? '' :
                                                    <div className="text-center pt-2">
                                                        <button onClick={() => this.viewMore()} className="btn btn-light btn-block">Xem thêm</button>
                                                    </div>
                                            }
                                        </Modal.Body>
                                        <Modal.Footer>
                                        </Modal.Footer>
                                    </Modal>
                                    {/* Modal Add  Icon */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        access_token: state.oauth.access_token,
        ID_TAIKHOAN: state.oauth.idTaiKhoan,
        user_onlines: state.oauth.user_onlines,
    };
};
const HoithoaiComponent = connect(mapStateToProps)(HoithoaiComponents);
export { HoithoaiComponent };
