import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Image } from 'react-bootstrap';
import { string, object } from 'yup';
import { Card, Form, InputGroup, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { LoadingComponent, Page } from 'shared/components';
import { authService } from 'shared/services';
import { OauthAction } from 'redux/actions';
import logoNavBar from 'assets/images/logo-new.png'
import '@iconify/iconify';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/styles.scss';
import { CAPTCHA_KEY } from 'app-setting';
import ReCAPTCHA from "react-google-recaptcha";
import { _QuenMatKhauService, Context } from './QuenMatKhauService';
const schema = object({
    username: string().trim().required('Cần phải nhập tên tài khoản'),
});

class QuenMatKhauComponent extends React.Component {
    static contextType = Context;
    constructor(props) {
        super(props);
        this.state = {
            data: {
                username: '',
            },
            loading: false,
            errorMessage: '',
            expired: false,
        };
    }

    componentDidMount() {

    }

    onSubmit(obj) {
        if (this.state.expired) {
            this.setState({ loading: true });
            _QuenMatKhauService.sendtkquenmatkhau(obj).subscribe(() => {
                this.setState({
                    loading: false
                });
                this.thongbao("Yêu cầu đã được gửi đến hệ thống. Vui lòng vào email đăng ký để xác nhận (email có hiệu lực trong 1 tiếng)")
            },
                (err) => {
                    this.setState({
                        loading: false
                    });
                    this.context.beh.alert(err.error);
                });

        }

    }
    async thongbao(str) {
        if (await this.context.beh.alert(str)) {
            //window.open(window.location.origin, '_blank');
            this.props.history.push({ pathname: '/' });
        }
    }
    onKeyPress(ev) {
        const keyCode = ev.keyCode || ev.which;
        if (keyCode === 13) {
            ev.preventDefault();
            return false;
        }
    }
    handleChange = value => {
        this.setState({ expired: "true" });
    };
    render() {
        return (
            <Page>
                <Page.Content>
                    <div className="d-flex justify-content-center align-items-center h-100 login-page-public">
                        <div className="login-box" style={{ width: 400 }}>
                            {/* /.login-logo */}
                            <Card style={{ marginTop: '50px', marginBottom: '50px' }} >
                                <Card.Body>
                                    
                                    <h5 className="text-center text-dark mt-3" style={{ fontSize: 18 }}>Quên mật khẩu</h5>
                                    <p className="login-box-msg">
                                        <i className="text-danger">{this.state.errorMessage}</i>
                                    </p>

                                    <Formik
                                        validationSchema={schema}
                                        onSubmit={(values) => {

                                            this.onSubmit(values);
                                        }}
                                        initialValues={this.state.data}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            handleBlur,
                                            values,
                                            touched,
                                            errors,
                                        }) => (
                                                <Form noValidate onSubmit={handleSubmit} onKeyPress={this.onKeyPress.bind(this)}>
                                                    <Form.Group as={Col} md="12" controlId="mota" style={{ marginBottom: '0px' }}>
                                                        <Form.Label>Nhập tài khoản</Form.Label> <Form.Label className="text-danger">(*)</Form.Label>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control
                                                                name="username"
                                                                placeholder="Tài khoản"
                                                                aria-label="Username"
                                                                autoComplete="on"
                                                                autoFocus
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.username}
                                                                isInvalid={touched.username && !!errors.username}
                                                            />
                                                            <InputGroup.Append>
                                                                <InputGroup.Text>
                                                                    <span className="iconify" data-icon="fa-solid:user" data-inline="false" />
                                                                </InputGroup.Text>
                                                            </InputGroup.Append>
                                                            <Form.Control.Feedback type="invalid">
                                                                {errors.username}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Form.Group>

                                                    <Row>
                                                        <Col className="col-12 ">
                                                            <ReCAPTCHA
                                                                className="text-center m-auto"
                                                                style={{ display: "inline-block" }}
                                                                ref={this._reCaptchaRef}
                                                                sitekey={CAPTCHA_KEY}
                                                                onChange={this.handleChange}
                                                                asyncScriptOnLoad={this.asyncScriptOnLoad}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col className="col-12">
                                                            <button type="submit" className="btn btn-primary btn-block">Xác nhận</button>
                                                        </Col>
                                                    </Row>

                                                </Form>
                                            )}
                                    </Formik>
                                    {/* /.login-card-body */}
                                </Card.Body>
                            </Card>
                        </div>
                        <LoadingComponent loading={this.state.loading} />

                    </div>
                </Page.Content>
            </Page>

        );
    }
}


export { QuenMatKhauComponent };
