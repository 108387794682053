import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { API_URL } from 'app-setting';
import { NavLink } from 'react-router-dom';

class HoiDap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataAskAndAnswers: []
        }
    }

    componentDidMount() {
        this.getDataAskAndAnswers();
    }

    getDataAskAndAnswers() {
        fetch(`${API_URL}api/chcauhoi/lists?trangthai=1`)
            .then(res => res.json())
            .then((data) => {

                this.setState({ dataAskAndAnswers: data.data.slice(0, 3) })
            })
            
            .catch(console.log)
    }

    renderAskAndAnswers = (numShowInRow) => {
        var l = this.state.dataAskAndAnswers.length;
        var d = Math.ceil(l / numShowInRow);

        var tmpOld = this.state.dataAskAndAnswers;

        return <>{Array.from(Array(d), (e, i) => {
            var tmp = tmpOld.slice(0, numShowInRow);
            var numRows = tmp.length - 1;
            tmpOld = tmpOld.slice(numShowInRow);

            return <div className="container" key={i}>
                {
                    tmp.map((item, row) => {
                        return (
                            <React.Fragment key={row}>
                                <div className="itemBoxSildeHoiDap">
                                    <p className="font-Light font-14 color-black">{item.noidung}</p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex">
                                            <i className="fa fa-pencil color-gray mr-2" aria-hidden="true" />
                                            <a href="#" className="font-12 font-Regular color-gray">{item.titlE_NGUOIHOI}</a>
                                        </div>
                                        <div className="d-flex">
                                            <i className="fa fa-commenting-o color-gray mr-2" aria-hidden="true" />
                                            <a href="#" className="font-12 font-Regular color-gray">{item.countcautraloi} lượt trả lời</a>
                                        </div>
                                    </div>
                                    <NavLink to={`#`} className="font-12 font-Regular color-blue text-link float-right">
                                        Xem thêm
                                     </NavLink>
                                </div>
                                {(row !== numRows) && <div className="mt-2 mb-2"> <hr /></div>}
                            </React.Fragment>
                        )
                    })
                }
            </div>
        })}</>
    }

    chuyenDoiURL = (str) => {
        // Chuyển hết sang chữ thường
        str = str.toLowerCase();

        // xóa dấu
        str = str.replace(/(à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ)/g, 'a');
        str = str.replace(/(è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ)/g, 'e');
        str = str.replace(/(ì|í|ị|ỉ|ĩ)/g, 'i');
        str = str.replace(/(ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ)/g, 'o');
        str = str.replace(/(ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ)/g, 'u');
        str = str.replace(/(ỳ|ý|ỵ|ỷ|ỹ)/g, 'y');
        str = str.replace(/(đ)/g, 'd');

        // Xóa ký tự đặc biệt
        str = str.replace(/([^0-9a-z-\s])/g, '');

        // Xóa khoảng trắng thay bằng ký tự -
        str = str.replace(/(\s+)/g, '-');

        // xóa phần dự - ở đầu
        str = str.replace(/^-+/g, '');

        // xóa phần dư - ở cuối
        str = str.replace(/-+$/g, '');

        // return
        return str;
    }
    render() {
        return (
            <div className="box-ket-noi">

                <div className="box-title_main_gray d-flex justify-content-between align-items-center">
                    <NavLink to={`/hoi-dap`} className="m-0 color-blue font-14 text-uppercase font-Bold">
                        Hỏi &amp; đáp
                    </NavLink>
                    <i className="fa fa-question color-blue" aria-hidden="true"></i>
                </div>
                {this.state.dataAskAndAnswers.map((item, i) => {
                    return (
                        <div key={i} className="itemBoxSildeHoiDap container">
                            <p className="font-Light font-14 color-black">{item.noidung}</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex">
                                    <i className="fa fa-pencil color-gray mr-2" aria-hidden="true" />
                                    <NavLink to={`#`} className="font-12 font-Regular color-gray">
                                        {item.titlE_NGUOIHOI}
                                    </NavLink>

                                </div>
                                <div className="d-flex font-12 font-Regular color-gray">
                                    {item.countcautraloi} lượt trả lời
                                </div>
                            </div>
                            <NavLink to={`/hoi-dap/chi-tiet/${this.chuyenDoiURL(item.tieude)}.${item.id}.html`} className="font-12 font-Regular color-blue text-link float-right">
                                Xem thêm
                                </NavLink>
                            <div className="mt-2 mb-2"> <hr /></div>
                        </div>
                    )
                })}

            </div>
        );
    }
}

export default HoiDap;