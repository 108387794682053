import * as Constant from './cauhinhdulieulienketConstant';

const cauhinhdulieulienket = {
    cauhinhdulieulienketList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'asc'},
        search: ''        
    }
};
const cauhinhdulieulienketReducer = (state = cauhinhdulieulienket, action) => {
    switch (action.type) {
        case Constant.CAUHINHDULIEULIENKET_SET_LIST_DATA:
            return Object.assign({}, state, { cauhinhdulieulienketList: action.cauhinhdulieulienketList });
        case Constant.CAUHINHDULIEULIENKET_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export {cauhinhdulieulienketReducer};