import {  DM_DON_VI_SET_LIST_DATA,  DM_DON_VI_SET_LIST_META } from './DMDonViConstant';

const dmdonvi = {
    DMDonViList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '',
        filter: {
            unitId: '',
            unitTraversalOption: '0',
            _: new Date().getTime()
            
        },
    }
};
const DMDonViReducer = (state = dmdonvi, action) => {
    switch (action.type) {
    case  DM_DON_VI_SET_LIST_DATA:
        return Object.assign({}, state, { DMDonViList: action.DMDonViList });
    case  DM_DON_VI_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {DMDonViReducer};