import * as Constant from './cauhoikhaosatConstant';

const cauhoikhaosat = {
    cauhoikhaosatList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: {id:'asc'},
        search: '',
        total: 1,
        filter: {
            ID_PHIEUKHAOSAT: 0

        },
    }
};
const cauhoikhaosatReducer = (state = cauhoikhaosat, action) => {
    switch (action.type) {
        case Constant.CAUHOIKHAOSAT_SET_LIST_DATA:
            return Object.assign({}, state, { cauhoikhaosatList: action.cauhoikhaosatList });
        case Constant.CAUHOIKHAOSAT_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { cauhoikhaosatReducer};