import { DM_KHOANG_GIA_SET_LIST_DATA, DM_KHOANG_GIA_SET_LIST_META } from './DMKhoangGiaConstant';

const dmkhoanggia = {
    DMKhoangGiaList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '',
        filter: {
            unitId: '',
            unitTraversalOption: '0',
            _: new Date().getTime()
            
        },
    }
};
const DMKhoangGiaReducer = (state = dmkhoanggia, action) => {
    switch (action.type) {
        case DM_KHOANG_GIA_SET_LIST_DATA:
        return Object.assign({}, state, { DMKhoangGiaList: action.DMKhoangGiaList });
        case DM_KHOANG_GIA_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {DMKhoangGiaReducer};