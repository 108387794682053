import * as Constant from './taikhoanConstant';

const taikhoan = {
    taikhoanList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id: 'asc' },
        search: '',
        filter: {
            id_tinhthanh: 0,
            trangthai: 0,
            tungay: '',
            denngay: '',
            manhom:"05",// mặc định lấy loại tài khoản quản lý thị trường
        }    
    }
};
const taikhoanReducer = (state = taikhoan, action) => {
    switch (action.type) {
        case Constant.TAIKHOAN_SET_LIST_DATA:
            return Object.assign({}, state, { taikhoanList: action.taikhoanList });     
        case Constant.TAIKHOAN_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { taikhoanReducer};