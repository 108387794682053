import * as Constant from './dmphuongxaConstant';

const dmphuongxa = {
    dmphuongxaList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '',
        filter: {
            idPhuongXa: 0,            
        },
    }
};
const dmphuongxaReducer = (state = dmphuongxa, action) => {
    switch (action.type) {
    case Constant.DMPHUONGXA_SET_LIST_DATA:
        return Object.assign({}, state, { dmphuongxaList: action.dmphuongxaList });
    case Constant.DMPHUONGXA_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {dmphuongxaReducer};