import { BaseService } from 'shared/services';
import { API_URL } from 'app-setting';
import { http } from 'shared/utils';
import { createContext } from 'react';
import { Subject } from 'rxjs';
class TruyCapService extends BaseService {
    
    LuotTruyCap() {
        return http.get(`${API_URL}api/tkluottruycap/gettruycap`);
    }
    TangLuotTruyCap() {
        return http.put(`${API_URL}api/tkluottruycap/tangluottruycap`);
    }
}
const truycapService = new TruyCapService();
const Context = createContext();
export { Context, truycapService, TruyCapService };