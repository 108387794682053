import { createRxDatabase, addRxPlugin } from 'rxdb';
import { SOCKET_URL } from 'app-setting';
import {
    RxDBReplicationGraphQLPlugin,

} from 'rxdb/plugins/replication-graphql';
import { tinNhanSchema } from '../chat/tinNhanSchema'
import {
    SubscriptionClient
} from 'subscriptions-transport-ws';
import { hoiThoaiSchema } from './hoiThoaiSchema';
addRxPlugin(require('pouchdb-adapter-idb'));
addRxPlugin(RxDBReplicationGraphQLPlugin);
const batchSize = 20
const pullQueryBuilder = (data)=>{
    return (doc) => {;
        if (!doc) {
            // the first pull does not have a start-document
            doc = {
                ID: '',
                NGAYTAO_UTC: '',
                ID_HOITHOAI: data.ID_HOITHOAI,
            };
        }
        
        const query = `{
            feedForRxDBReplicationMessage(lastId: "${doc.ID}" ,min_updated_at: "${doc.NGAYTAO_UTC}", limit: 20, id_hoithoai: "${doc.ID_HOITHOAI}",paging:${data.Paging}) {
                ID
                NOIDUNG
                NGAYTAO_UTC
                NGAYCHINHSUA_UTC
                DELETED
                ID_HOITHOAI
                ID_NGUOIGUI
            }
        }`;
        
        return {
            query,
           
            variables: {}
        };
    };
} 
const pushQueryBuilderCustom = (doc) => {
    
    const query = `
    mutation SetMessage($message: TNTINNHANDetailDtoInput!){
        setMessage(message:$message){
          ID
          NOIDUNG          
          DELETED
        }
      }
    `;
    const variables = {
        message: doc
    };
    return {
        query,
        variables
    };
};
const endpointUrl = 'wss://' + SOCKET_URL;
const wsClient = new SubscriptionClient(endpointUrl, {
    reconnect: true,
    timeout: 1000 * 60,
    onConnect: () => {
        console.log('SubscriptionClient.onConnect()');
    },
    connectionCallback: () => {
        console.log('SubscriptionClient.connectionCallback:');
    },
    reconnectionAttempts: 10000,
    inactivityTimeout: 10 * 1000,
    lazy: true
});

const subcriptionTinNhan = `
    subscription onListenMessage($id_hoithoai: Uuid!){   
    onListenMessage(id_hoithoai: $id_hoithoai)
    {
      NOIDUNG
    } 
  }
  
  `

let dbPromise = null;
const queryHistory = (data) => {
    return `{
            feedForRxDBReplicationMessage(lastId: "${data.lastID}" ,min_updated_at: "${data.NgayTao}", limit: 20, id_hoithoai: "${data.ID_HOITHOAI}",paging:${data.Paging}) {
                ID
                NOIDUNG
                NGAYTAO_UTC
                NGAYCHINHSUA_UTC
                DELETED
                ID_HOITHOAI
                ID_NGUOIGUI
            }
        }`;
}
const _create = async () => {
    
    const db = await createRxDatabase({
        name: 'chatdb',           // <- name
        adapter: 'idb',          // <- storage-adapter
        password: 'myPassword',     // <- password (optional)
        //multiInstance: true,         // <- multiInstance (optional, default: true)
        //eventReduce: false // <- eventReduce (optional, default: true)
     
        
      });

    //const collection = await db.collection({
    //    name: 'tinnhan',
    //    schema: tinNhanSchema
    //});
    //const collection2 = await db.collection({
    //    name: 'hoithoai',
    //    schema: hoiThoaiSchema
    //});
    
    
    return db
}

export const get = () => {
    if (!dbPromise)
        dbPromise = _create();
    return dbPromise;
}
    export { pullQueryBuilder, pushQueryBuilderCustom, wsClient, subcriptionTinNhan, queryHistory }