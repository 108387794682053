import * as actions from './hosovuviecConstants';
const hosovuviec = {
    hosovuviecList: [],
    dataHVVP: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { title: 'asc' },
        search: '',
        filter: {
            iddonvi: 0,
            status: 0,
            _: new Date().getTime()

        },
    }
};
const hosovuviecReducer = (state = hosovuviec, action) => {
    switch (action.type) {
    case actions.HOSOVUVIEC_SET_LIST_DATA:
        return Object.assign({}, state, { hosovuviecList: action.hosovuviecList });
    case actions.HANHVIVIPHAM_SET_LIST_DATA:
        return Object.assign({}, state, { dataHVVP: action.dataHVVP });
    case actions.HOSOVUVIEC_SET_LIST_META:
        return Object.assign(
            {},
            state,
            {
                meta: Object.assign(
                    {},
                    state.meta,
                    action.meta
                )
            });
    default:
        return state;
    }
};
export { hosovuviecReducer };