import { createRxDatabase, addRxPlugin } from 'rxdb';
import {
    RxDBReplicationGraphQLPlugin,

} from 'rxdb/plugins/replication-graphql';
import { RxDBEncryptionPlugin } from 'rxdb/plugins/encryption';
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder';
import { RxDBUpdatePlugin } from 'rxdb/plugins/update';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import {
    tinNhanSchema,
    graphQLGenerationTinNhanInput
} from '../chat/tinNhanSchema'
import {
    SubscriptionClient
} from 'subscriptions-transport-ws';
addRxPlugin(require('pouchdb-adapter-idb'));
addRxPlugin(RxDBReplicationGraphQLPlugin);
addRxPlugin(RxDBEncryptionPlugin);
addRxPlugin(RxDBQueryBuilderPlugin);
addRxPlugin(RxDBUpdatePlugin);
addRxPlugin(RxDBValidatePlugin);
const batchSize = 5
const pullQueryBuilderHoiThoai = (doc) => {
    if (!doc) {
        // the first pull does not have a start-document
        doc = {
            ID: '',
            NGAYCHINHSUA_UTC: "1970-06-10T00:00:00",

        };
    }

    const query = `{
            feedForRxDBReplicationHoiThoai(lastId: "${doc.ID}" ,min_updated_at: "${doc.NGAYCHINHSUA_UTC}", limit: 5) {
                ID
                NGAYCHINHSUA_UTC
                NGAYTAO_UTC
                TIEUDE
                DELETED
                LA_NHOM
                ID_NGUOITAO
                TINNHAN_CUOICUNG{
                    NOIDUNG
                    NGAYTAO_UTC
                    NGAYCHINHSUA_UTC
                    DELETED
                    ID_NGUOIGUI
                }
                DS_THANHVIEN{
                    ID_TAIKHOAN
                    TENDANGNHAP
                    LANDOC_CUOICUNG_UTC
                    SL_TINNHAN_CHUADOC,
                    ANHDAIDIEN,
                    TENDAYDU
                }
            }
        }`;

    return {
        query,

        variables: {}
    };
}

const pushQueryBuilderHoiThoai = (doc) => {

    const query = `
    mutation SetHoiThoai($oTNHOITHOAIDetailDto: TNHOITHOAIDetailDtoInput!){
        setHoiThoai(oTNHOITHOAIDetailDto:$oTNHOITHOAIDetailDto){
                  ID
                  TIEUDE
                  NGAYCHINHSUA_UTC          
                  DELETED
                DS_THANHVIEN{
                    ID_TAIKHOAN
                    TENDANGNHAP
                    LANDOC_CUOICUNG_UTC
                    SL_TINNHAN_CHUADOC,
                    ANHDAIDIEN,
                    TENDAYDU
                }
        }
      }
    `;
    const variables = {
        oTNHOITHOAIDetailDto: doc
    };
    return {
        query,
        variables
    };
};
const pushCreatHoiThoai = (doc) => {
    const query = `
    mutation createHoiThoai($userName: String!){
        createHoiThoai(userName:$userName){
                ID
                NGAYCHINHSUA_UTC
                NGAYTAO_UTC
                TIEUDE
                DELETED
                LA_NHOM
                ID_NGUOITAO
                TINNHAN_CUOICUNG{
                    NOIDUNG
                    NGAYTAO_UTC
                    NGAYCHINHSUA_UTC
                    DELETED
                    ID_NGUOIGUI
                }
                DS_THANHVIEN{
                    ID_TAIKHOAN
                    TENDANGNHAP
                    LANDOC_CUOICUNG_UTC
                    SL_TINNHAN_CHUADOC
                    ANHDAIDIEN
                    TENDAYDU
                }
        }
      }
    `;
    const variables = {
        userName: doc.userName
    };
    return {
        query,
        variables
    };
};

const subcriptionHoiThoai = `
    subscription onListenHoiThoai($id_taikhoan: Int!){   
    onListenHoiThoai(id_taikhoan:$id_taikhoan)
    {
      NOIDUNG
    } 
  }
  `
const mutationSetLastOnline = `
    subscription onListenHoiThoai($id_taikhoan: Int!){   
    onListenHoiThoai(id_taikhoan:$id_taikhoan)
    {
      NOIDUNG
    } 
  }
  `
export { pullQueryBuilderHoiThoai, pushQueryBuilderHoiThoai, subcriptionHoiThoai, pushCreatHoiThoai }