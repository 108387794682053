import { createContext } from 'react';
import { BaseService } from 'shared/services';
import { Subject } from 'rxjs';
import { http } from 'shared/utils';
import { map } from 'rxjs/operators';
import { API_URL } from 'app-setting';
class hoithoaiService extends BaseService {
    constructor(props) {
        const _props = Object.assign({}, { url: 'api/tkketnoi/listkn' }, props);
        super(_props);
    }
    //Danh sách bạn bè
    getDanhSachKetNoi(meta) {             
        return http.get(`${API_URL}api/tkketnoi/listkn?keyword=${meta.keyword}&page=${meta.page}&itemsPerPage=${meta.page_size}`);
    }
}
const Context = createContext();
const _hoithoaiService = new hoithoaiService();
export { Context, _hoithoaiService, hoithoaiService };