import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { API_URL } from 'app-setting';
import { Link } from 'react-router-dom';
import IamgeAdd from 'assets/images/add_images.png';
const fileImage = ['jpg', 'jpeg', 'png', 'icon'];
const maxsize = 100;
class FileAttachImageComponent extends React.Component {
    static propTypes = {
        multiple: PropTypes.bool, // chọn nhiều file
        action: PropTypes.oneOf(['new', 'edit', 'read']), // truyền vào state.action
        size: PropTypes.number, // dung lượng cho phép MB
        label: PropTypes.string, // label field
        btnName: PropTypes.string, // tên button thêm tệp
        value: PropTypes.array, // value truyền vào
        // định dạng cho phép nhập dạng string "fileImage","fileDocument","fileAll" 
        // hoặc nhập thằng định dạng cho phép theo dạng mảng ['doc','xls'...]
        fileAllow: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        onFileChange: PropTypes.func,
        onFileDelete: PropTypes.func,
        name: PropTypes.string,
    };
    constructor(props) {
        super(props);

        let fileType=[];
        if (typeof props.fileAllow === 'string') {
            switch (props.fileAllow) {
            case 'fileImage':
                fileType = fileImage;
                break;
            default:
                fileType = fileImage;
                break;
            }
        } else {
            fileType = props.fileAllow;
        }
        let prevProps=this.props;
        this.state = {
            innerValue: props.value, // value hiện tại
            checkedit: false,
            fileType: fileType,
            maxSize: props.size>0?props.size:maxsize,
            fileAttach: PropTypes.array,
            lstIdDelete: [],
            fileAttachs: props.value,
            urlImage: '',
            prevProps:prevProps
        };
        this.subscriptions = {};
    }
    static getDerivedStateFromProps(props, state) {
        let prevProps = { ...state.prevProps };
        let nextState = null;
        if (props.value !== prevProps.value) {
            nextState = nextState || {};
            prevProps = props || {};
            nextState.fileAttachs = props.value;
        }
        if (props.action !== prevProps.action) {
            nextState = nextState || {};
            prevProps = props || {};
            nextState.checkedit = props.action === 'edit';
        }
        // hanlder fileAllow prop
        if (props.fileAllow !== prevProps.fileAllow) {
            nextState = nextState || {};           
            if (typeof props.fileAllow === 'string') {
                switch (props.fileAllow) {
                case 'fileImage':
                    nextState.fileType = fileImage;
                    break;
                default:
                    nextState.fileType = fileImage;
                    break;
                }
            } else {
                nextState.fileType = props.fileAllow;
            }
        }
        //hanlder size prop
        if (props.size !== prevProps.size) {
            nextState = nextState || {};
            if (props.size > 0) {
                nextState.maxSize = props.size;
            }
            else {
                nextState.maxSize = maxsize;
            }
        }
        
        if (nextState) {
            nextState.prevProps = prevProps;
        }        
        return nextState;
    }
    handleFileChange(e) {
        this.props.onFileChange(e);
    }
    componentDidMount() {
        
    }

    componentWillUnmount() {
        // Object.keys(this.subscriptions).forEach((key) => {
        //     this.subscriptions[key].unsubscribe();
        // });


    }
    upload() {
        document.getElementById(this.props.name).click();
    }
    // xoa file => gán lại state
    deleteAtt(e) {
        //var array = [...this.state.fileAttachs];
        var { fileAttachs, lstIdDelete } = this.state;
        var index = fileAttachs.indexOf(e);
        if (index !== -1) {
            // đã lưu vào db thì sẽ thêm trường isDelete để post đi            
            if (fileAttachs[index].id > 0) {
                lstIdDelete.push(fileAttachs[index].id);
                fileAttachs.splice(index, 1);
            } else { // nếu chưa lưu vào db thì chỉ cần xóa ở state là dc                
                fileAttachs.splice(index, 1);
            }
        }
        this.setState({
            fileAttachs: fileAttachs,
            lstIdDelete: lstIdDelete
        });
        this.props.onFileChange(fileAttachs);
        if (this.props.onFileDelete) {
            this.props.onFileDelete(lstIdDelete);
        }

    }
    checkFileType(filetype) {
        if (this.state.fileType.indexOf(filetype) > -1)
            return '';
        else
            return 'Định dạng không được phép upload';
    }
    checkSize(size) {
        if ((this.state.maxSize * 1000000) >= size)
            return '';
        else
            return 'Dung lượng file vượt quá cho phép';
    }
    onChangeHandler = event => {
        let { multiple } = this.props;
        let fileAttachs = this.state.fileAttachs;
        if (!multiple) {
            fileAttachs = [];
        }

        let files = event.target.files;
        let arr = Array.from(files).map((x, index) => {
            x.filetype = x.name.split('.').pop();
            x.title = x.name;
            x.error = new Array();
            if (this.checkFileType(x.name.split('.').pop()) !== '')
                x.error.push(this.checkFileType(x.name.split('.').pop()));
            if (this.checkSize(x.size) !== '')
                x.error.push(this.checkSize(x.size));
            //Lấy data file
            let reader = new FileReader();
            reader.onloadend = () => {
                x.datafile = reader.result;
                if (fileAttachs) {
                    var idx = -1;
                    for (var j = 0; j < fileAttachs.length; j++) {
                        if (fileAttachs[j].title)
                            if (x.title == fileAttachs[j].title) {
                                idx = j;
                                break;
                            }
                    }
                    if (idx >= 0)
                        fileAttachs[idx] = x;
                    else
                        fileAttachs.push(x);
                }
                this.setState({
                    fileAttachs: fileAttachs
                });
            };

            reader.readAsDataURL(x);
            return x;
        });
        let arrRe = new Array();
        arr.map(x => {
            if (!x.error || x.error && x.error.length == 0)
                arrRe.push(x);
        });

        this.setState({
            fileAttachs: arr,
            fileAttach: arrRe,
        });
        this.props.onFileChange(fileAttachs);
    }

    render() {
        const { multiple, action, btnName, value } = this.props;
        let { fileAttachs } = this.state;
        return (
            <React.StrictMode>
                {(() => {
                    if (action === 'new') {
                        return (<React.Fragment>
                            <ul className="list-attachment mb-0">
                                {fileAttachs ?
                                    fileAttachs.map((file, index) => {
                                        return (
                                            (!file.isDelete || file.isDelete === 0) ?
                                                <li className="li_FileAttach" key={index}>
                                                    {
                                                        (!file.error || file.error && file.error.length === 0) ?
                                                            <React.Fragment>
                                                                <img src={file.datafile} alt={file.title} className="imgage-item rounded" title={file.title} />
                                                                <Link to="#" className="act-delete-img" onClick={() => this.deleteAtt(file)}>
                                                                    <span className="fa fa-times text-white">
                                                                    </span>
                                                                </Link>
                                                            </React.Fragment>
                                                            : <React.Fragment>
                                                                <p style={{ margin: '0px' }}>{file.title}</p>
                                                                <small style={{ color: 'red' }}>{file.error.map((err, index) => {
                                                                    return <span key={index}>{err}</span>;
                                                                })}</small>
                                                            </React.Fragment>
                                                    }

                                                </li>
                                                : ''
                                        );
                                    })
                                    : ''
                                }
                            </ul>

                            <NavLink to={"#"} onClick={() => { this.upload(); }} className="text-link color-blue d-flex align-items-center mt-1">
                                <img style={{ width: '20px', height: '20px' }} src={IamgeAdd} alt="" className="mr-1" />{btnName ? btnName : "Thêm ảnh"}</NavLink>
                            <input id={this.props.name} hidden type="file" onChange={this.onChangeHandler} multiple={multiple} accept="image/png, image/jpeg" />

                        </React.Fragment>);
                    } else if (action === 'edit') {
                        return (<React.Fragment>
                            <ul className="list-attachment mb-0">
                                {fileAttachs ?
                                    fileAttachs.map((file, index) => {
                                        return (
                                            (!file.isDelete || file.isDelete === 0) ?
                                                <li className="li_FileAttach" key={index}>
                                                    {
                                                        (!file.error || file.error && file.error.length === 0) ?
                                                            <React.Fragment>

                                                                <img src={file.id ? `${API_URL}${file.url}` : file.datafile} alt={file.title} className="imgage-item rounded" title={file.title} />
                                                                <Link to="#" className="act-delete-img" onClick={() => this.deleteAtt(file)}>
                                                                    <span className="fa fa-times text-white">
                                                                    </span>
                                                                </Link>
                                                            </React.Fragment>
                                                            : <React.Fragment>
                                                                <p style={{ margin: '0px' }}>{file.title}</p>
                                                                <small style={{ color: 'red' }}>{file.error.map((err, index) => {
                                                                    return <span key={index}>{err}</span>;
                                                                })}</small>
                                                            </React.Fragment>
                                                    }

                                                </li>
                                                : ''
                                        );
                                    })
                                    : ''
                                }
                            </ul>

                            <NavLink to={"#"} onClick={() => { this.upload(); }} className="text-link color-blue d-flex align-items-center mt-1">
                                <img style={{ width: '20px', height: '20px' }} src={IamgeAdd} alt="" className="mr-1" />{btnName ? btnName : "Thêm ảnh"}</NavLink>
                            <input id={this.props.name} hidden type="file" onChange={this.onChangeHandler} multiple={multiple} accept="image/png, image/jpeg" />

                        </React.Fragment>);
                        // return (<React.Fragment>
                        //     {/* <Button variant="success" className="ml-2" type="button" style={{ marginLeft: 'auto' }} onClick={this.upload}><span className="iconify fa" data-icon="fa-solid:plus" data-inline="false"></span> {btnName ? btnName : "Đính kèm"}</Button> */}
                        //     <Button size="sm" className="ml-2" type="button" variant="success" onClick={() => { this.upload(); }}>
                        //         <span className="iconify" data-icon="fa-solid:plus" data-inline="false" />
                        //         {btnName ? btnName : 'Đính kèm'}
                        //     </Button>
                        //     <input id={this.props.name} hidden type="file" onChange={this.onChangeHandler} multiple={multiple} />

                        //     <ul>
                        //         {fileAttachs ?
                        //             fileAttachs.map((file, index) => {
                        //                 //return (<li className="li_FileAttach" key={index}>{file.title} <a href="#" onClick={() => this.deleteAtt(file)}><span className="fa fa-times text-danger"></span></a></li>)
                        //                 return (
                        //                     (!file.isDelete || file.isDelete === 0) ?
                        //                         <li className="li_FileAttach" key={index}>
                        //                             {
                        //                                 (!file.error || file.error && file.error.length === 0) ?
                        //                                     <React.Fragment>
                        //                                         <a href={`${API_URL}${file.url}`}>{file.title}</a>
                        //                                         <a href="#" onClick={() => this.deleteAtt(file)}>
                        //                                             <span className="fa fa-times text-danger">
                        //                                             </span>
                        //                                         </a>
                        //                                     </React.Fragment>
                        //                                     : <React.Fragment>
                        //                                         <p style={{ margin: '0px' }}>{file.title}</p>
                        //                                         <small style={{ color: 'red' }}>{file.error.map((err, index) => {
                        //                                             return <span key={index}>{err}; </span>;
                        //                                         })}</small>
                        //                                     </React.Fragment>
                        //                             }

                        //                         </li>
                        //                         : ''
                        //                 );
                        //             })
                        //             : ''
                        //         }
                        //     </ul>
                        // </React.Fragment>);
                    } else {
                        return (<React.Fragment>
                            <ul>{
                                value ?
                                    value.map((file, index) => {
                                        return (
                                            <li className="li_FileAttach" key={index}>
                                                <a href={`${API_URL}${file.url}`}>{file.title}</a>
                                            </li>);
                                    }) : ''
                            }
                            </ul>
                        </React.Fragment>);
                    }
                    //return(<h1>abc</h1>)
                })()
                }
            </React.StrictMode >
        );
    }
}
export { FileAttachImageComponent };
