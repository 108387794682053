import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { string, object } from 'yup';
import { LoadingComponent, FormSelect } from 'shared/components';
import { tienichService, Context } from './tienichService';
import StarRatingComponent from 'react-star-rating-component';
import { store } from 'redux/store';
const schema = object({

});
const DanhGiaDefault = {
    title: '',
    mota: '',

};
class DanhGiaHeThongFormComponent extends Component {
    static contextType = Context;
    static propTypes = {
        onClose: PropTypes.func
    };
    constructor(props) {
        super(props);
        this.oauth = store.getState().oauth;
        this.state = {
            data: DanhGiaDefault,
            listGroup: [],
            editMode: false,
            loading: false,
            isShow: false,
            action: 'new',
            id: null,
            shownotify: false,
            datatieuchidanhgia: [],
            datarating: [],
            messnotify: "",
        };
        this.subscriptions = {};
    }
    componentDidMount() {
        this.subscriptions['sendToForm'] = tienichService.sendToForm.subscribe(res => {
            
            tienichService.getdanhgiahethong().subscribe(res => {
   
                if (res.data.length > 0) {
                    this.setState({ shownotify: true, messnotify: "Bạn đã gửi đánh giá hệ thống rồi" });
                } else {
                    this.setState({ editMode: true, isShow: true, id: null });
                    this.getchitieudanhgiahethong();
                }

            });
        });
    
     
    }
    getchitieudanhgiahethong() {
        tienichService.getchitieudanhgiahethong().subscribe(res => {

            this.setState({ datatieuchidanhgia: res.data });
            //tạo mảng defaul
            let datarating = [];
            for (let i = 0; i < res.data.length; i++) {
                let temp = { iD_DMCHITIEUDANHGIA: this.state.datatieuchidanhgia[i].id, danhgia: 0 };
                datarating.push(temp);
            }
            this.setState({ datarating: datarating });
        });
    }
    componentWillUnmount() {
        Object.keys(this.subscriptions).forEach((key) => {
            this.subscriptions[key].unsubscribe();
        });
    }
    onSubmit() {
        this.setState({
            loading: true
        });
        let data = { lstdanhgiachitiet: this.state.datarating };

        tienichService.createdanhgiahethong(data).subscribe(() => {
            this.Close();
            this.setState({ shownotify: true, messnotify:"Gửi đánh giá hệ thống thành công" });
            this.setState({
                loading: false
            });
        },
            (err) => {

                this.setState({
                    loading: false
                });
                
            });

    }

    handleClose(isRefesh) {
        this.setState({ isShow: false });
        if (this.props.onClose) {
            this.props.onClose(isRefesh);
        }

    }
    onKeyPress(ev) {
        const keyCode = ev.keyCode || ev.which;
        if (keyCode === 13) {
            ev.preventDefault();
            return false;
        }
    }
    computedTitle() {
        return 'Gửi đánh giá hệ thống';
    }
    Close() {
        this.setState({ isShow: false });
    }
    Closenotify() {
        this.setState({ shownotify: false });
    }

    onStarClick(nextValue, prevValue, name) {
        let lstdanhgia = [];//this.state.datarating.map(a => (JSON.parse(JSON.stringify({ ...a }))));

        for (let i = 0; i < this.state.datarating.length; i++) {
            let strname = "danhgia" + this.state.datarating[i].iD_DMCHITIEUDANHGIA;
            if (strname == name) {
                let itemtemp = { iD_DMCHITIEUDANHGIA: this.state.datarating[i].iD_DMCHITIEUDANHGIA, danhgia: nextValue };
                lstdanhgia.push(itemtemp);
            }
            else {
                lstdanhgia.push(this.state.datarating[i]);
            }
        }
        this.setState({ datarating: lstdanhgia });
    }
    render() {
        return (
            <div>
                <Modal size="sm" keyboard={false} backdrop="static" show={this.state.isShow} onHide={this.handleClose.bind(this)}>
                    <LoadingComponent loading={this.state.loading}></LoadingComponent>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(values) => {

                            this.onSubmit(values);
                        }}
                        enableReinitialize={true}
                        initialValues={this.state.dataBaoCao}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            errors,
                        }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Form.Group controlId="formBasicEmail" style={{ borderBottom: "1px solid #bcbcbc" }}>
                                        <h5 className="text-uppercase color-danger text-center mt-2">Đánh giá hệ thống</h5>
                                    </Form.Group>



                                    <div className="boxHover-danhgiasanpham p-2 ml-2">
                                        {this.state.datatieuchidanhgia.length > 0 ? this.state.datatieuchidanhgia.map((values, index) => (

                                            <div key={index} className="itemstar">
                                                <p className="text-black font-14 font-Regular m-0 ">{values.title}:</p>
                                                <div className="row  pl-1">
                                                    <StarRatingComponent
                                                        name={"danhgia" + values.id}
                                                        starColor="#ffb400"
                                                        emptyStarColor="#ffb400"
                                                        onStarClick={this.onStarClick.bind(this)}
                                                        renderStarIcon={(index, value) => {
                                                            return (
                                                                <span>
                                                                    <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                                                                </span>
                                                            );
                                                        }}
                                                        renderStarIconHalf={() => {
                                                            return (
                                                                <span>
                                                                    <span style={{ position: 'absolute' }}><i className="far fa-star" /></span>
                                                                    <span><i className="fas fa-star-half" /></span>
                                                                </span>
                                                            );
                                                        }} />
                                                    {/*<p className="text-black font-14">&nbsp;&nbsp;&nbsp;{this.state.data.diemsudunglai}&nbsp;&nbsp;</p>*/}
                                                </div>
                                            </div>
                                        )) : ""}

                                    </div>

                                    <Form.Group as={Col} md="12" controlId="" className="d-flex justify-content-between">
                                        <Button variant="primary" type="button" onClick={() => this.onSubmit()} style={{ width: "45%" }}>Gửi</Button>
                                        <Button variant="danger" type="button" onClick={() => this.Close()} style={{ width: "45%" }}>Hủy</Button>
                                    </Form.Group>
                                </Form>
                            )}
                    </Formik>


                </Modal>
                <Modal size="sm" keyboard={false} backdrop="static" show={this.state.shownotify} >
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title h4">
                                Thông báo</div>
                            <button type="button" className="close">
                             
                            </button></div><div className="modal-body">
                            <p>{this.state.messnotify}</p></div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.Closenotify()}>Đóng</button>
                        </div>
                    </div>

                </Modal>
            </div>

        );
    }
}
export { DanhGiaHeThongFormComponent };
