import React from 'react';
import { connect } from 'react-redux';
import Background from 'assets/images/bg-header_top.png';
import logoHeader from 'assets/images/logo-header.png';
import defaultImgUser from 'assets/images/user1-128x128.jpg';
import { authService } from 'shared/services';
import { store } from 'redux/store';
import { Image, Dropdown } from 'react-bootstrap';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Icon from "@mdi/react";
import { mdiDotsHorizontal } from '@mdi/js';
import { API_URL } from 'app-setting';
import { tienichService, Context } from 'modules/Public/tienich/tienichService'
import NoImg from 'assets/images/unavatar.jpg';
import PropTypes from 'prop-types';


class SideBarPublicComponent extends React.Component {
    
    static contextType = Context;
    constructor(props) {
        super(props);
        this.oauth = store.getState().oauth;
        this.state = {
            valueSearch: "",
            inpPlacehoder: "Nhập từ khóa tìm kiếm",
            dataNotify: [],
            page_size_notify: 10,
        }
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    formatDate = (strDate) => {
        const dateTimeFormat = new Intl.DateTimeFormat('vi', { year: 'numeric', month: 'numeric', day: '2-digit', weekday: 'long' });
        const [{ value: weekday }, , { value: day }, , { value: month }, , { value: year }] = dateTimeFormat.formatToParts(strDate);

        return `${weekday}, ngày ${day}/${month}/${year}`;
    }

    logout() {
        this.props.history.push('/dang-xuat');
    }

    viewPersonalPage() {
        this.props.history.push('/bai-dang');
    }

    viewPersonalInfo() {
        if (this.oauth.UnitCode.includes('03'))
            this.props.history.push('/thong-tin-doanh-nghiep/' + this.oauth.username);
        else
            this.props.history.push('/profile/' + this.oauth.username);
    }
    handleKeyPress(target) {
        if (target.charCode == 13) {
            this.props.history.push('/tim-kiem/q=' + this.state.valueSearch);
        }
    }
    componentDidMount() {
        if (this.oauth.idTaiKhoan && this.oauth.idTaiKhoan > 0) {
            this.fetchdata(this.state.page_size_notify, "");
        }
    }
    fetchdata(page_size, strNotify) {
        if (this.oauth.access_token) {
            tienichService.getDataNotify(this.oauth.idTaiKhoan, page_size, strNotify).subscribe(res => {
                this.setState({
                    dataNotify: res.data,
                });
            });
        }
    }
    formatDateTime(strValue) {
        if (strValue == null) {
            return '';
        }
        else {
            var d = new Date(strValue);
            var month = d.getMonth() + 1;
            var day = d.getDate();
            var output = day + '/' +
                (('' + month).length < 2 ? '0' : '') + month + '/' +
                d.getFullYear();
            return output;
        }
    }
    updateNotify(id, status) {
        if (this.oauth.idTaiKhoan && this.oauth.idTaiKhoan > 0) {
            let objNotify = { id: id, trangthai: status };
            tienichService.updateNotify(objNotify, id).subscribe(() => {
                // document.getElementById("dropdown-notify").style.display = "hidden";
                this.fetchdata(this.state.page_size_notify, "");
            });
        }

    }
    ViewMore() {
        this.setState({ page_size_notify: 60 })
        this.fetchdata(60, "");
    }
    SearchNotify() {

        let strNotify = document.getElementById("search-input-notify").value;
        this.fetchdata(this.state.page_size_notify, strNotify);
    }
    async delNotify(id) {
        if (await this.context.beh.confirm('Bạn có muốn gỡ thông báo này?')) {
            tienichService.delNotify(id).subscribe(() => {
                this.fetchdata(this.state.page_size_notify, "");
            });
        }
    }
    async TatThongBao(id) {
        if (await this.context.beh.confirm('Bạn có muốn tắt thông báo này?')) {
            if (this.oauth.idTaiKhoan && this.oauth.idTaiKhoan > 0) {
                let objNotify = { id: id, trangthai: 3 };
                tienichService.updateNotify(objNotify, id).subscribe(() => {
                    this.fetchdata(this.state.page_size_notify, "");
                });
            }

        }
    }

    renderLoginSignup = () => {
        return (
            <div className="d-flex align-items-center flex-md-nowrap flex-wrap">

                <div className="inp-custom-nav d-md-block d-none">
                    <input onChange={(e) => this.setState({ valueSearch: e.target.value })} className="form-control " type="text" aria-label="Search" value={this.state.inpSearch} placeholder={this.state.inpPlacehoder} style={{
                        borderColor: "#b3b3b3",
                        paddingRight: 32,
                        height: 35,
                        backgroundColor: "transparent",
                        borderRadius: 0,
                        border: 0,
                        borderBottom: "1px solid #ddd",
                        color: "#fff"
                    }}
                        onKeyPress={this.handleKeyPress}
                    />
                    <button className="btn btn-link icon-search_box position-absolute" onClick={() => this.props.history.push('/tim-kiem/q=' + this.state.valueSearch)} >
                        <i className="fa fa-search" aria-hidden="true" style={{ color: '#b3b3b3' }} />
                    </button>
                </div>
                <div className="box-col-right d-flex align-items-center order-md-2 order-1">
                    <NavLink to="/dang-ky-tai-khoan" className="text-white font-Bold font-normal">Đăng ký</NavLink>
                    <p className="text-white m-0 mr-2 ml-2 font-Bold">|</p>
                    <NavLink to="/dang-nhap" className="text-white font-Bold font-normal">Đăng nhập</NavLink>
                </div>
            </div>
        )
    }

    renderUser = () => {
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <a
                href=""
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick(e);
                }}
            >
                {children}
                <Icon path={mdiDotsHorizontal} size={0.5} color={"gray"} />
            </a>
        ));
        return (
            (this.oauth &&
                <div className="main-header navbar-expand navbar-dark bg-transparent d-flex align-items-center border-0">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <div className="inp-custom-nav  d-md-block d-none">
                                <input onChange={(e) => this.setState({ valueSearch: e.target.value })} className="form-control " type="text" aria-label="Search" value={this.state.inpSearch} placeholder={this.state.inpPlacehoder} style={{
                                    borderColor: "#b3b3b3",
                                    paddingRight: 32,
                                    height: 35,
                                    backgroundColor: "transparent",
                                    borderRadius: 0,
                                    border: 0,
                                    borderBottom: "1px solid #ddd",
                                    color: "#fff"
                                }}
                                    onKeyPress={this.handleKeyPress}
                                />
                                <button className="btn btn-link icon-search_box position-absolute" onClick={() => this.props.history.push('/tim-kiem/q=' + this.state.valueSearch)} >
                                    <i className="fa fa-search" aria-hidden="true" style={{ color: '#b3b3b3' }} />
                                </button>
                            </div>
                        </li>                        
                        <Dropdown as="li" className="nav-item">
                            <Dropdown.Toggle as="a" href="#" className="nav-link text-light" style={{ right: '0px', bottom: '0px' }} bsPrefix="notification">
                                <i className="fas fa-bell"></i>
                                {this.state?.dataNotify.filter(x => x.trangthai == 1).length > 0 ?
                                    <span className="badge badge-danger navbar-badge rounded-circle">{this.state.dataNotify.filter(x => x.trangthai == 1).length} </span>
                                    : ""}
                            </Dropdown.Toggle>

                            <Dropdown.Menu as="ul" className="dropdown-menu-lg" alignRight={true}>

                                <div className="box-filter-main-notify ">
                                    <div className="box-filter-main_top" >
                                        <div className="d-flex pl-2 pr-2 pt-1 pb-1 align-items-center box-filter-main" style={{ boxShadow: "0px 1px 1px #bcbcbc" }}>
                                            <p className="font-14 font-Bold color-blue"></p>
                                            <input type="text"
                                                id="search-input-notify"
                                                className="mr-2 form-control font-14 font-Light inp-search"
                                                placeholder="Nhập từ khóa tìm kiếm, ngày thông báo..."
                                                name="keyword"

                                            />
                                            <NavLink to="#" onClick={() => this.SearchNotify()} className="text-link font-14 color-gray btn-search" >
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                            </NavLink>
                                            {/* <a href="#" onClick={() => this.SearchNotify()} className="text-link font-14 color-gray btn-search">
                                                <i className="fa fa-search" aria-hidden="true"></i>
                                            </a> */}
                                        </div>
                                    </div>
                                </div>

                                {/* item menu navbar */}
                                <div className="list-menu-cha">
                                    {this.state?.dataNotify && this.state?.dataNotify.length > 0 ? this.state?.dataNotify.map((dataItem, index) => {
                                        let title = "";
                                        if (dataItem.noidung.indexOf("<b>") != -1 && dataItem.noidung.indexOf("</b>") != -1) {
                                            title = dataItem.noidung.replace("<b>", "").replace("</b>", "");
                                        }
                                        return (

                                            <div key={index} className={dataItem.trangthai == 1 ? "d-flex p-2 align-items-center item-menu-navbar active" : "d-flex p-2 align-items-center item-menu-navbar "}>
                                                <div className="mr-2" style={{ width: 55, height: 55, backgroundColor: "#ccc", borderRadius: 50, overflow: "hidden", border: "2px solid #fff" }}>
                                                    {
                                                        dataItem.anhdaidien ?
                                                            //<img className="rounded-circle" style={{ width: '35px', height: '35px' }} src={(data.taikhoan && data.taikhoan.anhDaiDien) ? `${API_URL}${data.taikhoan.anhDaiDien}` : `${NoImgBinhLuan}`} alt="" />
                                                            <img className="w-100 h-100 rounded-circle" src={`${API_URL}` + dataItem.anhdaidien} alt="" />
                                                            : ""
                                                    }
                                                </div>
                                                <div className="item-menu__col-right">
                                                    <div className="d-flex">
                                                        <div style={{ width: 300 }}>
                                                            <NavLink to={dataItem.duongdan || "/"} onClick={() => this.updateNotify(dataItem.id, 2)} className="m-0 font-12 font-Regular limited-line-3 color-black" style={{ whiteSpace: "pre-line", paddingLeft: '0px' }} >
                                                                <Dropdown.Item as={Link} to={dataItem.duongdan || "/"} style={{ paddingLeft: '0px' }}>
                                                                    <p className=" font-Light font-14 m-0  text-pre-wrap limited-line-2" title={title} style={{ paddingLeft: '0px' }} dangerouslySetInnerHTML={{ __html: dataItem.noidung }}>
                                                                    </p>
                                                                </Dropdown.Item>
                                                            </NavLink>

                                                        </div>
                                                        <div className="col-right__drop-down">

                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="dropdown-menu-lg" alignRight={true}>
                                                                    <div className="d-flex  align-items-center item-menu-navbar ">
                                                                        <div className="list-menu-cha">
                                                                            <div className="d-flex p-2 align-items-center item-menu-navbar">
                                                                                <NavLink to="/" onClick={() => this.updateNotify(dataItem.id, 2)} className="m-0 font-12 font-Regular limited-line-3 color-black" style={{ whiteSpace: "pre-line", paddingLeft: '0px' }} >
                                                                                    Đánh dấu đã đọc
                                                                                </NavLink>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <Dropdown.Item onClick={() => this.delNotify(dataItem.id)} eventKey="2" className="p-2 m-0 font-12 font-Regular limited-line-3 color-black" style={{ whiteSpace: "pre-line", paddingLeft: '0px' }}>Gỡ thông báo</Dropdown.Item>

                                                                    <Dropdown.Item onClick={() => this.TatThongBao(dataItem.id)} eventKey="3" className="p-2 m-0 font-12 font-Regular limited-line-3 color-black" style={{ whiteSpace: "pre-line", paddingLeft: '0px' }}>Tắt thông báo </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className="w-100">
                                                        <p className="m-0 font-12 color-gray font-Regular">{this.formatDateTime(dataItem.ngaytao)}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        )
                                    })
                                        : (
                                            <p className="m-0 font-12 font-Regular limited-line-3 color-black" style={{ whiteSpace: "pre-line", textAlign: 'center' }}>
                                                "Không có thông báo nào"
                                            </p>

                                        )

                                    }
                                </div>

                                {/* end item menu navbar */}
                                {this.state.page_size_notify < 20 && this.state.dataNotify.length > 0 ? (
                                    <NavLink to="/" onClick={() => this.ViewMore()} className="m-0 font-12 font-Regular limited-line-3 color-black" style={{ whiteSpace: "pre-line", textAlign: 'center' }} >
                                        Xem tất cả thông báo
                                    </NavLink>
                                ) : ""}

                                {/* <Dropdown.Item as={Link} to="/home" className="dropdown-footer">Xem tất cả thông báo</Dropdown.Item> */}

                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown as="li" className="nav-item user-menu d-flex">
                            <Image src={this.props.oauth.anhdaidien ? API_URL + this.props.oauth.anhdaidien : defaultImgUser} className="img-circle" alt="User Image" style={{ 'width': '40px', 'borderRadius': '50%' }} />
                            <Dropdown.Toggle as="a" className="nav-link" href="#">
                                <span className="d-none d-md-inline">{this.props.oauth.fullName}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu as="ul" className="dropdown-menu-sm" alignRight={true}>
                                <button className="dropdown-item" onClick={() => { this.viewPersonalPage() }} >
                                    <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />Trang tin bài
                            </button>
                                <button className="dropdown-item" onClick={() => { this.viewPersonalInfo() }} >
                                    <i className="fas fa-info fa-sm fa-fw mr-2 text-gray-400" />{this.oauth.UnitCode.includes('03') ? 'Thông tin doanh nghiệp' : 'Thông tin cá nhân'}
                                </button>
                                <button className="dropdown-item">
                                    <NavLink to="/san-pham-quan-tam" className="justify-content-start" style={{ color: "#212529" }}>
                                        <i className="fas fa fa-cart-plus fa-sm fa-fw mr-2 text-gray-400" />Sản phẩm quan tâm của tôi
                                   </NavLink>
                                </button>
                                <button className="dropdown-item">
                                    <NavLink to="/lich-su-quet-ma-qr" className="justify-content-start" style={{ color: "#212529" }}>
                                        <i className="fa fa-qrcode fa-sm fa-fw mr-2 text-gray-400" />Lịch sử quét mã QR
                                   </NavLink>
                                </button>
                                <button className="dropdown-item">
                                    <NavLink to="/lich-su-quet-ma-vach" className="justify-content-start" style={{ color: "#212529" }}>
                                        <i className="fa fa-barcode fa-sm fa-fw mr-2 text-gray-400" />Lịch sử quét mã vạch
                                   </NavLink>
                                </button>
                                <button className="dropdown-item">
                                    <NavLink to="/lich-su-quet-tem-chong-gia" className="justify-content-start" style={{ color: "#212529" }}>
                                        <i className="fa fa-credit-card fa-sm fa-fw mr-2 text-gray-400" />Lịch sử quét tem chống giả
                                   </NavLink>
                                </button>
                                <div className="dropdown-divider" />
                                <a className="dropdown-item" onClick={() => { this.logout() }} href="#" data-toggle="modal" data-target="#logoutModal">
                                    <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />Đăng xuất
                            </a>
                            </Dropdown.Menu>
                        </Dropdown>
                    </ul>
                </div>
            )
        )
    }

    render() {

        return (
            <header className="header-page" style={{ backgroundImage: `url(${Background})` }}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-between align-items-center header_box-top">
                                <p className="text-white m-0 font-LightItalic font-normal">{this.formatDate(Date.now())}</p>

                                {this.oauth.access_token != null ? this.renderUser() : this.renderLoginSignup()}

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="d-flex align-items-center header_box-center">
                                <div className="col-left d-flex flex-column align-items-center">
                                    <NavLink to="/">
                                        <img src={logoHeader} alt="logo_header" />
                                    </NavLink>
                                    <strong className="text-white font-Bold font-normal text-uppercase">Tổng cục</strong>
                                    <strong className="text-white font-Bold font-normal text-uppercase">Quản lý thị trường</strong>
                                </div>
                                <div className="col-right d-md-block d-none">
                                    <p className="title_box-left text-white m-0">Hệ thống quản lý về hàng giả, hàng nhái, buôn lậu,
              gian lận thương mại và vi phạm sở hữu trí tuệ</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        oauth: state.oauth,
    };
};
const SideBarPublic = connect(mapStateToProps, null)(SideBarPublicComponent);
export default withRouter(SideBarPublic);
