import { createContext } from 'react';
import { BaseService } from 'shared/services';
import { Subject } from 'rxjs';
import { http } from 'shared/utils';
import { map } from 'rxjs/operators';
import { API_URL } from 'app-setting';
class BaiVietService extends BaseService {

    constructor(props) {
        const _props = Object.assign({}, { url: 'api/bvbaiviet/lists' }, props);
        super(_props);
        this.sendToForm = new Subject();
        this.sendToLoadFormDangBai = new Subject();
        this.sendToViewLike = new Subject();
        this.sendToViewShare = new Subject();
        this.sendToFormEdit = new Subject();
        this.sendToBaoCaoViPham = new Subject();
        this.sendToShareBaiViet = new Subject();
        this.sendToShareMesage = new Subject();
    }
    getDataBaiVietCuaToi(meta) {
        return http.get(`api/bvbaiviet/mylist?page=${meta.page}&itemsPerPage=${meta.page_size}&sortBy=${meta.sortBy}&sortDesc=${meta.sortDesc}&lststatus=${meta.lststatus}`);
    }
    getDataBaiVietMoiNhat(meta) {
        return http.get(`api/bvbaiviet/mylistnew?page=${meta.page}&itemsPerPage=${meta.page_size}&sortBy=${meta.sortBy}&sortDesc=${meta.sortDesc}&lststatus=2,5`);
    }
    themMoiBaiViet(data, obj) {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const formData = new FormData();
        if(data&&data.fileAttach){
            for (let i = 0; i < data.fileAttach.length; i++) {
                formData.append(`files`, data.fileAttach[i]);
            }
        }        
        formData.append(obj, JSON.stringify(data));
        return http.post(`${API_URL}api/bvbaiviet`, formData, {
            headers: headers
        });
    }
    updateBaiViet(data, obj) {
        
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const formData = new FormData();
        if(data&&data.fileAttach){
            for (let i = 0; i < data.fileAttach.length; i++) {
                if(!data.fileAttach[i].id)
                    formData.append(`files`, data.fileAttach[i]);
            }
        }
        formData.append(obj, JSON.stringify(data));
        return http.put(`${API_URL}api/bvbaiviet/${data.id}`, formData, {
            headers: headers
        });
    }    
    getDanhSachAnhByIdBaiViet(meta, idbv) {
        return http.get(`api/bvattachment?page=${meta.page}&itemsPerPage=${meta.page_size}&sortBy=${meta.sortBy}&sortDesc=${meta.sortDesc}&idbaiviet=${idbv}`);
    }
    likeBaiViet(id) {
        return http.put(`${API_URL}api/bvluotthich/like/${id}`);
    }
    unLikeBaiViet(id) {
        return http.put(`${API_URL}api/bvluotthich/unlike/${id}`);
    }
    actViewLikeBaiViet(meta) {
        return http.get(`${API_URL}api/bvluotthich?idBaiViet=${meta.id}&page=${meta.page}&itemsPerPage=${meta.page_size}`);
    }
    actViewShareBaiViet(meta) {
        return http.get(`${API_URL}api/tktaikhoan/listshare?idBaiViet=${meta.id}&page=${meta.page}&itemsPerPage=${meta.page_size}`);
    }
    actaddFriend(id) {
        return http.post(`${API_URL}api/tkketnoi/guiketnoi/${id}`);
    }

    deleteBaiviet(id) {             
        return http.delete(`${API_URL}api/bvbaiviet/${id}`);
    }

    hiddenBaiviet(id) {             
        return http.put(`${API_URL}api/bvbaiviet/hidden/${id}`);
    }
    dangBaiviet(id) {             
        return http.put(`${API_URL}api/bvbaiviet/dangbai/${id}`);
    }

    hiddenViewBaiviet(id) {             
        return http.put(`${API_URL}api/bvbian/hidden/${id}`);
    }

    hiddenViewAllBaiviet(id) {             
        return http.put(`${API_URL}api/bvtaikhoanbian/hidden/${id}`);
    }

    closeBaiviet(id) {             
        return http.put(`${API_URL}api/bvbaiviet/close/${id}`);
    }
    openBaiviet(id) {             
        return http.put(`${API_URL}api/bvbaiviet/open/${id}`);
    }
    getBaiVietById(id) {
        return http.get(`${API_URL}api/bvbaiviet/viewdetail/${id}`);
    }
    getBaiVietShareById(id) {
        return http.get(`${API_URL}api/bvbaiviet/share/${id}`);
    }

    getDataCheckin() {
        return http.get('api/dmcheckin?page=1&itemsPerPage=-1').pipe(map((res) => {
            if (res) {
                return res.data.map(x => {
                    x.value = x.id;
                    x.name = x.title;
                    return x;
                });
            }
        }));
    }
    getDmLyDoBaoCao() {
        return http.get('api/bclydobaocao?page=1&itemsPerPage=-1&type=2&sortBy=ID&sortDesc=false').pipe(map((res) => {
            if (res) {
                return res.data.map(x => {
                    x.value = x.id;
                    x.label = x.title;
                    return x;
                });
            }
        }));
    }

    createBaoCaoViPham(data) {
        return http.post(`${API_URL}api/bvbaocaovipham`, data);
    }
    //Danh sách bạn bè
    getDanhSachKetNoi(meta) {             
        return http.get(`${API_URL}api/tkketnoi/listkn?keyword=${meta.keyword}&page=${meta.page}&itemsPerPage=${meta.page_size}`);
    }
    sendShareTinNhan(data) {
        return http.post(`${API_URL}api/tntinnhan`, data);
    }
    
    //Quan tâm
    getSanPhamGoiY(meta) {
        return http.get(`api/spsanpham/goiy?page=${meta.page}&itemsPerPage=${meta.page_size}&sortBy=${meta.sortBy}&sortDesc=${meta.sortDesc}`);
    }
    getGoiYSuKien(meta) {
        return http.get(`api/dnsukien/goiy?page=${meta.page}&itemsPerPage=${meta.page_size}&sortBy=${meta.sortBy}&sortDesc=${meta.sortDesc}&checkEvent=${meta.checkEvent}`);
    }
    getGoiYTinTuc(meta) {
        return http.get(`api/tttintuc/listshost?page=${meta.page}&itemsPerPage=${meta.page_size}&sortBy=${meta.sortBy}&sortDesc=${meta.sortDesc}`);
    }
    getGoiYKetNoi(meta) {
        return http.get(`api/tktaikhoan/goiy?page=${meta.page}&itemsPerPage=${meta.page_size}&sortBy=${meta.sortBy}&sortDesc=${meta.sortDesc}`).pipe(map((res) => {            
            if (res) {
                return res.data.map(x => {
                    x.isketnoi=true;
                    return x;
                });
            }
        }));        
    }
    getLoiMoiKetNoi(meta) {
        return http.get(`api/tkketnoi/loimoi?page=${meta.page}&itemsPerPage=${meta.page_size}&sortBy=${meta.sortBy}&sortDesc=${meta.sortDesc}`).pipe(map((res) => {            
            if (res) {
                return res.data.map(x => {
                    x.ischeck=true;
                    return x;
                });
            }
        }));        
    }
    getGoiYTheoDoi(meta) {
        return http.get(`api/tktaikhoan/goiytheodoi?page=${meta.page}&itemsPerPage=${meta.page_size}&sortBy=${meta.sortBy}&sortDesc=${meta.sortDesc}`).pipe(map((res) => {            
            if (res) {
                return res.data.map(x => {
                    x.istheodoi=true;
                    return x;
                });
            }
        }));        
    }

    actaddFollow(id) {
        return http.post(`${API_URL}api/dntheodoi/theodoi/${id}`);
    }

    actThamGiaSuKien(id) {
        return http.put(`${API_URL}api/dnsukienthamgia/thamgia/${id}`);
    }
    actBoThamGiaSuKien(id) {
        return http.put(`${API_URL}api/dnsukienthamgia/huythamgia/${id}`);
    }

    actQuanTamSuKien(id) {
        return http.put(`${API_URL}api/dnsukienquantam/quantam/${id}`);
    }
    actBoQuanTamSuKien(id) {
        return http.put(`${API_URL}api/dnsukienquantam/huyquantam/${id}`);
    }

    getKetNoiCuaToi(meta) {
        return http.get(`api/tkketnoi/myconnect?page=${meta.page}&itemsPerPage=${meta.page_size}&sortBy=${meta.sortBy}&sortDesc=${meta.sortDesc}`);        
    }

    dongYLoiMoiKetNoi(id) {
        return http.post(`${API_URL}api/tkketnoi/dongyketnoi/` + id);
    }
    huyLoiMoiKetNoi(id) {
        return http.post(`${API_URL}api/tkketnoi/huyketnoi/` + id);
    } 

}
const Context = createContext();
const _BaiVietService = new BaiVietService();
export { Context, _BaiVietService, BaiVietService };