import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../styles/Public/custom-style.scss';
import '../../../styles/Public/responsive.scss';
import 'jquery/src/jquery';
import KetNoiCuaToiComponent from '../../../modules/Public/baiviet/rightcol/KetNoiCuaToiComponent';
import GoiYKetNoiComponent from '../../../modules/Public/baiviet/rightcol/GoiYKetNoiComponent';
import LoiMoiKetNoiComponent from '../../../modules/Public/baiviet/rightcol/LoiMoiKetNoiComponent';
import HoiDap from '../../../modules/layoutright/HoiDap';
import DiaBanViPham from '../../../modules/layoutright/DiaBanViPham';
import SPBaoCaoViPham from '../../../modules/layoutright/SPBaoCaoViPham';
const LayoutbvContext = React.createContext();

class Left extends Component {
    static propTypes = {
        className: PropTypes.string
    };
    render() {
        const { className, ...props } = this.props;
        return (
            <LayoutbvContext.Consumer>
                {() => (
                    <div className={['col-md-8', className].join(' ')} {...props}>{props.children}</div>
                )}
            </LayoutbvContext.Consumer>

        );
    }

}

class Right extends Component {
    static propTypes = {
        className: PropTypes.string
    };
    render() {
        const { className, ...props } = this.props;
        return (
            <LayoutbvContext.Consumer>
                {() => (
                    <div className={['col-md-4', className].join(' ')} {...props}>{props.children}
                        <div>
                            
                            {/*end trao đổi mới nhất của bạn             */}
                            {/* box kết nối của tôi */}
                            <KetNoiCuaToiComponent></KetNoiCuaToiComponent>
                            {/* end box kết nối của tôi */}
                            <LoiMoiKetNoiComponent/>
                            {/* box co the ban quan tam */}
                            <GoiYKetNoiComponent/>
                            {/* box co the ban quan tam */}
                            {/* su kien sap dien ra */}
                            
                            {/* end su kien sap dien ra */}
                            {/* box tin moi */}
                            
                            {/* end box tin moi */}
                            {/* san pham moi */}
                            <SPBaoCaoViPham />
                            <HoiDap />
                            <DiaBanViPham />
                            
                        </div>

                    </div>
                )}
            </LayoutbvContext.Consumer>
        );
    }
}
class LayoutbvComponent extends Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.object
        ])
    };


    render() {
        return (
            <LayoutbvContext.Provider {...this.props}>
                <div className="wrap-main-danh-sach-bai-viet pt-4">
                    <div className="container">
                        <div className="row">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </LayoutbvContext.Provider>
        );
    }
}
LayoutbvComponent.Left = Left;
LayoutbvComponent.Right = Right;
export { LayoutbvComponent };