import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { Image } from 'react-bootstrap';
import { string, object, ref } from 'yup';
import { Card, Form, InputGroup, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { LoadingComponent, Page } from 'shared/components';
import { authService } from 'shared/services';
import { OauthAction } from 'redux/actions';
import logoNavBar from 'assets/images/logo-new.png'
import '@iconify/iconify';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'styles/styles.scss';

import { _QuenMatKhauService, Context } from '../QuenMatKhauService';
import { CAPTCHA_KEY } from 'app-setting';
import ReCAPTCHA from "react-google-recaptcha";

const schema = object({
    matkhaumoi: string().trim().required('Cần phải nhập mật khẩu').matches(
        /(?=^.{6,30}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        'Mật khẩu phải có ít nhất một chữ số, một chữ cái thường, một chữ cái in hoa, một ký tự đặc biệt'
    ).min(6, 'Nhập tối thiểu 6 ký tự')
        .max(30, 'Nhập tối đa 30 ký tự'),
    nhaplaimatkhau: string().trim().required('Cần phải nhập lại mật khẩu').oneOf([ref('matkhaumoi'), null], 'Mật khẩu nhập lại không đúng'),
});

class NhapMatKhauComponent extends React.Component {
    static contextType = Context;
    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
        setData: PropTypes.func,
        setMeta: PropTypes.func,
        data: PropTypes.array,
        meta: PropTypes.object,
        handleChange: PropTypes.func,
        id: PropTypes.number,
    }
    constructor(props) {
        super(props);
        this.state = {
            data: {
                matkhaumoi: '',
                values: '',

            },
            check: true,
            loading: false,
            errorMessage: '',
            expired: false,
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        let a = window.location.href;
        var obj = {};
        obj.matkhaumoi = "";
        obj.values = a.replace(a.substring(0, a.lastIndexOf("nhap-mat-khau/") + 14), "");
        obj.type = 1;
        _QuenMatKhauService.sendtkmatkhau(obj).subscribe(() => {
            this.setState({
                loading: false,
                check: true
            });
          
        },
            (err) => {
                this.setState({
                    loading: false,
                    check: false
                });
               
            });
    }

    onSubmit(obj) {
        if (this.state.expired) {
            this.setState({ loading: true });
            let a = window.location.href;
            obj.values = a.replace(a.substring(0, a.lastIndexOf("nhap-mat-khau/") + 14), "");
            obj.type = 2;
            _QuenMatKhauService.sendtkmatkhau(obj).subscribe(() => {
                this.setState({
                    loading: false
                });
                this.thongbao("Bạn đã đổi mật khẩu thành công. Đăng nhập để bắt đầu phiên làm việc");

            },
                (err) => {
                    this.setState({
                        loading: false
                    });
                    this.context.beh.alert(err.error);
                });
        }
     
    }
    async thongbao(str) {
        if (await this.context.beh.alert(str)) {
            //window.open(window.location.origin, '_blank');
            this.props.history.push({ pathname: '/dang-nhap' });
        }
    }
    onKeyPress(ev) {
        const keyCode = ev.keyCode || ev.which;
        if (keyCode === 13) {
            ev.preventDefault();
            return false;
        }
    }
    handleChange = value => {
        this.setState({ expired: true });
    };
    render() {
        return (
            <Page>
                <Page.Content>
                    {this.state.check == true ? (
                        <div className="d-flex justify-content-center align-items-center h-100 login-page-public">
                            <div className="login-box" style={{ width: 400 }}>
                                {/* /.login-logo */}
                                <Card style={{ marginTop: '50px', marginBottom: '50px' }} >
                                    <Card.Body>

                                        <h5 className="text-center text-dark mt-3" style={{ fontSize: 18 }}>Đổi mật khẩu</h5>
                                        <p className="login-box-msg">
                                            <i className="text-danger">{this.state.errorMessage}</i>
                                        </p>

                                        <Formik
                                            validationSchema={schema}
                                            onSubmit={(values) => {

                                                this.onSubmit(values);
                                            }}
                                            initialValues={this.state.data}
                                        >
                                            {({
                                                handleSubmit,
                                                handleChange,
                                                handleBlur,
                                                values,
                                                touched,
                                                errors,
                                            }) => (
                                                    <Form noValidate onSubmit={handleSubmit} onKeyPress={this.onKeyPress.bind(this)}>
                                                        <Form.Group as={Col} md="12" controlId="mota" style={{ marginBottom: '0px' }}>
                                                            <Form.Label>Mật khẩu mới</Form.Label> <Form.Label className="text-danger">(*)</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    name="matkhaumoi"
                                                                    type="password"
                                                                    placeholder="Nhập mật khẩu"
                                                                    aria-label="matkhaumoi"
                                                                    autoComplete="on"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.matkhaumoi}
                                                                    isInvalid={touched.matkhaumoi && !!errors.matkhaumoi}
                                                                />
                                                                <InputGroup.Append>
                                                                    <InputGroup.Text>
                                                                        <span className="iconify" data-icon="fa:lock" data-inline="false" />
                                                                    </InputGroup.Text>
                                                                </InputGroup.Append>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.matkhaumoi}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </Form.Group>
                                                        <Form.Group as={Col} md="12" controlId="mota" style={{ marginBottom: '0px' }}>
                                                            <Form.Label>Nhập lại mật khẩu mới</Form.Label> <Form.Label className="text-danger">(*)</Form.Label>
                                                        </Form.Group>
                                                        <Form.Group>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    name="nhaplaimatkhau"
                                                                    type="password"
                                                                    placeholder="Nhập lại mật khẩu mới"
                                                                    aria-label="nhaplaimatkhau"
                                                                    autoComplete="on"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.nhaplaimatkhau}
                                                                    isInvalid={touched.nhaplaimatkhau && !!errors.nhaplaimatkhau}
                                                                />
                                                                <InputGroup.Append>
                                                                    <InputGroup.Text>
                                                                        <span className="iconify" data-icon="fa:lock" data-inline="false" />
                                                                    </InputGroup.Text>
                                                                </InputGroup.Append>
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.nhaplaimatkhau}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </Form.Group>

                                                        <Row>
                                                            <Col className="col-12 ">
                                                                <ReCAPTCHA
                                                                    className="text-center m-auto"
                                                                    style={{ display: "inline-block" }}
                                                                    ref={this._reCaptchaRef}
                                                                    sitekey={CAPTCHA_KEY}
                                                                    onChange={this.handleChange}
                                                                    asyncScriptOnLoad={this.asyncScriptOnLoad}
                                                                />
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col className="col-12">
                                                                <button type="submit" className="btn btn-primary btn-block">Xác nhận</button>
                                                            </Col>
                                                        </Row>

                                                    </Form>
                                                )}
                                        </Formik>
                                        {/* /.login-card-body */}
                                    </Card.Body>
                                </Card>
                            </div>
                            <LoadingComponent loading={this.state.loading} />

                        </div>
                    ): ("Đường link không tồn tại")}
                   
                </Page.Content>
            </Page>

        );
    }
}


export { NhapMatKhauComponent };
