import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { BehaviorsContext } from 'shared/services';
import { HoithoaiComponent } from './hoithoai/hoithoaiComponent'
import { Context, _hoithoaiService } from './hoithoaiService';
class chatModule extends Component {
    constructor(props) {
        super(props);
    }
    static propTypes={
        match:PropTypes.object
    }
    render() {
        let { path } = this.props.match;
        return (
            <BehaviorsContext.Consumer>
                {
                    ({ beh }) => (
                        <Context.Provider value={{
                            _hoithoaiService: _hoithoaiService,
                            beh: beh
                        }} >
                            <Switch>
                                <Route path={`${path}/:username`} render={(props) => <HoithoaiComponent {...props} />} />
                                <Route path={path} render={(props) => <HoithoaiComponent {...props} />} />
                            </Switch>
                        </Context.Provider>
                    )
                }
            </BehaviorsContext.Consumer>
        );
    }
}
export { chatModule };