import React from 'react';
import { Redirect } from 'react-router-dom';
import { authService } from 'shared/services';
class LogoutComponent extends React.Component {

    renderRedirect() {
        // call service logout tại đây
        // userService.logout();
        return <Redirect to='/dang-nhap' />;
    }
    componentDidMount() {
        authService.logout();
    }
    render() {
        return (
            <div>
                {this.renderRedirect()}
            </div>
        );
    }
}
export { LogoutComponent };