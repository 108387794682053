import { createContext } from 'react';
import { BaseService } from 'shared/services';
import { Subject } from 'rxjs';
import { http } from 'shared/utils';
import { map } from 'rxjs/operators';
import { API_URL } from 'app-setting';
class QuenMatKhauService extends BaseService {
    
    constructor(props) {
        const _props = Object.assign({}, { url: 'api/tktaikhoan' }, props);
        super(_props);
        this.sendToForm = new Subject();
    }
    sendtkquenmatkhau(obj) {
        return http.get(`${API_URL}api/tktaikhoan/sendforgotpass/${obj.username}`);
    }
    sendtkmatkhau(obj) {
        return http.put(`${API_URL}api/tktaikhoan/forgotpass`, obj);
    }
}
const Context = createContext();
const _QuenMatKhauService = new QuenMatKhauService();
export { Context, _QuenMatKhauService, QuenMatKhauService };