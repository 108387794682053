import * as Constant from './dmchitieusanphamConstant'

const dmchitieusanpham = {
    dmchitieusanphamList: [],
    meta: {
        page: 1,
        page_size: 10,
        sort: { id:'desc'},
        search: '', 
    }
};
const dmchitieusanphamReducer = (state = dmchitieusanpham, action) => {
    switch (action.type) {
    case Constant.DMCHITIEUSANPHAM_SET_LIST_DATA:
        return Object.assign({}, state, { dmchitieusanphamList: action.dmchitieusanphamList });
    case Constant.DMCHITIEUSANPHAM_SET_LIST_META:
        return Object.assign(
            {}, 
            state, 
            { 
                meta: Object.assign(
                    {}, 
                    state.meta, 
                    action.meta
                )
            });
    default:
        return state;
    }
};
export  {dmchitieusanphamReducer};